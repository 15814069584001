import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ContractCard from "components/ContractCard";
import { fetchTemplates, getTemplate } from "service/template";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import TemplateCard, { TemplateAddCard } from "components/TemplateCard";
import { fetchContracts } from "../../service/contract";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectIsCreator, selectUser } from "../Login/userSlice";
import commonStyles from "../../common/commonStyles";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    maxWidth: 1200,
    margin: "0 auto",
  },
  templateContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  ...commonStyles(theme),
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const [reloading, setReloading] = useState({});
  const [groupedTemplates, setGroupedTemplates] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [totalTemplates, setTotalTemplates] = useState(0);
  const [isTemplateLoading, setTemplateLoading] = useState(false);
  const [isContractLoading, setContractLoading] = useState(false);
  const isAdmin = useSelector(selectIsAdmin);
  const user = useSelector(selectUser);
  useEffect(() => {
    (async () => {
      setTemplateLoading(true);
      try {
        let templates = [];
        // TODO: 以后改回来，去掉下面的单个查询
        if (user.username) {
          const { data: responseBody } = await fetchTemplates(true);
          setTotalTemplates(responseBody.members.length);
          templates = responseBody.members;
        } else {
          const { data: resp } = await getTemplate("5fa611a0d8afbd41159df1da");
          setTotalTemplates(1);
          templates = [resp];
        }
        const groupedTemplates = groupBy(templates, (t) => {
          if (isAdmin) {
            return t.category;
          }
          return "用户工具";
        });
        setGroupedTemplates(groupedTemplates);
      } catch (e) {
      } finally {
        setTemplateLoading(false);
      }
    })();
    (async () => {
      if (isAdmin) {
        return;
      }
      setContractLoading(true);
      try {
        const { data: responseBody } = await fetchContracts();
        setContracts(responseBody.members);
      } catch (e) {
      } finally {
        setContractLoading(false);
      }
    })();
  }, [reloading]);

  return (
    <div className={classes.root}>
      <Grid>
        <Typography variant="h5" align="center">
          欢迎使用DoxAuto试用版。如需帮助，请参阅
          <RouterLink
            to="/faq#title-faq"
            style={{ color: theme.fontColor.grey3 }}
          >
            FAQ
          </RouterLink>
        </Typography>
      </Grid>
      <Grid key="__recent" style={{ paddingTop: theme.spacing(4) }}>
        <Typography variant="h5">创建新工具</Typography>
        <Grid container spacing={5} className={classes.templateContainer}>
          <Grid item xs={12} md={3}>
            <TemplateAddCard total={totalTemplates} />
          </Grid>
        </Grid>
      </Grid>
      {map(groupedTemplates, (templates, category) => {
        return (
          <div key={category}>
            <Typography variant="h5">
              {category === "null" ? "未分类工具" : category}
            </Typography>
            {!user.username ? (
              <Typography variant="subtitle1" color="primary">
                (当前未登录，工具修改仅供试用，无法保存。请登录后再进行操作。)
              </Typography>
            ) : null}
            <Grid container spacing={5} className={classes.templateContainer}>
              {map(templates, (template, templateIndex) => {
                return (
                  <Grid item xs={12} md={3} key={template.id}>
                    <TemplateCard
                      isLogin={!!user.username}
                      id={template.id}
                      title={template.title}
                      description={template.description}
                      onDelete={() => {
                        setReloading({});
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      })}
      {!isAdmin && contracts.length > 0 ? (
        <Grid item key="__savedContract" className={classes.largeGutterTop}>
          <Typography variant="h5">保存的回答</Typography>
          <Grid container spacing={5} className={classes.templateContainer}>
            {map(contracts, (contract, contractIdx) => {
              return (
                <Grid item xs={3} key={contract.id}>
                  <ContractCard
                    id={contract.id}
                    title={contract.name}
                    description={`修改于${new Date(
                      contract.updateTime
                    ).toLocaleString()}`}
                    onDelete={() => {
                      setReloading({});
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};
