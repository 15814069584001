import background from "assets/sign-background.jpg";

export default (theme) => ({
  navBar: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },

  fullBackground: {
    // minHeight: "100vh",
    // background: `url(${background})  no-repeat center center fixed`,
    // backgroundSize: "cover",
  },

  main: {
    position: "relative",
    paddingTop: theme.spacing(10),
  },

  templateHeader: {
    marginTop: theme.spacing(5),
    position: "relative",
  },

  questionBar: {
    height: "10px",
    backgroundColor: "rgb(103, 58, 183)",
  },

  questionActiveBar: {
    width: "6px",
    height: "100%",
    position: "absolute",
    backgroundColor: "#4285f4",
  },

  questionContent: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  toolkit: {
    position: "absolute",
    transition: "top .5s ease",
    right: theme.spacing(5) * -1,
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  smallGutterTop: {
    marginTop: theme.spacing(3),
  },

  test: {
    fontSize: "100%",
    "&::before": {
      borderBottom: 0,
    },
  },

  submit: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    textAlign: "center",
  },

  flipImage: {
    transform: "scaleX(-1)",
  },
});
