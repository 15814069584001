import axios from "axios";

export function fetchContracts() {
  return axios.get("/api/contracts/profiles");
}

export function createContract(contract) {
  return axios.post("/api/contracts", contract);
}
export function updateContract(contract) {
  return axios.patch(`/api/contracts/${contract.id}`, contract);
}

export function getContract(contractId) {
  return axios.get(`/api/contracts/${contractId}`);
}

export function deleteContract(contractId) {
  return axios.delete(`/api/contracts/${contractId}`);
}
