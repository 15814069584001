import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { grey, red, yellow } from "@material-ui/core/colors";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  overline: {
    color: theme.fontColor.grey3,
  },
  highlight: {
    fontWeight: 800,
    color: theme.fontColor.grey1,
  },
  value: {
    color: theme.fontColor.grey1,
  },
}));

export default ({ label, value, highLightValue }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="overline" className={classes.overline}>
        {label}
      </Typography>
      <Typography
        className={classnames({
          [classes.highlight]: highLightValue,
          [classes.value]: true,
        })}
      >
        {value}
      </Typography>
    </React.Fragment>
  );
};
