import jshint from "jshint";
import CodeMirror from "codemirror";

function validator(text, options) {
  if (!options.indent)
    // JSHint error.character actually is a column index, this fixes underlining on lines using tabs for indentation
    options.indent = 1; // JSHint default value is 4
  jshint.JSHINT(text, options, options.globals);
  const errors = jshint.JSHINT.data().errors,
    result = [];
  if (errors) {
    parseErrors(errors, result);
  } else {
    window.__monday_code_errors = "";
  }
  return result;
}

CodeMirror.registerHelper("lint", "javascript", validator);

function parseErrors(errors, output) {
  window.__monday_code_errors = "";
  for (let i = 0; i < errors.length; i++) {
    const error = errors[i];
    if (error) {
      if (error.line <= 0) {
        if (window.console) {
          window.console.warn(
            "Cannot display JSHint error (invalid line " + error.line + ")",
            error
          );
        }
        continue;
      }

      let start = error.character - 1,
        end = start + 1;
      if (error.evidence) {
        const index = error.evidence.substring(start).search(/.\b/);
        if (index > -1) {
          end += index;
        }
      }

      // Convert to format expected by validation service
      const hint = {
        message: error.reason,
        severity: error.code
          ? error.code.startsWith("W")
            ? "warning"
            : "error"
          : "error",
        from: CodeMirror.Pos(error.line - 1, start),
        to: CodeMirror.Pos(error.line - 1, end),
      };
      if (window.__monday_code_errors !== "error") {
        window.__monday_code_errors = hint.severity;
      }
      output.push(hint);
    }
  }
}
