import React from "react";
import update from "immutability-helper";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables({ rows, columns, onChange }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map(({ name: col }) => (
              <StyledTableCell align="center">{col}</StyledTableCell>
            ))}
            <StyledTableCell key="__action" align="center">
              操作
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex, originRows) => (
            <StyledTableRow key={rowIndex}>
              {columns.map(({ name: col }) => {
                return (
                  <StyledTableCell key={col} align="right">
                    <TextField
                      fullWidth
                      value={row[col]}
                      onChange={(event) => {
                        const newRows = update(rows, {
                          [rowIndex]: {
                            [col]: {
                              $set: event.target.value,
                            },
                          },
                        });
                        onChange(newRows);
                      }}
                    />
                  </StyledTableCell>
                );
              })}

              <StyledTableCell key="actions" align="right">
                {
                  <React.Fragment>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        if (rowIndex === 0) {
                          return;
                        }
                        const newRow = update(originRows, {
                          $splice: [
                            [rowIndex, 1],
                            [rowIndex - 1, 0, row],
                          ],
                        });
                        onChange(newRow);
                      }}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        if (rowIndex === originRows.length - 1) {
                          return;
                        }
                        const newRow = update(originRows, {
                          $splice: [
                            [rowIndex, 1],
                            [rowIndex + 1, 0, row],
                          ],
                        });
                        onChange(newRow);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        const newRow = update(originRows, {
                          $splice: [[rowIndex + 1, 0, row]],
                        });
                        onChange(newRow);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                    {originRows.length !== 1 ? (
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => {
                          const newRow = update(originRows, {
                            $splice: [[rowIndex, 1]],
                          });
                          onChange(newRow);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    ) : null}
                  </React.Fragment>
                }
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
