import React from "react";
import update from "immutability-helper";

import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextFieldWithCodeMirror from "components/TextFieldWithCodeMirror";

const useStyles = makeStyles((theme) => ({
  test: {
    fontSize: "larger",
    "&::before": {
      borderBottom: 0,
    },
  },
}));

const theme = createMuiTheme({});

export default ({ active, question, onChange }) => {
  const classes = useStyles();
  const { options, defaultValue, defaultValueExpression } = question;

  const updateDefaultValue = (value) => {
    let newValue;
    if (defaultValue === value) {
      newValue = undefined;
    } else {
      newValue = value;
    }

    const newQuestion = update(question, {
      defaultValue: {
        $set: newValue,
      },
    });
    onChange(newQuestion, question);
  };

  return (
    <Grid container spacing={3} item xs={12}>
      <Grid container item xs={12}>
        <Grid
          item
          xs={1}
          style={{ marginRight: theme.spacing(-3) }}
          onClick={() => {
            if (!active) {
              return;
            }
            updateDefaultValue(0);
          }}
        >
          {defaultValue === 0 ? (
            <RadioButtonCheckedIcon />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            value={question.options[0] && question.options[0].label}
            disabled
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          xs={1}
          style={{ marginRight: theme.spacing(-3) }}
          onClick={() => {
            if (!active) {
              return;
            }
            updateDefaultValue(1);
          }}
        >
          {defaultValue === 1 ? (
            <RadioButtonCheckedIcon />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            value={question.options[1] && question.options[1].label}
            disabled
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="demo-simple-select-label">显示形式</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={`${options[0] && options[0].label}/${
                options[1] && options[1].label
              }`}
              onChange={(event) => {
                const options = event.target.value
                  .split("/")
                  .map((label, idx) => ({
                    label,
                    value: idx === 0 ? true : false,
                  }));
                const newQuestion = update(question, {
                  options: {
                    $set: options,
                  },
                });
                onChange(newQuestion, question);
              }}
            >
              <MenuItem value="TRUE/FALSE">Yes/No</MenuItem>
              <MenuItem value="是/否">是/否</MenuItem>
              <MenuItem value="对/错">对/错</MenuItem>
              <MenuItem value="已完成/未完成">已完成/未完成</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {/*<TextField*/}
        {/*  name="defaultValueExpression"*/}
        {/*  fullWidth*/}
        {/*  value={field.defaultValueExpression}*/}
        {/*  label="默认值公式"*/}
        {/*  variant="filled"*/}
        {/*  onChange={onValueChange}*/}
        {/*/>*/}
        <TextFieldWithCodeMirror
          current={question}
          value={defaultValueExpression}
          name="defaultValueExpression"
          onChange={(event) => {
            const newQuestion = update(question, {
              defaultValueExpression: {
                $set: event.target.value,
              },
            });
            onChange(newQuestion, question);
          }}
          disabled={!active}
          label="默认值公式"
          placeholder="默认值公式"
        />
      </Grid>
    </Grid>
  );
};
