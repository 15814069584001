import React from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextFieldWithBlockly from "components/TextFieldWithBlockly";
import {
  FORMAT_DATE_COMMON,
  FORMAT_DATE_COMMON2,
} from "common/commonConstants/QuestionTypeFormat";
import TextFieldWithCodeMirror from "../../TextFieldWithCodeMirror";

const useStyles = makeStyles((theme) => ({
  test: {
    fontSize: "larger",
    "&::before": {
      borderBottom: 0,
    },
  },
}));

const theme = createMuiTheme({});

export default ({
  active,
  format,
  defaultValueExpression,
  onChange,
  question,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} item xs={12}>
      {/*<Grid item xs={12} md={9}>*/}
      {/*  <KeyboardDatePicker*/}
      {/*    disableToolbar*/}
      {/*    variant="inline"*/}
      {/*    format={format}*/}
      {/*    margin="normal"*/}
      {/*    id="date-picker-inline"*/}
      {/*    disabled*/}
      {/*    KeyboardButtonProps={{*/}
      {/*      "aria-label": "change date",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item xs={12}>
        <TextFieldWithCodeMirror
          current={question}
          value={defaultValueExpression}
          name="defaultValueExpression"
          onChange={(event) => {
            onChange({
              target: {
                name: "defaultValueExpression",
                value: event.target.value,
              },
            });
          }}
          disabled={!active}
          label="默认值公式"
          placeholder="默认值公式"
        />
      </Grid>
    </Grid>
  );
};
