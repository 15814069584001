import Shanghai from "assets/shanghai.jpg";
export default (theme) => ({
  root: {
    position: "relative",
    minHeight: "100vh",
  },
  main: {
    position: "relative",
    paddingTop: theme.spacing(10),
  },

  templateHeader: {
    marginTop: theme.spacing(5),
    position: "relative",
  },

  questionBar: {
    height: "10px",
    backgroundColor: "rgb(103, 58, 183)",
  },

  questionActiveBar: {
    width: "6px",
    height: "100%",
    position: "absolute",
    backgroundColor: "#4285f4",
  },

  questionContent: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    overflow: "hidden",
  },
  toolkit: {
    position: "absolute",
    transition: "top .5s ease",
    right: theme.spacing(5) * -1,
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  smallGutterTop: {
    marginTop: theme.spacing(3),
  },

  mainTitle: {
    fontSize: "larger",
    color: theme.fontColor.grey1,
    "&::before": {
      borderBottom: 0,
    },
  },
  titleDesc: {
    color: theme.fontColor.grey2,
  },
  titleAuthor: {
    color: theme.fontColor.grey2,
    textAlign: "right",
  },

  submit: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: "center",
  },
  theQuestion: {
    fontSize: "1.5rem",
    fontWeight: 400,
    color: theme.fontColor.grey2,
  },
  noEdited: {
    fontSize: "1.2rem",
    lineHeight: "2rem",
    fontWeight: 400,
    color: theme.fontColor.grey3,
  },
  disabledText: {
    fontSize: "1.2rem",
    lineHeight: "2rem",
    fontWeight: 400,
    color: theme.fontColor.grey3,
  },
  edited: {
    color: "#3154b5",
    fontSize: "1.2rem",
    lineHeight: "2rem",
    fontWeight: 400,
  },
  disabled: {
    color: theme.fontColor.grey2,
  },
  unEditable: {
    backgroundColor: theme.palette.grey[200],
  },
  theQuestionHit: {
    color: theme.fontColor.grey3,
    paddingTop: 5,
  },
  answerSize: {
    fontSize: "1.2rem",
  },
  module: {
    color: theme.fontColor.grey3,
  },
  flowButton: {
    position: "fixed",
    right: theme.spacing(6),
    bottom: theme.spacing(6),
  },
  flowButtonIcon: {
    fontSize: "1.2rem",
  },
  flowDrawer: {
    maxWidth: "70vw",
    maxHeight: "100vh",
  },
});
