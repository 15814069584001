import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Link as RouterLink } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

import Rating from "@material-ui/lab/Rating";

//import funding from "assets/home/funding.jpg";
//import funding from "assets/home/labor-2.jpg";
import funding from "assets/home/compliance.jpg";


import houseRental1 from "assets/home/house-rental-1.jpg";
import houseRental2 from "assets/home/house-rental-2.jpg";
import termSheet from "assets/home/term-sheet.jpg";
import data1 from "assets/home/data-1.jpg";
import data2 from "assets/home/data-2.jpg";
import data3 from "assets/home/data-3.jpg";
import calc1 from "assets/home/calc1.jpg";
import risk from "assets/home/risk-man.jpg";
import stock from "assets/home/stock-purchase.jpg";
import nda from "assets/home/NDA.jpg";
import labor from "assets/home/labor-2.jpg";
import audit from "assets/home/audit.jpg";
import store from "assets/home/store.jpg";
import bank from "assets/home/bank.jpg";
import sign from "assets/home/sign.jpg";
import hire from "assets/home/hire.jpg";
import vie from "assets/home/vie.jpg";
import copyright from "assets/home/copyright.png";
import contractFlow from "assets/home/contract-flow.jpg";
import checkCode from "assets/home/code.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const pics = {
  "5f5d3dfdddf87b5bc8b4950a": funding,
  "5f5310a471f8055605334b8b": houseRental2,
  "5f63013b061dac322e0fe27c": data1,
  "5f630aa0061dac322e0fe27f": data2,
  "5f630b1f061dac322e0fe280": data3,
  "5f5aa7b597e9233bcbb11f5b": risk,
  "5f55143271f8055605334bb6": calc1,
  "5f5fb750ddf87b5bc8b49534": store,
  "5f49389150cb1e0d3158c05f": termSheet,
  "5f3dbe58c4cc7f35e0ee7f28": stock,
  "5f62b0ff061dac322e0fe273": nda,
  "5f5428db71f8055605334bad": labor,
  "5f4e70b60359ef7c540dc237": audit,
  "5f81238341bae8724ed4d096": checkCode,
  "5f815cd141bae8724ed4d09e": contractFlow,
  "5f81ad2741bae8724ed4d0a0": bank,
  "5f8443ce41bae8724ed4d0bf": hire,
  "5f8ed0b3d34b5f00edbed264": sign,
  "5f96d1cbd34b5f00edbed2da": vie,
  "5f94296bd34b5f00edbed2bc": copyright,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgb(245, 241, 234)",
    boxShadow: "unset",
    width: 250,
    padding: 0,
    color: theme.fontColor.grey3,
  },
  content: {
    position: "relative",
    padding: 0,
  },
  img: {
    width: "100%",
    height: 160,
    display: "block",
  },
  area: {
    boxSizing: "border-box",
    border: "3px solid rgba(255, 255, 255, 0)",
    "&:hover": {
      border: "3px solid grey",
    },
  },
  desc: {
    boxSizing: "border-box",
    padding: theme.spacing(2),
    position: "absolute",
    transition: "opacity .5s ease",
    opacity: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: 160,
    backgroundColor: "white",
    "&:hover": {
      opacity: 0.85,
    },

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: 2,
    color: "#7eb2e0",
    fontWeight: 700,
  },
  developer: {
    fontSize: "90%",
  },
  ratio: {
    paddingTop: theme.spacing(1),
  },
}));

export default ({ to, title, tid, author, desc, ratio, newPage }) => {
  const classes = useStyles();
  ratio = ratio || 4;
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <CardActionArea
          component={RouterLink}
          to={to}
          className={classes.area}
          target={newPage ? "_blank" : undefined}
        >
          <img src={pics[tid] || funding} className={classes.img} />
          <div className={classes.desc}>
            <Typography variant="subtitle1" align="center">
              {desc}
            </Typography>
          </div>
          <Typography component="p" className={classes.title}>
            {title}
          </Typography>
          <Typography component="p" className={classes.developer}>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;&nbsp;&nbsp;{author}
          </Typography>
          <Rating
            className={classes.ratio}
            name="read-only"
            precision={0.1}
            value={ratio}
            readOnly
          />
        </CardActionArea>
      </CardContent>
    </Card>
  );
};
