import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BackspaceOutlinedIcon from "@material-ui/icons/BackspaceOutlined";

const useStyles = makeStyles((theme) => ({
  fileInput: {
    width: 0,
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
  },
  attachIcon: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * TODO: 需要使用safari测试一下。
 *
 * @param props
 * @returns {JSX.Element}
 */
export default (props) => {
  const classes = useStyles();
  const refs = useRef(null);
  const [file, setFile] = useState();
  useEffect(() => {
    props.onChange(file);
  }, [file]);

  let content;
  if (file) {
    content = (
      <React.Fragment>
        {file.name}
        <BackspaceOutlinedIcon
          size="small"
          className={classes.deleteIcon}
          onClick={(event) => {
            refs.current.value = "";
            setFile("");
            event.stopPropagation();
          }}
        />
      </React.Fragment>
    );
  } else {
    content = "上传模板";
  }
  return (
    <Button
      variant="outlined"
      {...props}
      onClick={() => {
        refs.current.click();
      }}
    >
      <Input
        inputRef={refs}
        type="file"
        className={classes.fileInput}
        onChange={(event) => {
          const files = refs.current.files;
          if (files.length === 0) {
            setFile("");
          } else {
            setFile(files[0]);
          }
        }}
      />
      <AttachFileIcon className={classes.attachIcon} />
      {content}
    </Button>
  );
};
