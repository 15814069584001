import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import QuestionToolBar from "components/QuestionToolBar";
import update from "immutability-helper";
import { TEXT } from "common/commonConstants/QuestionCardType";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import TemplateFormStyles from "./TemplateFormStyles";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getTemplate, getTemplateCategories } from "service/template";
import QuestionSection from "components/QuestionTemplateSection";
import { FORMAT_TEXT } from "common/commonConstants/QuestionTypeFormat";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InputOutlinedIcon from "@material-ui/icons/InputOutlined";
import RiskSection from "./Components/RiskSection";
import VariableTemplateSection from "components/VariableTemplateSection";
import {
  createAuditTemplate,
  updateAuditTemplate,
  getAuditTemplateCategories,
  getAuditTemplate,
} from "service/auditTemplate";
import Switch from "@material-ui/core/Switch";
import { QuestionType } from "../../components/QuestionTemplateSection/QuestionTemplateCard";

const styles = TemplateFormStyles;

const TITLE_KEY = "title";

class TemplateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTemplateLoading: false,
      isSubmitting: false,
      activityCardKey: TITLE_KEY,
      templateCategories: [],
      questionModules: {},
      riskModules: {},
      template: {
        title: "",
        description: "",
        publicTemplate: false,
        questions: [],
        variables: [],
        risks: [],
      },
      file: undefined,
    };

    this.keyIndex = 0;
    this.riskIndex = 0;
    this.valIndex = 0;

    this.activeRef = React.createRef();
    this.toolbarRef = React.createRef();
    this.topOfToolbar = 0;
  }

  genRiskCardKey = () => `risk_key_${this.riskIndex++}`;

  genQuestionCardKey = () => `question_key_${this.keyIndex++}`;

  genVariableName = () => `var${this.valIndex++}`;

  isActive = (key) => {
    return this.state.activityCardKey === key;
  };

  setActivityCard = (activityCardKey) => {
    this.setState({
      activityCardKey,
    });
  };

  setTemplate = (template) => {
    // TODO: remove, workaround
    window.__questions = template.questions || [];
    window.__midVars = template.variables || [];

    this.setState({
      template,
    });
  };

  onInputValueChange = (event) => {
    const propertyName = event.target.name;
    const value = event.target.value;
    const newTemplate = update(this.state.template, {
      [propertyName]: { $set: value },
    });
    this.setTemplate(newTemplate);
  };

  onChangeVarsOf = (event) => {
    const index = event.target.getAttribute("x-index");
    const propertyName = event.target.name;
    // func = func || ((e) => e.target.value);
    const value = event.target.value;

    const newTemplate = update(this.state.template, {
      variables: { [index]: { [propertyName]: { $set: value } } },
    });
    this.setTemplate(newTemplate);
  };

  onSwitchValueChange = (event) => {
    const propertyName = event.target.name;
    const value = event.target.checked;
    const newTemplate = update(this.state.template, {
      [propertyName]: { $set: value },
    });
    this.setTemplate(newTemplate);
  };

  onQuestionsChange = (newQuestions, originQuestions) => {
    const newTemplate = update(this.state.template, {
      questions: { $set: newQuestions },
    });
    this.setTemplate(newTemplate);
  };

  onRisksChange = (newQuestions, originQuestions) => {
    const newTemplate = update(this.state.template, {
      risks: { $set: newQuestions },
    });
    this.setTemplate(newTemplate);
  };

  addRisk = () => {
    let position;
    if (this.isActive(TITLE_KEY)) {
      position = 0;
    } else {
      position =
        (this.state.template.risks || []).findIndex(
          (f) => f.key === this.state.activityCardKey
        ) + 1;
    }
    const key = this.genRiskCardKey();
    const newTemplate = update(this.state.template, {
      risks: {
        $splice: [
          [
            position,
            0,
            {
              key,
              enabled: true,
            },
          ],
        ],
      },
    });
    this.setState({
      activityCardKey: key,
    });
    this.setTemplate(newTemplate);
  };

  addQuestion = () => {
    let position;
    if (this.isActive(TITLE_KEY)) {
      position = 0;
    } else {
      position = (this.state.template.questions || []).findIndex(
        (f) => f.key === this.state.activityCardKey
      );
      if (position < 0) {
        position = (this.state.template.questions || []).length + 1;
      } else {
        position += 1;
      }
    }
    const key = this.genQuestionCardKey();
    const newTemplate = update(this.state.template, {
      questions: {
        $splice: [
          [
            position,
            0,
            {
              key,
              var: this.genVariableName(),
              questionType: QuestionType.Text,
              type: TEXT,
              format: FORMAT_TEXT,
              enabled: true,
              editable: true,
              options: [],
              columns: [],
              dependency: [],
            },
          ],
        ],
      },
    });
    this.setState({
      activityCardKey: key,
    });
    this.setTemplate(newTemplate);
  };

  updateRiskModules = (risks) => {
    this.setState({
      riskModules: this.getModules(risks),
    });
  };

  updateQuestionModules = (questions) => {
    this.setState({
      questionModules: this.getModules(questions),
    });
  };

  getModules = (questions) => {
    const modules = (questions || []).reduce((pre, question) => {
      if (!question.moduleLevel1) {
        return pre;
      }
      if (question.moduleLevel1 in pre === false) {
        pre[question.moduleLevel1] = {};
      }
      if (!question.moduleLevel2) {
        return pre;
      }
      const level2 = pre[question.moduleLevel1];
      if (question.moduleLevel2 in level2 === false) {
        level2[question.moduleLevel2] = true;
      }
      return pre;
    }, {});
    return modules;
  };

  // loading
  componentDidMount() {
    const { templateId } = this.props.match.params;
    // 初始化模板分类
    (async () => {
      const { data: categories } = await getAuditTemplateCategories();
      this.setState({
        templateCategories: categories,
      });
    })();

    // 初始化模板
    (async () => {
      this.setState({ isTemplateLoading: true });
      try {
        if (!templateId) {
          return;
        }
        const { data: auditTemplate } = await getAuditTemplate(templateId);
        (auditTemplate.questions || []).forEach((question, index) => {
          question.key = `__QUESTION_${index}`;
        });
        // 假设变量名是a12这种格式，找到后面数字最大的+1作为index。
        this.valIndex =
          (auditTemplate.questions || [])
            .filter(({ var: v }) => !!v) // 找到var存在的
            .map(({ var: v }) => parseInt(v.substr(3)))
            .filter((num) => !isNaN(num))
            .reduce((pre, cur) => (pre > cur ? pre : cur), 0) + 1;
        this.riskIndex =
          (auditTemplate.risks || [])
            .filter(({ key: v }) => !!v) // 找到var存在的
            .map(({ key: v }) => parseInt(v.substr(9)))
            .filter((num) => !isNaN(num))
            .reduce((pre, cur) => (pre > cur ? pre : cur), 0) + 1;
        //
        const questionModules = this.getModules(auditTemplate.questions);
        const riskModules = this.getModules(auditTemplate.risks);
        this.setState({
          questionModules,
          riskModules,
        });
        this.setTemplate(auditTemplate);
      } catch (e) {
        // TODO: error
      } finally {
        this.setState({ isTemplateLoading: false });
      }
    })();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // 更新toolbar的位置
    if (this.activeRef.current) {
      this.topOfToolbar = this.activeRef.current.offsetTop;
      this.toolbarRef.current.style.top = this.topOfToolbar + "px";
    }
  }

  render() {
    const { classes, history } = this.props;
    const { template } = this.state;

    return (
      <Container maxWidth="md" className={classes.main}>
        <QuestionToolBar ref={this.toolbarRef}>
          <IconButton size="medium" onClick={this.addQuestion}>
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton size="medium" onClick={this.addRisk}>
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton aria-label="add to favorites" size="medium">
            <InputOutlinedIcon className={classes.flipImage} />
          </IconButton>
          <IconButton aria-label="add to favorites" size="medium">
            <InputOutlinedIcon />
          </IconButton>
        </QuestionToolBar>

        <Grid className={classes.question} key="__title">
          <Card
            className={classes.templateHeader}
            onClick={() => this.setActivityCard(TITLE_KEY)}
            ref={this.isActive(TITLE_KEY) ? this.activeRef : null}
          >
            <div className={classes.questionBar} />
            <div style={{ display: "flex" }}>
              <div
                className={classes.questionActiveBar}
                style={{ opacity: this.isActive(TITLE_KEY) ? 1 : 0 }}
              />
              <div style={{ flexGrow: 1 }}>
                <CardContent className={classes.questionContent}>
                  <Typography variant="h4">
                    <TextField
                      fullWidth
                      autoFocus
                      margin="normal"
                      placeholder="模板标题"
                      name="title"
                      value={template.title}
                      InputProps={{
                        className: classes.test,
                      }}
                      onChange={this.onInputValueChange}
                    />
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="模板描述"
                    size="small"
                    value={template.description}
                    name="description"
                    InputProps={{
                      className: classes.test,
                    }}
                    onChange={this.onInputValueChange}
                  />
                </CardContent>
                <CardActions
                  style={{
                    display: "flex",
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingBottom: 16,
                  }}
                >
                  <Autocomplete
                    id="combo-box-demo"
                    freeSolo
                    value={template.category || ""}
                    options={this.state.templateCategories}
                    getOptionLabel={(category) => category}
                    style={{ width: 300, marginRight: 40 }}
                    onChange={(event, value) => {
                      this.onInputValueChange({
                        target: {
                          name: "category",
                          value,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="分类"
                        variant="outlined"
                        name="category"
                        onChange={this.onInputValueChange}
                      />
                    )}
                  />
                  <TextField
                    placeholder="开发者"
                    name="author"
                    InputProps={{
                      className: classes.test,
                    }}
                    value={template.author}
                    onChange={this.onInputValueChange}
                  />
                  <Typography color="textPrimary" align="center">
                    {template.publicTemplate ? "公开" : "私有"}
                    <Switch
                      color="primary"
                      name="publicTemplate"
                      checked={template.publicTemplate}
                      onChange={this.onSwitchValueChange}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Typography>
                  <div style={{ flex: 1 }} />
                </CardActions>
              </div>
            </div>
          </Card>
        </Grid>

        <Grid className={classes.mediumGutterTop}>
          <Typography variant="h4">问题列表</Typography>
        </Grid>

        <QuestionSection
          activityCardKey={this.state.activityCardKey}
          questions={template.questions}
          modules={this.state.questionModules}
          activeRef={this.activeRef}
          onFocus={this.setActivityCard}
          onModuleChange={this.updateQuestionModules}
          onQuestionsChange={this.onQuestionsChange}
        />

        <VariableTemplateSection
          activityCardKey={this.state.activityCardKey}
          variables={template.variables}
          name="variables"
          onFocus={this.setActivityCard}
          onChange={this.onInputValueChange}
          activeRef={this.activeRef}
        />
        <Grid className={classes.mediumGutterTop}>
          <Typography variant="h4">风险列表</Typography>
        </Grid>

        <RiskSection
          activityCardKey={this.state.activityCardKey}
          risks={template.risks}
          modules={this.state.riskModules}
          activeRef={this.activeRef}
          onFocus={this.setActivityCard}
          onModuleChange={this.updateRiskModules}
          onRisksChange={this.onRisksChange}
        />

        <Grid className={classes.submit} key="__submit">
          <Button
            disabled={this.state.isSubmitting}
            variant="contained"
            color="primary"
            onClick={() => {
              // submit;
              (async () => {
                this.setState({ isSubmitting: true });
                try {
                  if (template.id) {
                    await updateAuditTemplate(template);
                  }
                  await createAuditTemplate(template);
                  history.push("/auditTemplateForm");
                } catch (e) {
                  console.error(e);
                  this.setState({ isSubmitting: false });
                }
              })();
            }}
          >
            提交模板
          </Button>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(withRouter(TemplateForm));
