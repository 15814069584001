export const downloadURI = (uri, name) => {
  const link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute("download", name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const openInNewTab = (href) => {
  Object.assign(document.createElement("a"), {
    target: "_blank",
    href,
  }).click();
};

export function scrollTo(element, callback) {
  const startingY = window.pageYOffset;
  const elementY = element.getBoundingClientRect().top + startingY - 75;
  const diff = elementY - startingY;
  let start;

  const duration = 500;
  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    const percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    } else {
      callback && callback();
    }
  });
}

export function scrollOut(element, callback) {
  const startingY = window.pageYOffset;
  const elementY = element.getBoundingClientRect().bottom + startingY - 75;
  const diff = elementY - startingY;
  let start;

  const duration = 500;
  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    const percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    } else {
      callback();
    }
  });
}
