import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Blockly from "blockly/core";
import locale from "blockly/msg/en";
import "blockly/blocks";

const styles = (theme) => ({
  editor: {
    width: "100%",
    height: 500,
  },
});

Blockly.setLocale(locale);

class BlocklyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.blocklyDiv = React.createRef();
    this.toolbox = React.createRef();
  }

  componentDidMount() {
    const { initialXml, children, ...rest } = this.props;
    this.primaryWorkspace = Blockly.inject(this.blocklyDiv.current, {
      toolbox: this.toolbox.current,
      ...rest,
    });
  }

  get workspace() {
    return this.primaryWorkspace;
  }

  render() {
    const { classes, children } = this.props;
    return (
      <React.Fragment>
        <div ref={this.blocklyDiv} id="blocklyDiv" className={classes.editor} />
        <xml is="blockly" style={{ display: "none" }} ref={this.toolbox}>
          {children}
        </xml>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BlocklyEditor);
