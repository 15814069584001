import React from "react";
import update from "immutability-helper";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

import commonStyles from "common/commonStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import TextFieldWithBlockly from "../../../components/TextFieldWithBlockly";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import TextFieldWithCodeMirror from "components/TextFieldWithCodeMirror";

const useStyles = makeStyles((theme) => ({
  templateHeader: {
    marginTop: theme.spacing(5),
    position: "relative",
  },
  questionActiveBar: {
    width: "6px",
    height: "100%",
    position: "absolute",
    backgroundColor: "#4285f4",
  },
  questionContent: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    overflow: "hidden",
  },
  smallGutterTop: {
    marginTop: theme.spacing(2),
  },

  questionTypeSelect: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  menuItemText: {
    paddingRight: theme.spacing(6),
  },
  ...commonStyles(theme),
}));

export default React.memo(
  ({ active, risk, modules, onChange, onPositionChange }) => {
    const classes = useStyles();

    const onValueChange = (event) => {
      const propertyName = event.target.name;
      const value = event.target.value;
      let newRisk = update(risk, {
        [propertyName]: { $set: value },
      });

      onChange(newRisk, risk);
    };

    const valueChange = (func) => (event) => {
      const propertyName = event.target.name;
      const newRisk = update(risk, {
        [propertyName]: { $set: func(event) },
      });
      onChange(newRisk, risk);
    };

    const isEditable = () => active && risk.enabled;
    const isNotEditable = () => !isEditable();

    return (
      <Card className={classes.templateHeader}>
        <div style={{ display: "flex" }}>
          <div
            className={classes.questionActiveBar}
            style={{ opacity: active ? 1 : 0 }}
          />
          <div className={classes.questionContent}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid container spacing={3} item xs={12}>
                  <Grid item xs={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      freeSolo
                      value={risk.moduleLevel1 || ""}
                      options={Object.keys(modules)}
                      getOptionLabel={(category) => category}
                      onChange={(event, value) => {
                        onValueChange({
                          target: {
                            name: "moduleLevel1",
                            value,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="分类1"
                          variant="outlined"
                          name="moduleLevel1"
                          onChange={onValueChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      freeSolo
                      value={risk.moduleLevel2 || ""}
                      options={Object.keys(modules[risk.moduleLevel1] || {})}
                      getOptionLabel={(category) => category}
                      name="moduleLevel2"
                      onChange={(event, value) => {
                        onValueChange({
                          target: {
                            name: "moduleLevel2",
                            value,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="分类2"
                          variant="outlined"
                          name="moduleLevel2"
                          onChange={onValueChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color="textPrimary" align="right">
                      {risk.enabled ? "显示" : "隐藏"}
                      <Switch
                        disabled={!active}
                        color="primary"
                        name="enabled"
                        checked={risk.enabled}
                        onChange={valueChange((event) => event.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={isNotEditable()}
                    name="description"
                    value={risk.description}
                    margin="normal"
                    placeholder="风险描述"
                    InputProps={{
                      className: classes.test,
                    }}
                    onChange={onValueChange}
                  />
                </Grid>
                {(() => {
                  if (!active) {
                    return null;
                  }
                  return (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          disabled={isNotEditable()}
                          name="advice"
                          value={risk.advice}
                          placeholder="修改建议"
                          onChange={onValueChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Paper variant="outlined" style={{ padding: "8px" }}>
                          <TextField
                            fullWidth
                            disabled={isNotEditable()}
                            name="hasRiskDescription"
                            value={risk.hasRiskDescription}
                            placeholder="存在描述"
                            label="存在描述"
                            InputProps={{
                              className: classes.test,
                            }}
                            onChange={onValueChange}
                          />
                          <TextFieldWithCodeMirror
                            current={{}}
                            fullWidth
                            disabled={isNotEditable()}
                            name="hasRiskExpression"
                            value={risk.hasRiskExpression}
                            placeholder="返回结果必须为true或者false"
                            label="存在公式"
                            onChange={onValueChange}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper variant="outlined" style={{ padding: "8px" }}>
                          <TextField
                            fullWidth
                            disabled={isNotEditable()}
                            name="extentDescription"
                            value={risk.extentDescription}
                            placeholder="程度描述"
                            label="程度描述"
                            onChange={onValueChange}
                          />
                          <TextFieldWithCodeMirror
                            current={{}}
                            fullWidth
                            disabled={isNotEditable()}
                            name="extentExpression"
                            value={risk.extentExpression}
                            placeholder="返回结果为字符串HIGH, MEDIUM或者LOW"
                            label="程度公式"
                            onChange={onValueChange}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper variant="outlined" style={{ padding: "8px" }}>
                          <TextField
                            fullWidth
                            disabled={isNotEditable()}
                            name="ratioDescription"
                            value={risk.ratioDescription}
                            placeholder="概率描述"
                            label="概率描述"
                            onChange={onValueChange}
                          />
                          <TextFieldWithCodeMirror
                            current={{}}
                            fullWidth
                            disabled={isNotEditable()}
                            name="ratioExpression"
                            value={risk.ratioExpression}
                            placeholder="返回结果为字符串HIGH, MEDIUM或者LOW"
                            label="概率公式"
                            onChange={onValueChange}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper variant="outlined" style={{ padding: "8px" }}>
                          <TextFieldWithCodeMirror
                            current={{}}
                            fullWidth
                            disabled={isNotEditable()}
                            name="levelExpression"
                            value={risk.levelExpression}
                            placeholder="返回值为整数1~9。 未提供的情况下，将按照默认公式通过概率和程度来生成等级。"
                            label="等级公式"
                            onChange={onValueChange}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper variant="outlined" style={{ padding: "8px" }}>
                          <TextFieldWithCodeMirror
                            current={{}}
                            fullWidth
                            disabled={isNotEditable()}
                            name="lightExpression"
                            value={risk.lightExpression}
                            placeholder="返回值为RED, YELLOW或者GREEN。未提供的情况下，将按照默认公式通过等级来生成灯色。"
                            label="灯色公式"
                            onChange={onValueChange}
                          />
                        </Paper>
                      </Grid>
                    </React.Fragment>
                  );
                })()}
              </Grid>
            </CardContent>
            <Divider />
            {(() => {
              if (!isEditable()) {
                return null;
              }
              return (
                <CardActions
                  style={{
                    display: isEditable() ? "flex" : "none",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    aria-label="add to favorites"
                    onClick={(event) => {
                      onPositionChange("UP", risk, event);
                    }}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    aria-label="add to favorites"
                    onClick={(event) => {
                      onPositionChange("DOWN", risk, event);
                    }}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                  <IconButton
                    aria-label="add to favorites"
                    onClick={(event) => {
                      onPositionChange("DELETE", risk, event);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              );
            })()}
          </div>
        </div>
      </Card>
    );
  }
);
