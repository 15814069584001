import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ContractCreateCard from "pages/Main/ContractCard";
import ContractCard from "components/ContractCard";
import map from "lodash/map";
import commonStyles from "common/commonStyles";
import { fetchAuditTemplates } from "service/auditTemplate";
import { fetchAudits } from "service/audit";
import AuditCard from "../../components/AuditCard";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    maxWidth: 1200,
    margin: "0 auto",
  },
  templateContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  ...commonStyles(theme),
}));

export default () => {
  const classes = useStyles();
  const [reloading, setReloading] = useState({});
  const [auditTemplates, setAuditTemplates] = useState([]);
  const [isTemplateLoading, setTemplateLoading] = useState(false);
  const [audits, setAudits] = useState([]);
  const [isContractLoading, setContractLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setTemplateLoading(true);
      try {
        const { data: responseBody } = await fetchAuditTemplates();
        setAuditTemplates(responseBody.members);
      } catch (e) {
      } finally {
        setTemplateLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setContractLoading(true);
      try {
        const { data: responseBody } = await fetchAudits();
        setAudits(responseBody.members);
      } catch (e) {
      } finally {
        setContractLoading(false);
      }
    })();
  }, [reloading]);

  return (
    <Grid className={classes.root}>
      <Grid item key="__auditTemplates">
        <Typography variant="h5">模板</Typography>
        <Grid container spacing={5} className={classes.templateContainer}>
          {map(auditTemplates, (template, templateIndex) => {
            return (
              <Grid item xs={3} key={template.id}>
                <ContractCreateCard
                  title={template.title}
                  description={template.description}
                  to={`/auditForm/create/${template.id}`}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <Grid item key="__savedAudits" className={classes.largeGutterTop}>
        <Typography variant="h5">已保存的回答</Typography>
        <Grid container spacing={5} className={classes.templateContainer}>
          {map(audits, (audit, auditIdx) => {
            return (
              <Grid item xs={3}>
                <AuditCard
                  id={audit.id}
                  title={audit.name}
                  description={`修改于${audit.updateTime}`}
                  onDelete={() => {
                    setReloading({});
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
