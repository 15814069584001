export default (theme) => ({
  main: {
    position: "relative",
    paddingTop: theme.spacing(10),
  },
  hidden: {
    display: "none!important",
  },

  smallGutterTop: {
    marginTop: theme.spacing(3),
  },
  smallGutterBottom: {
    marginBottom: theme.spacing(3),
  },

  mediumGutterBottom: {
    marginBottom: theme.spacing(5),
  },

  mediumGutterTop: {
    marginTop: theme.spacing(5),
  },

  largeGutterTop: {
    marginTop: theme.spacing(7),
  },

  mainGutterTop: {
    paddingTop: theme.spacing(10),
  },
});
