import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import update from "immutability-helper";
import { withStyles } from "@material-ui/core";
import VariableTemplateCard from "./VariableTemplateCard";
import { FORMAT_TEXT } from "common/commonConstants/QuestionTypeFormat";

const VARIABLE_KEY = "variables";

const styles = (theme) => ({
  templateHeader: {
    marginTop: theme.spacing(5),
    position: "relative",
  },

  questionActiveBar: {
    width: "6px",
    height: "100%",
    position: "absolute",
    backgroundColor: "#4285f4",
  },
});

class Index extends React.PureComponent {
  isActive = () => this.props.activityCardKey === VARIABLE_KEY;

  onVariableChange = (event) => {
    const index = event.target.getAttribute("x-index");
    const propertyName = event.target.name;
    const value = event.target.value;

    const newVariables = update(this.props.variables, {
      [index]: { [propertyName]: { $set: value } },
    });
    this.props.onChange({
      target: {
        name: this.props.name,
        value: newVariables,
      },
    });
  };

  onVariablePositionChange = (action, originVariable, variableIdx) => {
    const { name, variables, onChange } = this.props;
    let newVariables;
    switch (action) {
      case "DELETE":
        newVariables = update(variables, {
          $splice: [[variableIdx, 1]],
        });
        break;
      case "UP":
        if (variableIdx === 0) {
          return;
        }
        newVariables = update(variables, {
          $splice: [
            [variableIdx, 1],
            [variableIdx - 1, 0, originVariable],
          ],
        });
        break;
      case "DOWN":
        if (variableIdx === variables.length - 1) {
          return;
        }
        newVariables = update(variables, {
          $splice: [
            [variableIdx, 1],
            [variableIdx + 1, 0, originVariable],
          ],
        });
        break;
      default:
        break;
    }
    onChange({
      target: {
        name,
        value: newVariables,
      },
    });
  };

  addVar = (idx) => {
    const { variables, name, onChange } = this.props;
    const newVariables = update(variables, {
      $splice: [
        [
          idx + 1,
          0,
          {
            key: `variable_${variables.length + 1}`,
            variableName: `variable${variables.length + 1}`,
            expression: {},
            format: FORMAT_TEXT,
          },
        ],
      ],
    });
    onChange({
      target: {
        name,
        value: newVariables,
      },
    });
  };

  render() {
    const {
      variables,
      name,
      onFocus: setActivityGrid,
      onChange,
      classes,
      activeRef,
    } = this.props;

    const isActive = this.isActive();

    return (
      <Grid className={classes.question} key="__variables" item>
        <Card
          className={classes.templateHeader}
          onClick={() => setActivityGrid(VARIABLE_KEY)}
          ref={isActive ? activeRef : null}
        >
          <div
            className={classes.questionActiveBar}
            style={{ opacity: isActive ? 1 : 0 }}
          />
          <CardContent className={classes.questionContent}>
            <Typography variant="h5">中间变量列表</Typography>
            {(() => {
              return (variables || []).map((variable, varIdx) => {
                return (
                  <VariableTemplateCard
                    active={isActive}
                    onVariableChange={this.onVariableChange}
                    onVariablePositionChange={this.onVariablePositionChange}
                    variable={variable}
                    variableIndex={varIdx}
                    addVar={this.addVar}
                    showDeleteButton={variables.length > 1}
                  />
                );
              });
            })()}
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(Index);
