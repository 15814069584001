import axios from "axios";

export function fetchAudits() {
  return axios.get("/api/audits");
}

export function createAudit(audit) {
  return axios.post("/api/audits", audit);
}

export function getAudit(auditId) {
  return axios.get(`/api/audits/${auditId}`);
}

export function deleteAudit(auditId) {
  return axios.delete(`/api/audits/${auditId}`);
}
