import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink, Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import classnames from "classnames";

import TemplateForm from "pages/TemplateForm";
import AuditTemplateForm from "pages/AuditTemplateForm";
import ContractForm from "pages/ContractForm";
import AuditForm from "pages/AuditForm";
import UserForm from "pages/UserForm";
import AuditRiskForm from "pages/AuditRiskForm";
import UpdateUser from "pages/UpdateUser";
import Main from "pages/Main";
import ManageTemplates from "pages/ManageTemplates";
import ManageAuditTemplates from "pages/ManageAuditTemplates";
import CreateContract from "pages/CreateContract";
import CreateAudit from "pages/CreateAudit";
import Snackbar from "layout/Components/SnackBar";
import { useHistory, useLocation } from "react-router";
import { Container, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsAdmin,
  selectIsCreator,
  selectUser,
} from "pages/Login/userSlice";
import { deepOrange } from "@material-ui/core/colors";
import AboutService from "pages/AboutService";
import Statistic from "pages/Statistic";
import Preview from "pages/Preview";
import Logo from "assets/logo.png";
import PrivateRoute from "components/PrivateRoute";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import MobileMenu from "./Components/MobileMenu";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import { logout } from "../pages/Login/userSlice";
import Badge from "@material-ui/core/Badge";
import FAQ from "../pages/FAQ";
import INFO from "../pages/INFO";


const useStyle = makeStyles((theme) => ({
  navBar: {
    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
    transition:
      "background-color 0.5s ease-in-out, height 0.5s ease-in-out,padding 0.5s ease-in-out",
    height: "72px",
    backgroundColor: theme.palette.common.white,
    flexDirection: "row",
    alignItems: "center",
  },
  navBarTransparent: {
    backgroundColor: "rgba(0,0,0,.2)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: "130px",
  },
  main: {
    minHeight: "100vh",
    color: theme.fontColor.grey2,
  },
  signUpButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: "rgb(38, 38, 39)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(38, 38, 39)",
      opacity: 0.8,
    },
  },

  loginButton: {
    marginLeft: theme.spacing(4),
  },

  menuButton: {
    marginRight: theme.spacing(3),
    fontSize: "1.1rem",
    transition: "color 0.5s ease-in-out, fontSize 0.5s ease-in-out",
  },
  serviceButton: {
    color: "white",
  },
  logo: {
    width: 100,
    color: theme.palette.common.black,
    transition: "width 0.5s ease-in-out, padding 0.5s ease-in-out",
  },

  logoBig: {
    width: 200,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[200],
  },
  userProfile: {
    backgroundColor: "rgb(189 189 189 / .5)",

    "&:hover": {
      backgroundColor: "rgb(189 189 189 / 1)",
    },
  },
  superScript: {
    "& > span": {
      opacity: 0.8,
      backgroundColor: "orange",
    },
  },
}));

export default () => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMenuOpen, setMenuOpen] = useState();
  const anchorRef = useRef();
  const [userProfile, setUserProfile] = useState();
  const classes = useStyle();
  const [isTop, setTop] = useState(true);
  let location = useLocation();
  let history = useHistory();
  const isAdmin = useSelector(selectIsAdmin);
  const isCreator = useSelector(selectIsCreator);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const handleScroll = (event) => {
    const top = window.scrollY == 0;
    if (isTop !== top) {
      setTop(top);
    }
  };

  const handleClose = () => {
    setUserProfile(false);
  };

  const needDynamicAppBar = () => {
    return !isPhone && isTop && location.pathname === "/";
  };

  useEffect(() => {
    // TODO: 优化一下，不需要每次都注册，取消
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={classnames(classes.navBar, {
          [classes.navBarTransparent]: needDynamicAppBar(),
        })}
      >
        <Container maxWidth="lg">
          <Toolbar>
            {isPhone ? (
              <IconButton
                onClick={() => {
                  setMenuOpen(true);
                }}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            <Typography
              variant="h3"
              color="primary"
              className={classnames({
                [classes.logo]: true,
                [classes.logoBig]: needDynamicAppBar(),
              })}
              component={RouterLink}
              to="/"
            >
              <img
                src={Logo}
                className={classnames({
                  [classes.logo]: true,
                  [classes.logoBig]: needDynamicAppBar(),
                })}
              />
            </Typography>
            <div style={{ flex: 1 }} />
            {!isPhone ? (
              <React.Fragment>
                <Button
                  className={classnames({
                    [classes.menuButton]: true,
                    [classes.serviceButton]: needDynamicAppBar(),
                  })}
                  to="/#generate"
                  component={RouterLink}
                >
                  DoxAuto平台
                </Button>
                <Button
                  className={classnames({
                    [classes.menuButton]: true,
                    [classes.serviceButton]: needDynamicAppBar(),
                  })}
                  to="/manageTemplates"
                  component={RouterLink}
                >
                  <Badge
                    badgeContent="免费试用"
                    color="primary"
                    className={classes.superScript}
                  >
                    我的DoxAuto
                  </Badge>
                </Button>
                {isAdmin ? (
                  <React.Fragment>
                    <Button
                      className={classes.menuButton}
                      to="/createContract"
                      component={RouterLink}
                    >
                      临时:合同
                    </Button>
                    <Button
                      className={classes.menuButton}
                      to="/manageAuditTemplates"
                      component={RouterLink}
                    >
                      审核管理
                    </Button>
                    <Button
                      className={classes.menuButton}
                      to="/createAudit"
                      component={RouterLink}
                    >
                      临时:审核
                    </Button>
                    <Button
                      className={classes.menuButton}
                      to="/UserForm/create"
                      component={RouterLink}
                    >
                      创建用户
                    </Button>
                  </React.Fragment>
                ) : null}
                <Button
                  className={classnames({
                    [classes.menuButton]: true,
                    [classes.serviceButton]: needDynamicAppBar(),
                  })}
                  to="/faq"
                  component={RouterLink}
                >
                  使用指南
                </Button>
                <Button
                                  className={classnames({
                                    [classes.menuButton]: true,
                                    [classes.serviceButton]: needDynamicAppBar(),
                                  })}
                                  to="/info"
                                  component={RouterLink}
                                >
                   资讯与活动
                </Button>
                <Button
                  className={classnames({
                    [classes.menuButton]: true,
                    [classes.serviceButton]: needDynamicAppBar(),
                  })}
                  to="/aboutService"
                  component={RouterLink}
                >
                  关于我们
                </Button>
              </React.Fragment>
            ) : null}

            {user.username ? (
              isPhone ? (
                <Button
                  color="black"
                  variant="outlined"
                  disableElevation
                  size={isPhone ? "medium" : "large"}
                  className={classes.loginButton}
                  onClick={() => dispatch(logout())}
                >
                  退 出
                </Button>
              ) : (
                <React.Fragment>
                  {/*<Avatar className={classes.orange}>*/}
                  {/*  {user.username.substr(0, 1).toUpperCase()}*/}
                  {/*</Avatar>*/}
                  <Button
                    size="large"
                    ref={anchorRef}
                    endIcon={<FontAwesomeIcon icon={faUserTie} />}
                    onClick={(event) => {
                      setUserProfile(true);
                    }}
                    style={{ textTransform: "none" }}
                    className={classnames({
                      [classes.menuButton]: true,
                    })}
                    variant="text"
                  >
                    {user.nickname}
                  </Button>

                  <Popper
                    open={userProfile}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={userProfile}
                              id="menu-list-grow"
                              // onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={handleClose}
                                component={RouterLink}
                                to="/updatePassword"
                              >
                                修改密码
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  dispatch(logout());
                                  handleClose();
                                }}
                              >
                                退出
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                <Button
                  color="black"
                  variant="outlined"
                  disableElevation
                  size={isPhone ? "medium" : "large"}
                  className={classes.loginButton}
                  component={RouterLink}
                  to="/login"
                >
                  登 录
                </Button>
                <Button
                  color="black"
                  variant="contained"
                  className={classes.signUpButton}
                  disableElevation
                  size={isPhone ? "medium" : "large"}
                  component={RouterLink}
                  to="/sign-up"
                >
                  注 册
                </Button>
              </React.Fragment>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}
      >
        <MobileMenu onMenuSelected={() => setMenuOpen(false)} user={user} />
      </Drawer>
      <main className={classes.main} onScroll={handleScroll}>
        <Snackbar />
        <Switch>
          <Route path="/manageTemplates">
            <ManageTemplates />
          </Route>
          <PrivateRoute path="/manageAuditTemplates">
            <ManageAuditTemplates />
          </PrivateRoute>
          <PrivateRoute path="/createContract">
            <CreateContract />
          </PrivateRoute>
          <PrivateRoute path="/createAudit">
            <CreateAudit />
          </PrivateRoute>
          <PrivateRoute path="/templateForm/create">
            <TemplateForm />
          </PrivateRoute>
          <Route path="/templateForm/update/:templateId">
            <TemplateForm />
          </Route>
          <PrivateRoute path="/auditTemplateForm/create">
            <AuditTemplateForm />
          </PrivateRoute>
          <PrivateRoute path="/auditTemplateForm/update/:templateId">
            <AuditTemplateForm />
          </PrivateRoute>
          <Route path="/contractForm/create/:templateId">
            <ContractForm />
          </Route>
          <PrivateRoute path="/contractForm/update/:contractId">
            <ContractForm />
          </PrivateRoute>
          <Route path="/AuditForm/create/:auditTemplateId">
            <AuditForm />
          </Route>
          <PrivateRoute path="/AuditForm/update/:auditId">
            <AuditForm />
          </PrivateRoute>
          <Route path="/AuditRiskForm/update/:auditId">
            <AuditRiskForm />
          </Route>
          <PrivateRoute path="/UserForm/create">
            <UserForm />
          </PrivateRoute>
          <PrivateRoute path="/updatePassword">
            <UpdateUser />
          </PrivateRoute>
          <Route path="/statistic/:contractId">
            <Statistic />
          </Route>
          <Route path="/aboutService">
            <AboutService />
          </Route>
          {/*}< Route path="/faq">
            <FAQ />
          </Route> */}
          <Route path='/faq' component={() => {
               window.location.replace('https://mp.weixin.qq.com/s?__biz=MzIwODMwMTEyMw==&mid=2247483956&idx=1&sn=6e0530e57a2f491acd1f12ed49814de2&chksm=97047f18a073f60e7162116c4b0152d951e6c4823d5be59e84e3a7e0bc44ec387006de14fe15&token=178338073&lang=zh_CN');
               return null;
          }}/>
           <Route path="/info">
            <INFO />
          </Route>
          <Route path="/preview/:contractId">
            <Preview />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </main>
    </React.Fragment>
  );
};
