import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Toolbar } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { error, selectErrorMessage } from "layout/layoutSlice";
import { faUserEdit, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { createUser, getUsers, updateUser } from "../../service/session";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import commonStyles from "../../common/commonStyles";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - 80px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scroll: {
    height: `calc(100vh - 80px)`,
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContainer: {
    height: 600,
  },
  loginContainer: {
    marginTop: "-15%",
    width: 400,
  },
  loginTitle: {
    display: "flex",
    justifyContent: "center",
  },
  formContainer: {},
  logo: {},
  ...commonStyles(theme),
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const errors = useSelector(selectErrorMessage);
  const [userParam, setUserParam] = useState({
    username: "",
    email: "",
    nickname: "",
    password: "",
    confirmPassword: "",
    enabled: true,
    roles: ["ROLE_USER"],
  });
  const [selectedUser, setSelectedUser] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const history = useHistory();

  const updateForm = (mondayUser) => {
    if (!mondayUser) {
      setUserParam({
        username: "",
        email: "",
        nickname: "",
        password: "",
        confirmPassword: "",
        enabled: true,
        roles: ["ROLE_USER"],
      });
    } else {
      setUserParam({
        id: mondayUser.id,
        username: mondayUser.username,
        email: mondayUser.email,
        nickname: mondayUser.nickname,
        password: "",
        confirmPassword: "",
        enabled: mondayUser.enabled,
        roles: mondayUser.authorities.map((r) => r.authority),
      });
    }
    setSelectedUser(mondayUser);
  };

  useEffect(() => {
    (async () => {
      const { data } = await getUsers();
      setAllUsers(data.members);
    })();
  }, []);

  const setValue = (event) => {
    const idx = event.target.name;
    const value = event.target.value || "";
    setUserParam({ ...userParam, [idx]: value });
  };

  return (
    <Container maxWidth="lg" className={classes.main}>
      <Grid container spacing={10} style={{ paddingTop: 80 }}>
        <Grid item md={6} className={classes.scroll}>
          <div>
            <TableContainer
              stickyHeader
              component={Paper}
              className={classes.tableContainer}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>用户名</TableCell>
                    <TableCell align="right">角色</TableCell>
                    <TableCell align="right">是否启用</TableCell>
                    <TableCell align="right">创建时间</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUsers.map((user) => (
                    <TableRow
                      key={user.id}
                      selected={selectedUser === user}
                      hover
                      onClick={() => {
                        if (selectedUser === user) {
                          updateForm(null);
                        } else {
                          updateForm(user);
                        }
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.username}
                      </TableCell>
                      <TableCell align="right">
                        {user.authorities.map((a) => a.authority).join(",")}
                      </TableCell>
                      <TableCell align="right">
                        {user.enabled ? "启用" : "禁用"}
                      </TableCell>
                      <TableCell align="right">{user.createTime}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.root}>
          <div className={classes.loginContainer}>
            <Toolbar className={classes.loginTitle}>
              <img src={Logo} />
            </Toolbar>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12}>
                <TextField
                  name="username"
                  disabled={!!userParam.id}
                  value={userParam.username}
                  onChange={setValue}
                  label="用户名"
                  variant="outlined"
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  onChange={setValue}
                  label="密码"
                  type="password"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="confirmPassword"
                  onChange={setValue}
                  label="确认密码"
                  type="password"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="nickname"
                  value={userParam.nickname || ""}
                  onChange={setValue}
                  label="别名"
                  variant="outlined"
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  value={userParam.email || ""}
                  onChange={setValue}
                  label="邮箱"
                  type="email"
                  variant="outlined"
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    角色
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={userParam.roles && userParam.roles[0]}
                    onChange={(event) => {
                      const roles = [event.target.value];
                      setUserParam({ ...userParam, roles });
                    }}
                    label="角色"
                  >
                    <MenuItem value={"ROLE_ADMIN"}>管理员</MenuItem>
                    <MenuItem value={"ROLE_CREATOR"}>开发者</MenuItem>
                    <MenuItem value={"ROLE_UNLIMITED_CREATOR"}>
                      不受限开发者
                    </MenuItem>
                    <MenuItem value={"ROLE_USER"}>一般用户</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value={true}
                  control={
                    <Switch
                      color="primary"
                      checked={userParam.enabled}
                      onChange={(event) => {
                        setUserParam({
                          ...userParam,
                          enabled: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="是否启用"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <IconButton
                  color={"primary"}
                  onClick={async () => {
                    try {
                      if (userParam.id) {
                        await updateUser(userParam.id, userParam);
                      } else {
                        if (
                          userParam.username === "" ||
                          userParam.password === ""
                        ) {
                          dispatch(error("用户或者密码不能为空"));
                        }
                        if (userParam.password !== userParam.confirmPassword) {
                          dispatch(error("两次输入密码不相同"));
                        }
                        await createUser(userParam);
                      }

                      history.push("/");
                    } catch (e) {
                      dispatch(error(e.message));
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={userParam.id ? faUserEdit : faUserPlus}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
