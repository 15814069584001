import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Container } from "@material-ui/core";

import { fetchTemplates } from "service/template";
import { fetchAuditTemplates } from "service/auditTemplate";
import { Link as RouterLink } from "react-router-dom";
import Categories from "common/commonConstants/TemplateCategories";
import commonStyles from "common/commonStyles";
import { selectUser } from "pages/Login/userSlice";
import Logo from "assets/logo.png";
import HomePic from "assets/home.png";
import AppStoreStyleCard from "./Components/AppStorStyleCard";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { selectIsCreator } from "../Login/userSlice";

const CARD_WIDTH = 256;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 32,
  },
  papersContainer: {
    marginTop: 64,
    maxWidth: 1200,
  },
  homePic: {
    height: "70vh",
    // background: `url(${Shanghai})`,
    // backgroundSize: "cover",
    position: "relative",

    "&::before": {
      content: "''",
      background: `url(${HomePic})`,
      backgroundSize: "cover",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },

  center: {
    transform: "translate(-50%, -50%)",
    top: "40%",
    position: "absolute",
    left: "40%",
  },
  bottom: {
    transform: "translate(-50%, -50%)",
    bottom: "10%",
    position: "absolute",
    left: "50%",
    textAlign: "center",
  },
  title: {
    color: "#eeeeee",
    fontWeight: "400",
    animationName: "$moveInLeft",
    animationDuration: "1s",
    animationTimingFunction: "ease-in",
  },
  subTitle: {
    color: "#eeeeee",
    paddingTop: theme.spacing(3),
    animationName: "$moveInRight",
    animationDuration: "1s",
    animationTimingFunction: "ease-in",
  },
  subButton: {
    fontSize: "1.5rem",
    color: "white",
    marginTop: theme.spacing(3),
    animationName: "$moveInBottom",
    animationDuration: "1s",
    animationTimingFunction: "ease-in",
  },
  mainPage: {
    backgroundColor: "rgb(245, 241, 234)",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  menu: {
    minWidth: "170px",
    paddingRight: theme.spacing(5),
  },
  menuTitle: {
    marginBottom: theme.spacing(1),
  },

  menuItem: {
    cursor: "pointer",
  },

  categoryContainer: {
    paddingBottom: theme.spacing(1),
  },

  itemContainer: {
    display: "inline-flex",
    flexWrap: "unset",
  },
  item: {
    width: `${CARD_WIDTH}px`,
    flexShrink: 0,
    boxSizing: "content-box",
    padding: "0 20px",
  },
  hideOverflow: {
    display: "inline-block",
    overflow: "hidden",
  },

  "@keyframes moveInLeft": {
    "0%": {
      opacity: 0,
      transform: "translateX(-10rem)",
    },

    "80%": {
      transform: "translateX(2rem)",
    },

    "100%": {
      opacity: 1,
      transform: "translate(0)",
    },
  },

  "@keyframes moveInRight": {
    "0%": {
      opacity: 0,
      transform: "translateX(10rem)",
    },

    "80%": {
      transform: "translateX(-2rem)",
    },

    "100%": {
      opacity: 1,
      transform: "translate(0)",
    },
  },

  "@keyframes moveInBottom": {
    "0%": {
      opacity: 0,
      transform: "translateY(3rem)",
    },

    "100%": {
      opacity: 1,
      transform: "translate(0)",
    },
  },

  footer: {
    backgroundColor: "#333",
  },

  footerLogoContainer: {
    paddingTop: theme.spacing(5),
  },
  footerLogo: {
    color: theme.palette.common.white,
  },

  footerNavList: {
    listStyle: "none",
    paddingBottom: theme.spacing(3),
    borderTop: "1px solid #eee",
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
  },
  footerNavItem: {
    display: "inline-block",
    "&:not(:last-child)": {
      marginRight: theme.spacing(3),
    },
  },
  footerNavItemLink: {
    textDecoration: "none",
    color: theme.palette.common.white,
    /*textTransform: "uppercase",*/
    display: "inline-block",
    transition: "all .2s",
/*
    "&:hover": {
      boxShadow: `0 1rem 2rem rgba(${theme.palette.common.black}, .4)`,
      transform: "rotate(5deg) scale(1.3)",
    },
    */
  },

  agendaContainer: {
    backgroundColor: theme.palette.common.white,
    boxSizing: "border-box",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    minHeight: "40vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  agenda: {
    color: theme.palette.grey[500],
    textAlign: "center",
  },

  agendaTitleIcon: {
    color: theme.palette.primary.dark,
    textAlign: "center",
  },
  agendaTitle: {
    marginTop: 20,
    marginBottom: 10,
    color: theme.palette.primary.dark,
    textAlign: "center",
  },

  titleText: {
    color: theme.fontColor.grey1,
  },

  subText: {
    color: theme.fontColor.grey3,
    paddingBottom: 3,
  },

  highlight: {
    color: theme.fontColor.grey3,
    fontStyle: "italic",
    fontWeight: 700,
  },

  demo: {
    paddingTop: theme.spacing(2),
    color: theme.fontColor.grey3,
  },

  picContainer: {
    paddingTop: theme.spacing(12),
  },

  rightPic: {
    width: 540,
  },
  rightContent: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  leftPic: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: "100%",
  },

  ...commonStyles(theme),
}));

function scrollTo(element) {
  const startingY = window.pageYOffset;
  const elementY = element.getBoundingClientRect().top + startingY - 75;
  const diff = elementY - startingY;
  let start;

  const duration = 500;
  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    const percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}

const descriptions = {
  合规工具:
    "用户可以使用DoxAuto开发自动化合规工具，将法律法规和监管部门的要求和专家的经验算法化，自动对合规场景进行分析并快速提示合规风险与合规建议，从而使用户能够高效率的合规。一旦算法化后，合规系统也能随着合规要求的变化而迅速更新、持续改善。",
  HR工具箱:
    "HR工具箱是DoxAuto联合资深劳动法律师与HR专家共同开发的HR自动化工具。首批工具包括（1）从Offer Letter到离职证明的全套常用HR文档的自动生成器；（2）自动计算离职补偿金和加班工资的计算器和（3）入职和离职手续的智能SOP。HR工具箱可大大提升企业HR的工作效率，不仅能够帮助她们从繁琐的日常文档、计算工作中解放出来，自动完成高质量的HR文档，也能让HR收益于行业专家的经验，通过智能SOP，以最佳的方式完成关键HR任务，保证零差错。HR工具箱目前免费试用。",
  自动生成:
    "根据需求自动生成合同、报告等复杂文档，支持单词级别的复杂逻辑处理。与手工撰写相比，生成时间节约95%，且在质量和合规性上也远远优于手工撰写的文档。",
  自动审核:
    "基于从经验与数据中提炼而来的算法自动审核文档信息，自动完成原来只能由资深专业人士完成的合同审查、尽职调查等工作。",
  问卷与计算器:
    "通过智能问卷/清单自动采集和处理信息；支持复杂的问答交互逻辑，完美模拟面对面沟通。",
  数据分析:
    "实时自动分析沉淀的文档数据，进而收获商业洞察。不再让宝贵的数据沉睡在文档中。",
  智能SOP:
    "智能SOP一步实现工作流的流程化、标准化、数据化与自动化。员工无需再记忆、学习SOP，企业也无需再下发、培训SOP。真正做到“SOP即执行！”",
};

const order = {
  "5f798526d453581afbf50e88": 1,
  "5f5d3dfdddf87b5bc8b4950a": 3.5,
  "5f49389150cb1e0d3158c05f": 3,
  "5f3dbe58c4cc7f35e0ee7f28": 2,
  "5f5310a471f8055605334b8b": 4,
  "5f5428db71f8055605334bad": 5,
  "5f62b0ff061dac322e0fe273": 6,
  "5f5fb750ddf87b5bc8b49534": 7,
  "5f4e70b60359ef7c540dc237": 8,
  "5f5aa7b597e9233bcbb11f5b": 9,
  "5f55143271f8055605334bb6": 10,
  "5f63013b061dac322e0fe27c": 11,
  "5f630b1f061dac322e0fe280": 12,
  "5f630aa0061dac322e0fe27f": 13,
  "5f81ad2741bae8724ed4d0a0": 14,
  "5f81238341bae8724ed4d096": 15,
  "5f815cd141bae8724ed4d09e": 16,
  "5f8443ce41bae8724ed4d0bf": 17,
  "5f8ed0b3d34b5f00edbed264": 18,
  "5fbf80406caeaa8de4c0786f": 19, //NDA reviwer
  "5fc1a8830af5e719841d844c": 20,
  //for HR tool kit ordering
  "6086233b541f313c74a5e7d2": 100, //HR工具箱 - 员工手册生成器
  "6089584f541f313c74a5e86b": 101, //HR工具箱 - 录用通知书生成器
  "5f5428db71f8055605334bad": 102, //HR工具箱 - 劳动合同生成器
  "6086c151541f313c74a5e7ea": 103, //HR工具箱 - 员工保密、不招揽、知识产权、竞业限制等协议生成器
  "60875edf541f313c74a5e814": 104, //HR工具箱 - 协商解除劳动合同协议生成器
  "60877448541f313c74a5e822": 105, //HR工具箱 - 解除或终止劳动合同通知书生成器(并附解除劳动合同工会通知函)
  "6088c421541f313c74a5e854": 106, //HR工具箱 - 启动/不启动竞业限制通知书生成器
  "60069a09213238198b7f3cb7": 107, //HR工具箱 - 离职证明生成器
  "609cd2cd541f313c74a5e8de": 108, //HR工具箱 - 加班工资计算器
  "608a803e541f313c74a5e89a": 109, //HR工具箱 - 离职补偿计算器
  "609cf6f0541f313c74a5e8ee": 110, //HR工具箱 - 员工入职手续SOP
  "609a00c6541f313c74a5e8bb": 111, //HR工具箱 - 员工主动离职（辞职）手续SOP
  "609f41ce541f313c74a5e8ef": 112, //HR工具箱 - 员工被动离职（辞退）手续SOP

};

export default ({ children }) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const refs = Categories.map((c) => useRef(null));
  const [groupedTemplates, setGroupedTemplates] = useState([]);
  let location = useLocation();
  let history = useHistory();
  const user = useSelector(selectUser);
  const isCreator = useSelector(selectIsCreator);
  const classes = useStyles();

  useEffect(() => {
    if (groupedTemplates.length == 0) {
      return;
    }
    if (location.hash) {
      const section = location.hash.substr(1);
      const category = Categories.findIndex((c) => c.hash === section);
      if (category < 0) {
        return;
      }
      scrollTo(refs[category].current);
      history.push("/");
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const { data: contractTemplates } = await fetchTemplates();
        const groupedTemplates = groupBy(
          //sortBy(contractTemplates.members, (t) => order[t.id] || 0),
          sortBy(contractTemplates.members, (t) => order[t.id] || 1000),
          (t) => {
            if (isCreator && t.createdBy && t.createdBy === user.id) {
              return "自定义模板";
            }
            return t.category;
          }
        );
        const { data: auditTemplates } = await fetchAuditTemplates();
        groupedTemplates["自动审核"] = sortBy(
          auditTemplates.members,
          //(t) => order[t.id] || 0
          (t) => order[t.id] || 1000
        );
        setGroupedTemplates(groupedTemplates);
      } catch (e) {
      } finally {
      }
    })();
  }, []);

  const ifNotFirstTitle = (idx) => {
    return idx !== 0;
  };

  return (
    <div className={classes.mainPage}>
      {/*<Container maxWidth="lg" className={classes.homePic}>*/}
      {/*  <div className={classes.center}>*/}
      {/*    <Typography variant="h2" className={classes.title}>*/}
      {/*      人人都能自动化*/}
      {/*    </Typography>*/}
      {/*    <Typography variant="h4" className={classes.subTitle}>*/}
      {/*      无需写代码的文档自动化平台*/}
      {/*    </Typography>*/}
      {/*  </div>*/}
      {/*</Container>*/}
      <div>
        <Container maxWidth="lg">
          <Grid container className={classes.picContainer}>
            <Grid item md={6} className={classes.leftPic}>
              <div className={classes.rightContent}>
                <Typography
                  variant="h3"
                  className={classes.title}
                  style={{ color: theme.fontColor.grey1 }}
                >
                  人人都能自动化
                </Typography>
                <Typography
                  variant="h5"
                  className={classes.subTitle}
                  style={{ color: theme.fontColor.grey2 }}
                >
                  无需写代码的文档自动化平台
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} className={classes.rightPic}>
              <img src={HomePic} className={classes.img} />
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container maxWidth="lg" className={classes.demo}>
        <Grid container>
          <Grid item md={2}></Grid>
          <Grid item md={10}>
            <Typography variant="h6">
              <span className={classes.highlight}>
                只要您会用WORD写文档，您就会用DoxAuto创建文档自动化工具。
              </span>
            </Typography>
            <Typography variant="h6">
              DoxAuto是一个高效、安全与易用的文档自动化平台。使用DoxAuto平台，用户无需写任何代码或IT
              支持就能够独立自主地创建各种文档自动化工具，包括文档的自动生成、自动执行、自动审核和数据分析。
              DoxAuto可广泛应用于任何在业务中需要处理文档的行业。以下是一些我们的用户创建的文档自动化工具。
              我们诚邀您
              <RouterLink
                to="/manageTemplates"
                style={{ color: theme.fontColor.grey3 }}
              >
                免费试用
              </RouterLink>
              DoxAuto来自动化您的文档。
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" className={classes.contentContainer}>
        <Grid container spacing="2" justify="space-evenly">
          {isPhone ? null : (
            <Grid item md={2} className={classes.menuContainer}>
              <List className={classes.menu}>
                {Categories.map((category, idx) => {
                  return (
                    <React.Fragment key={category.varName}>
                      <ListItem
                        alignItems="flex-start"
                        className={classes.menuItem}
                        onClick={() => {
                          history.push(`/#${category.hash}`);
                        }}
                      >
                        <ListItemText primary={category.title} />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}
                <ListItem
                  alignItems="flex-start"
                  className={classes.menuItem}
                  onClick={() => {
                    history.push("/aboutService");
                  }}
                >
                  <Typography style={{ fontSize: "1rem" }}>
                    了解{" "}
                    <span
                      style={{
                        fontSize: "1.2rem",
                        textDecoration: "underline",
                      }}
                    >
                      我们的服务
                    </span>
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          )}
          <Grid item md={10} xs={12}>
            {Categories.map((category, cIdx) => {
              return (
                <div
                  className={classes.categoryContainer}
                  key={category.varName}
                  ref={refs[cIdx]}
                >
                  {ifNotFirstTitle(cIdx) ? <Divider /> : null}
                  <Toolbar disableGutters>
                    <Typography
                      variant="h5"
                      component="p"
                      className={classes.titleText}
                    >
                      {category.title}
                    </Typography>
                  </Toolbar>
                  <Typography
                    variant="subtitle1"
                    className={classes.subText}
                    component="p"
                  >
                    {descriptions[category.title]}
                  </Typography>
                  <Grid
                    container
                    spacing={1}
                    justify={isPhone ? "center" : "flex-start"}
                  >
                    {(groupedTemplates[category.category] || []).map(
                      (t, tIdx) => {
                        return (
                          <Grid item key={t.id}>
                            <AppStoreStyleCard
                              ratio={tIdx === 0 ? 5 : 4.6}
                              title={t.title}
                              tid={t.id}
                              desc={t.description}
                              author={t.author}
                              to={category.link(t.id)}
                              newPage={true}
                            />
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Container>

      <footer className={classes.footer}>
        <Grid
          container
          justify="center"
          className={classes.footerLogoContainer}
        >
          <Typography
            variant="h1"
            className={classes.footerLogo}
            component={RouterLink}
            to="/"
          >
            <img src={Logo} />
          </Typography>
        </Grid>

        <div className="row">
                <Grid container justify="center">
                    <a href="http://beian.miit.gov.cn" className={classes.footerNavItemLink}>
                      2020-2021 Doxauto.com版权所有 京ICP备19058980号
                    </a>
                  </Grid>
        </div>

        {/*<div className="row">*/}
        {/*  <Grid container justify="center">*/}
        {/*    <ul className={classes.footerNavList}>*/}
        {/*      <li className={classes.footerNavItem}>*/}
        {/*        <a href="#" className={classes.footerNavItemLink}>*/}
        {/*          Company*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li className={classes.footerNavItem}>*/}
        {/*        <a href="#" className={classes.footerNavItemLink}>*/}
        {/*          Contact us*/}
        {/*        </a>*/}
        {/*      </li>*/}

        {/*      <li className={classes.footerNavItem}>*/}
        {/*        <a href="#" className={classes.footerNavItemLink}>*/}
        {/*          Privacy policy*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </Grid>*/}
        {/*</div>*/}
      </footer>
    </div>
  );
};
