import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Toolbar, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { login, selectIsAuthenticated, selectUser } from "./userSlice";
import { useHistory } from "react-router";
import Snackbar from "layout/Components/SnackBar";
import { error, selectErrorMessage } from "layout/layoutSlice";
import Paper from "@material-ui/core/Paper";

import background from "assets/sign-background.jpg";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${background}) no-repeat center center fixed`,
    backgroundSize: "cover",
    // backgroundPosition: "top",
  },
  container: {
    margin: "0 auto",
    width: 504,
    boxSizing: "content-box",
  },
  loginContainer: {
    maxWidth: 400,
    padding: theme.spacing(4),
  },
  registerContainer: {
    maxWidth: 400,
    padding: theme.spacing(4),
  },
  loginTitle: {
    display: "flex",
    justifyContent: "center",
    color: theme.fontColor.grey2,
  },
  loginButton: {
    marginTop: theme.spacing(4),
  },
  formContainer: {},
  logo: {},
}));

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const errors = useSelector(selectErrorMessage);
  const [loginParam, setLoginParam] = useState({ username: "", password: "" });
  const hasError = (errors || []).length > 0;
  const history = useHistory();
  useEffect(() => {
    if (isAuthenticated) {
      if (!user.needUpdatePassword) {
        history.push("/");
      } else {
        history.push("/updatePassword");
      }
    }
  }, [isAuthenticated]);

  const setValue = (event) => {
    const idx = event.target.name;
    const value = event.target.value || "";
    setLoginParam({ ...loginParam, [idx]: value });
  };

  return (
    <div className={classes.root}>
      <Snackbar />
      <Grid
        container
        direction={"column"}
        className={classes.container}
        spacing={5}
      >
        <Grid item xs={12}>
          <Paper className={classes.loginContainer}>
            <Toolbar className={classes.loginTitle}>
              <Typography variant="h4">DoxAuto 登录</Typography>
            </Toolbar>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12}>
                <TextField
                  name="username"
                  onChange={setValue}
                  label="用户名"
                  variant="standard"
                  fullWidth
                  autoFocus
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <AccountCircleIcon />
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  onChange={setValue}
                  label="密码"
                  type="password"
                  variant="standard"
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <VpnKeyIcon />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={classes.loginButton}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    // let privateKey = window.localStorage.getItem("privateKey");
                    // if (!privateKey) {
                    //   privateKey = uuidv4();
                    //   window.localStorage.setItem("privateKey", privateKey);
                    // }
                    if (
                      loginParam.username === "" ||
                      loginParam.password === ""
                    ) {
                      dispatch(error("用户或者密码不能为空"));
                      return;
                    }
                    dispatch(login(loginParam));
                  }}
                  disableElevation
                >
                  登录
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.registerContainer}>
            <Toolbar className={classes.loginTitle}>
              <Typography variant="h5">没有 DoxAuto 帐户？</Typography>
            </Toolbar>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12} className={classes.loginButton}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  disableElevation
                  component={RouterLink}
                  to={"/sign-up"}
                >
                  创建账号
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
