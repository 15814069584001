import { BOOL, RADIO, TABLE } from "common/commonConstants/QuestionCardType";
import update from "immutability-helper";
import { default as Tran } from "common/commonConstants/QuestionTypeFormat";
import map from "lodash/map";

const USE_UNDEFINED_VARIABLE = "USE_UNDEFINED_VARIABLE";
const USE_VAR_BEFORE_EVAlUATION = "USE_VAR_BEFORE_EVAlUATION";
const RUNTIME_ERROR = "RUNTIME_ERROR";

function isCustomizedError(error) {
  return (
    error &&
    (error.code === USE_UNDEFINED_VARIABLE ||
      error.code === USE_VAR_BEFORE_EVAlUATION)
  );
}

function runTimeError(program, e, allValue) {
  const input = map(allValue, (val, key) => `${key}: ${val}`).join(",");
  return {
    code: RUNTIME_ERROR,
    message: `程序${program}运行时异常, 错误信息为:${e}。 可用的值为： ${input}。请确保程序无运行时异常。`,
  };
}

function undefinedVariable(program, variable) {
  return {
    code: USE_UNDEFINED_VARIABLE,
    message: `在公式${program}中使用了变量${variable}，请确保${variable}已定义，并且程序在问题中使用，或者所属变量定义在${variable}之后。`,
  };
}

function varBeforeEvaluatedIt(program, variable) {
  return {
    code: USE_VAR_BEFORE_EVAlUATION,
    message: `在公式${program}中使用了变量${variable},此时${variable}还未计算。`,
  };
}

function isEmpty(input) {
  return input === null || input === undefined || input === "";
}

export function debug(contract) {
  const answers = {};
  const all = {};
  const problems = [];
  map(contract.questions || {}, (value, key) => {
    all[key] = value.formatted;
    answers[key] = value.formatted;
    if (isEmpty(value.formatted) && isEmpty(value.input)) {
      problems.push(`问题${key}的答案为空`);
    }
    if (isEmpty(value.formatted) && !isEmpty(value.input)) {
      problems.push(
        `问题${key}的回答不为空，但是处理结果为空，可能是UI计算有问题`
      );
    }
  });
  const vars = {};
  map(contract.variables || {}, (value, key) => {
    all[key] = value.formatted;
    vars[key] = value.formatted;
    if (isEmpty(value.formatted) && isEmpty(value.input)) {
      problems.push(`中间变量${key}的答案为空`);
    }
    if (isEmpty(value.formatted) && !isEmpty(value.input)) {
      problems.push(
        `中间变量${key}的回答不为空，但是处理结果为空，可能是UI计算有问题`
      );
    }
  });
  return map(all, (value, key) => `${key}: ${value}`);
}

function shouldSkipQuestion(template, question, formItems) {
  if (!question.enabled) {
    return true;
  }
  // 不需要显示，跳过
  if (question.dependencyExpression && question.dependencyExpression.code) {
    const shouldShow = evaluateExpressionWithError(
      template,
      formItems,
      question.dependencyExpression
    ).$result;
    if (!shouldShow) {
      return true;
    }
  }
  return false;
}

export const evaluateExpressionWithError = (
  template,
  allVars,
  program,
  input,
  inputForm,
  forbiddenVars
) => {
  return evaluateExpression(
    template,
    false,
    allVars,
    program,
    input,
    inputForm,
    forbiddenVars
  );
};

const evaluateExpression = (
  template,
  silently,
  fItems,
  code,
  input,
  inputForm,
  forbiddenVars
) => {
  const program = code.code ? code.code : code;
  const answerAndVariable = { ...fItems, $input: { ...inputForm, input } };
  const answers = Object.create(answerAndVariable);
  // TODO: 可以使用代理，没有对应的值时，抛出异常
  const data = new Proxy(answers, {
    get: (target, propName, receiver) => {
      // 当某一个变量delay了
      if ((forbiddenVars || []).findIndex((name) => name === propName) > -1) {
        throw varBeforeEvaluatedIt(code.text || program, propName);
      }
      const form = target[propName];
      if (form === undefined) {
        const idx = (template.variables || []).findIndex(
          (variable) => variable.variableName === propName
        );
        if (idx > -1) {
          throw varBeforeEvaluatedIt(code.text || program, propName);
        }

        // TODO: silently的情况下，就不判断变量是否定义直接跑错了。
        throw undefinedVariable(code.text || program, propName);
      }
      let value = form.translator.inputToJs(form.input);
      return value;
    },
  });
  try {
    eval(program);
  } catch (e) {
    if (isCustomizedError(e)) {
      throw e;
    } else if (e instanceof SyntaxError || e instanceof ReferenceError) {
      throw {
        code: "SYNTAX_ERROR",
        message: `程序${
          code.text || program
        }语法错误，错误信息： ${e.toString()}`,
      };
    } else {
      const err = runTimeError(program, e, answerAndVariable);
      console.log(err.message);
      answers.$result = undefined;
    }
  }
  return answers;
};

export const setDefaultValue = (template, formItems) => {
  const questionTemplates = template.questions;
  let newFormItems = formItems;
  const delayCalcs = [];
  // 第一次计算所有的问题的默认值，然后将需要引用中间变量的问题延迟计算。
  (questionTemplates || []).forEach((question) => {
    // 有默认值，并且没有被编辑过。那么就是用默认值。
    const answer = formItems[question.var];
    if (answer.edited) {
      return;
    }
    if (!answer.template.enabled) {
      return;
    }
    if (
      question.defaultValueExpression &&
      question.defaultValueExpression.code
    ) {
      // 如果存在默认值公式，通过公式计算默认值
      let value;
      try {
        value = evaluateExpressionWithError(
          template,
          newFormItems,
          question.defaultValueExpression,
          undefined,
          undefined,
          delayCalcs.map((q) => q.var)
        ).$result;
        // 计算结果为js的类型，转换为input需要的类型。
        value = answer.translator.jsToInput(value);
      } catch (e) {
        if (e && e.code === USE_VAR_BEFORE_EVAlUATION) {
          delayCalcs.push(question);
        } else {
          throw e;
        }
      }
      // 将结果更新到对象上，这一步在这里做，是因为后面的答案可能依赖于当前这个问题的答案
      newFormItems = update(newFormItems, {
        [question.var]: {
          input: {
            $set: value,
          },
        },
      });
    } else if (
      (question.type === BOOL || question.type === RADIO) &&
      "defaultValue" in question
    ) {
      // 否则，判断是否有默认值，如果有默认值，则使用默认值，默认值保留的是options中的index。
      // TODO: update
      let value = question.options[question.defaultValue];
      if (value && value.value) {
        value = value.value;
      }
      newFormItems = update(newFormItems, {
        [question.var]: {
          input: {
            $set: answer.translator.jsToInput(value),
          },
        },
      });
    }
  });

  let allVars = calcAllVarsFromAnswers(template, newFormItems);
  // 更新剩余的问题
  delayCalcs.forEach((question) => {
    const answer = formItems[question.var];
    let value = evaluateExpressionWithError(
      template,
      allVars,
      question.defaultValueExpression
    ).$result;
    // 计算结果为js的类型，转换为input需要的类型。
    value = answer.translator.jsToInput(value);
    // 将结果更新到对象上，这一步在这里做，是因为后面的答案可能依赖于当前这个问题的答案
    newFormItems = update(newFormItems, {
      [question.var]: {
        input: {
          $set: value,
        },
      },
    });
    allVars = update(allVars, {
      [question.var]: {
        input: {
          $set: value,
        },
      },
    });
  });

  return [newFormItems, allVars];
};

export const validateFormItemsWith = (
  template,
  allVars,
  errors,
  validateFunc
) => {
  (template.questions || []).forEach((question, questionIndex) => {
    const formItem = allVars[question.var];
    // 未启用，跳过
    if (shouldSkipQuestion(template, question, allVars)) {
      return;
    }
    const result = validateFunc(formItem, questionIndex, template, allVars);
    if (result) {
      errors[questionIndex] = result;
    }
  });
  return errors;
};

function isBlank(formItem) {
  if (
    formItem.input === "" ||
    formItem.input == undefined ||
    formItem.input === null ||
    (typeof formItem.input === "number" && isNaN(formItem.input))
  ) {
    return "请回答问题";
  }
}

function validateNotBlank(formItem, questionIdx, template, formItems) {
  if (isBlank(formItem)) {
    return;
  }
  const question = formItem.template;
  if (question.validationExpression && question.validationExpression.code) {
    try {
      const answer = evaluateExpressionWithError(
        template,
        formItems,
        question.validationExpression,
        formItem.translator.inputToJs(formItem.input),
        formItem
      );
      if (answer.$error) {
        return answer.$error;
      }
    } catch (e) {
      if (e && e.code === USE_VAR_BEFORE_EVAlUATION) {
        return e.message;
      }
    }
  } else if (question.type === TABLE) {
    // 如果问题类型是TABLE的话, 这里是不是也要格式化一下类型这么处理一下。
    const rows = formItem.input;
    const columnNames = question.columns;
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < columnNames.length; j++) {
        if (!row[columnNames[j].name]) {
          return "请回答问题, 表格不能有未填字段";
        }
      }
    }
  }
}

export const validateIsBlankFormItems = (template, allVars, errors) => {
  return validateFormItemsWith(template, allVars, errors, isBlank);
};

export const validateNotBlankFormItems = (template, allVars, errors) => {
  return validateFormItemsWith(template, allVars, errors, validateNotBlank);
};

export const validateFormItems = (template, allVars) => {
  const errors = [];
  validateIsBlankFormItems(template, allVars, errors);
  validateNotBlankFormItems(template, allVars, errors);
  return errors;
};

const extractVariables = (template, variables) => {
  const result = {};
  (template.variables || []).forEach((variable) => {
    const translator = variables[variable.variableName].translator;
    const input = variables[variable.variableName].input;
    result[variable.variableName] = {
      input: translator.inputToJson(input),
      formatted: translator.formatInput(input),
    };
  });
  return result;
};

const calcAllVarsFromAnswers = (template, formItems) => {
  const variables = { ...formItems };
  (template.variables || []).forEach((variable) => {
    const translator = Tran[variable.format];
    const data = evaluateExpressionWithError(
      template,
      variables,
      variable.expression
    );
    variables[variable.variableName] = {
      input: translator.jsToInput(data.$result),
      translator: translator,
    };
  });
  return variables;
};

export const calcAllVariables = (template, formItems) => {
  const variables = calcAllVarsFromAnswers(template, formItems);
  return extractVariables(template, variables);
};

export const extractAnswers = (template, allVars) => {
  const answers = {};
  const edited = {};
  (template.questions || []).forEach((question) => {
    // 未启用，跳过
    if (shouldSkipQuestion(template, question, allVars)) {
      return;
    }
    const translator = allVars[question.var].translator;
    const input = allVars[question.var].input;
    answers[question.var] = {
      edited: allVars[question.var].edited,
      input: translator.inputToJson(input),
      formatted: translator.formatInput(input),
    };
    edited[question.var] = allVars[question.var].edited;
  });
  return [answers, edited];
};

export const calcRisks = (template, allVars) => {
  const risks = {};
  (template.risks || []).forEach((risk) => {
    const hasRisk = evaluateExpressionWithError(
      template,
      allVars,
      risk.hasRiskExpression
    ).$result;
    let extent, ratio, level, light;
    if (hasRisk) {
      extent = evaluateExpressionWithError(
        template,
        allVars,
        risk.extentExpression
      ).$result;
      ratio = evaluateExpressionWithError(
        template,
        allVars,
        risk.ratioExpression
      ).$result;

      if (!risk.levelExpression || !risk.levelExpression.code) {
        const defaultLevel = [
          [1, 3, 6],
          [2, 5, 8],
          [4, 7, 9],
        ];
        const toIdx = { LOW: 0, MEDIUM: 1, HIGH: 2 };
        level = defaultLevel[toIdx[ratio]][toIdx[extent]];
      } else {
        level = evaluateExpressionWithError(
          template,
          allVars,
          risk.levelExpression.code
        ).$result;
      }
      if (!risk.lightExpression || !risk.lightExpression.code) {
        if (level <= 1) {
          light = "GREEN";
        } else if (level <= 5) {
          light = "YELLOW";
        } else {
          light = "RED";
        }
      } else {
        light = evaluateExpressionWithError(
          template,
          allVars,
          risk.lightExpression.code
        ).$result;
      }
    }

    risks[risk.key] = {
      key: risk.key,
      risk: !!hasRisk,
      extent,
      ratio,
      level,
      light,
    };
  });

  return risks;
};
