import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import ToolTip from "@material-ui/core/ToolTip";
import Typography from "@material-ui/core/Typography";

import ContractImg from "assets/home/calc1.jpg";
import AddImg from "../../assets/forms-add.png";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    height: 280,
    margin: "auto",
  },
  rootAdd: {
    maxWidth: 345,
    height: 280,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    color: theme.palette.grey[500],
  },
  tooltip: {
    backgroundColor: theme.palette.grey[500],
  },
}));

export default (props) => {
  const classes = useStyles();
  const { title, description, to, update } = props;
  if (title === undefined) {
    return (
      <Card className={classes.rootAdd}>
        <CardActionArea
          className={classes.rootAdd}
          component={RouterLink}
          to={to}
        >
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            image={AddImg}
            title="Contemplative Reptile"
          />
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <CardActionArea component={RouterLink} to={to} style={{ height: "100%" }}>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={ContractImg}
          title="Contemplative Reptile"
        />
        <CardContent style={{ height: "calc(100% - 140)" }}>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <ToolTip
            title={description}
            placement="right"
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          >
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </ToolTip>
        </CardContent>
      </CardActionArea>
      {/*<CardActions style={{ flexDirection: "row-reverse" }}>*/}
      {/*  <Button size="small" color="primary" onClick={async () => {}}>*/}
      {/*    删除*/}
      {/*  </Button>*/}
      {/*  <Button size="small" color="primary" component={RouterLink} to={update}>*/}
      {/*    修改*/}
      {/*  </Button>*/}
      {/*</CardActions>*/}
    </Card>
  );
};
