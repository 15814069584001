import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Categories from "common/commonConstants/TemplateCategories";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Grid, Toolbar, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import Logo from "assets/logo.png";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";
import { faLayerGroup, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgb(27, 36, 48)",
    height: "100%",
    color: theme.fontColor.grey5,
    width: 250,
    position: "relative",
  },
  logo: {
    marginLeft: 50,
    width: 100,
    color: theme.palette.common.black,
  },
  menuIcon: {
    color: theme.fontColor.grey6,
  },
  toolbar: {
    height: theme.spacing(9),
    backgroundColor: "rgb(35, 47, 62)",
    color: theme.fontColor.grey4,
  },
  user: {
    overflow: "hidden",
    height: theme.spacing(9),
    backgroundColor: "rgb(35, 47, 62)",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[300]),
    backgroundColor: deepOrange[300],
  },
  superScript: {
    "& > span": {
      opacity: 0.8,
      backgroundColor: "orange",
    },
  },
}));

export default ({ onMenuSelected, user }) => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <img src={Logo} className={classes.logo} />
      </Toolbar>
      <List className={classes.menu}>
        {Categories.map((category) => (
          <React.Fragment key={category.varName}>
            <ListItem
              alignItems="flex-start"
              className={classes.menuItem}
              onClick={() => {
                history.push(`/#${category.hash}`);
                onMenuSelected();
              }}
            >
              <ListItemIcon className={classes.menuIcon}>
                {category.icon}
              </ListItemIcon>
              <ListItemText primary={category.title} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        <ListItem
          alignItems="flex-start"
          className={classes.menuItem}
          onClick={() => {
            history.push("/manageTemplates");
            onMenuSelected();
          }}
        >
          <Typography
            style={{ fontSize: "1.2rem", textDecoration: "underline" }}
          >
            <Badge
              badgeContent="免费试用"
              color="primary"
              className={classes.superScript}
            >
              我的DoxAuto
            </Badge>
          </Typography>
        </ListItem>
        <ListItem
          alignItems="flex-start"
          className={classes.menuItem}
          onClick={() => {
            history.push("/faq");
            onMenuSelected();
          }}
        >
          <Typography
            style={{ fontSize: "1.2rem", textDecoration: "underline" }}
          >
            使用指南
          </Typography>
        </ListItem>
                <ListItem
                  alignItems="flex-start"
                  className={classes.menuItem}
                  onClick={() => {
                    history.push("/info");
                    onMenuSelected();
                  }}
                >
                  <Typography
                    style={{ fontSize: "1.2rem", textDecoration: "underline" }}
                  >
                    资讯与活动
                  </Typography>
                </ListItem>
        <ListItem
          alignItems="flex-start"
          className={classes.menuItem}
          onClick={() => {
            history.push("/aboutService");
            onMenuSelected();
          }}
        >
          <Typography style={{ fontSize: "1rem" }}>
            了解{" "}
            <span
              style={{
                fontSize: "1.2rem",
                textDecoration: "underline",
              }}
            >
              我们的服务
            </span>
          </Typography>
        </ListItem>
      </List>
      {user && user.username ? (
        <div className={classes.user}>
          <Grid container spacing={2}>
            <Grid
              item
              alignItems="center"
              style={{ display: "flex", marginLeft: 30 }}
            >
              <Avatar className={classes.orange}>
                <FontAwesomeIcon icon={faUserTie} />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography>{user && user.nickname}</Typography>
              <Typography variant="caption">Early Access</Typography>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};
