import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import update from "immutability-helper";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const useStyles = makeStyles((theme) => ({
  test: {
    fontSize: "larger",
    "&::before": {
      borderBottom: 0,
    },
  },
}));

export default ({ active, columns, onChange }) => {
  const classes = useStyles();

  const onColumnsChange = (newColumns) => {
    onChange({
      target: {
        name: "columns",
        value: newColumns,
      },
    });
  };

  const addColumn = active ? (
    <Grid item xs={12} container key="__addColumn">
      <Grid
        item
        xs={8}
        onClick={() => {
          const newColumns = update(columns, {
            $push: [
              {
                name: `column ${columns.length + 1}`,
                type: "TEXT",
              },
            ],
          });
          onColumnsChange(newColumns);
        }}
      >
        <TextField
          disabled
          placeholder="添加表格的列"
          className={classes.test}
        />
      </Grid>
    </Grid>
  ) : null;

  return (
    <Grid container spacing={3} item xs={12}>
      {[
        ...columns.map((col, colIndex, columns) => {
          return (
            <Grid item xs={12} container spacing={3} key={colIndex}>
              <Grid item xs={6}>
                <TextField
                  value={col.name}
                  disabled={!active}
                  onChange={(event) => {
                    const newColumns = update(columns, {
                      [colIndex]: {
                        name: {
                          $set: event.target.value,
                        },
                      },
                    });
                    onColumnsChange(newColumns);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={col.type}
                  disabled
                  onChange={(event) => {
                    const newColumns = update(columns, {
                      [colIndex]: {
                        type: {
                          $set: event.target.value,
                        },
                      },
                    });
                    onColumnsChange(newColumns);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                {active ? (
                  <React.Fragment>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        if (colIndex === 0) {
                          return;
                        }
                        const newColumns = update(columns, {
                          $splice: [
                            [colIndex, 1],
                            [colIndex - 1, 0, col],
                          ],
                        });
                        onColumnsChange(newColumns);
                      }}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        if (colIndex === columns.length - 1) {
                          return;
                        }
                        const newColumns = update(columns, {
                          $splice: [
                            [colIndex, 1],
                            [colIndex + 1, 0, col],
                          ],
                        });
                        onColumnsChange(newColumns);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        const newColumns = update(columns, {
                          columns: {
                            $splice: [[colIndex, 1]],
                          },
                        });
                        onColumnsChange(newColumns);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
          );
        }),
        addColumn,
      ]}
    </Grid>
  );
};
