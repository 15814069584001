import React, { useRef, useState } from "react";
import update from "immutability-helper";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

import TextFieldWithCodeMirror from "components/TextFieldWithCodeMirror";

const useStyles = makeStyles((theme) => ({
  test: {
    fontSize: "larger",
    "&::before": {
      borderBottom: 0,
    },
  },
}));

export default ({ active, field, onChange }) => {
  const classes = useStyles();

  const onValueChange = (event) => {
    const propName = event.target.name;
    const newField = update(field, {
      [propName]: {
        $set: event.target.value,
      },
    });
    onChange(newField, field);
  };

  const onCheckedChange = (event) => {
    const propName = event.target.name;
    const newField = update(field, {
      [propName]: {
        $set: event.target.checked,
      },
    });
    onChange(newField, field);
  };

  return (
    <Grid container spacing={3} item xs={12}>
      <Grid item xs={12}>
        <TextFieldWithCodeMirror
          current={field}
          value={field.defaultValueExpression}
          name="defaultValueExpression"
          onChange={onValueChange}
          disabled={!active}
          label="默认值公式"
          placeholder="默认值公式"
        />
      </Grid>
    </Grid>
  );
};
