import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import Layout from "layout/Layout";
import Login from "pages/Login";
import Register from "pages/Register";
import { useLocation } from "react-router";

const theme = createMuiTheme({
  palette: {
    black: {
      dark: "black",
      main: "black",
      light: "black",
      contrastText: "white",
    },
  },
  fontColor: {
    grey1: "#202124",
    grey2: "#3c4043",
    grey3: "#5f6368",
    grey4: "#d4d0cec9",
    grey5: "rgb(238, 238, 238)",
    grey6: "rgb(238, 238, 238, .7)",

    blue1: "#1a73e8",
  },
});

function App() {
  let location = useLocation();

  useEffect(() => {
    // TODO: 满足奇葩需求
    document.title = "DoxAuto - 自动化你的文档";
    switch (location.pathname) {
      case "/faq":
        if (location.hash === "#title-video") {
          document.title = "DoxAuto - 教学视频";
        } else {
          document.title = "DoxAuto - 使用指南";
        }
        break;
      case "/manageTemplates":
        document.title = "DoxAuto - 创建自动化工具";
        break;
      case "/aboutService":
        document.title = "DoxAuto - 关于我们";
        break;
    }
  }, [location]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/sign-up">
              <Register />
            </Route>
            <Route path="/">
              <Layout />
            </Route>
          </Switch>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
