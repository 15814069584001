import React, { useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import TextFieldWithBlockly from "../TextFieldWithBlockly";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import translator, {
  FORMAT_DATE_CH,
  FORMAT_BOOL,
  FORMAT_DATE_COMMON,
  FORMAT_DATE_COMMON2,
  FORMAT_DATE_EN,
  FORMAT_NUMBER,
  FORMAT_NUMBER_CH_LOWERCASE,
  FORMAT_NUMBER_CH_UPPERCASE,
  FORMAT_NUMBER_CURRENCY_RMB,
  FORMAT_NUMBER_CURRENCY_RMB_W,
  FORMAT_NUMBER_CURRENCY_US,
  FORMAT_NUMBER_EN,
  FORMAT_NUMBER_FLOAT_FIXED_2,
  FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP,
  FORMAT_NUMBER_INT,
  FORMAT_NUMBER_INT_WITH_SEP,
  FORMAT_NUMBER_PERCENTAGE,
  FORMAT_TEXT,
} from "common/commonConstants/QuestionTypeFormat";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import TextFieldWithCodeMirror, {
  CodeMirrorInput,
} from "components/TextFieldWithCodeMirror";
import CodeMirrorEditor from "components/CodeMirrorEditor";
import { updateCode } from "../TextFieldWithCodeMirror";
import AddIcon from "@material-ui/icons/Add";

const supportFormats = [
  translator[FORMAT_TEXT],
  translator[FORMAT_NUMBER],
  translator[FORMAT_BOOL],
  translator[FORMAT_NUMBER_INT],
  translator[FORMAT_NUMBER_INT_WITH_SEP],
  translator[FORMAT_NUMBER_FLOAT_FIXED_2],
  translator[FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP],
  translator[FORMAT_NUMBER_PERCENTAGE],
  translator[FORMAT_NUMBER_EN],
  translator[FORMAT_NUMBER_CH_LOWERCASE],
  translator[FORMAT_NUMBER_CH_UPPERCASE],
  translator[FORMAT_NUMBER_CURRENCY_US],
  translator[FORMAT_NUMBER_CURRENCY_RMB],
  translator[FORMAT_NUMBER_CURRENCY_RMB_W],
  translator[FORMAT_DATE_COMMON],
  translator[FORMAT_DATE_COMMON2],
  translator[FORMAT_DATE_CH],
  translator[FORMAT_DATE_EN],
];

export default React.memo(
  ({
    active,
    variable,
    variableIndex,
    onVariableChange,
    onVariablePositionChange,
    addVar,
    showDeleteButton,
  }) => {
    const simpleWorkspace = useRef(null);
    const [isEditorOpen, setEditorOpen] = useState(false);
    const syncCode = () => {
      if (!simpleWorkspace.current) {
        console.warn("cannot read code");
        return;
      }
      const text = simpleWorkspace.current.inputCode;
      const code = updateCode(text);
      onVariableChange({
        target: {
          value: { code, text, severity: window.__monday_code_errors },
          name: "expression",
          getAttribute: () => variableIndex, // 返回x-index
        },
      });
    };
    const toggleEditor = () => {
      if (isEditorOpen) {
        syncCode();
      }
      setEditorOpen(!isEditorOpen);
    };
    return (
      <Grid container style={{ paddingTop: 10 }}>
        <Grid item xs={12} container spacing={3} key={variableIndex}>
          <Grid item xs={3}>
            <TextField
              multiline
              disabled={!active}
              placeholder="变量名"
              fullWidth
              value={variable.variableName}
              name="variableName"
              inputProps={{ "x-index": variableIndex }}
              onChange={onVariableChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={active ? 4 : 6}>
            <CodeMirrorInput
              isEditorOpen={isEditorOpen}
              toggleEditor={toggleEditor}
              current={variable}
              placeholder="公式"
              label="公式"
              name="expression"
              x-index={variableIndex}
              value={variable.expression}
              onChange={onVariableChange}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                输出格式
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="format"
                disabled={!active}
                value={variable.format}
                index={variableIndex}
                onChange={(event) => {
                  const fakeEvent = {
                    target: {
                      "x-index": variableIndex,
                      name: "format",
                      value: event.target.value,
                      getAttribute: (name) => fakeEvent.target[name],
                    },
                  };
                  onVariableChange(fakeEvent);
                }}
                label="输出格式"
              >
                {supportFormats.map((tran) => {
                  return (
                    <MenuItem key={tran.value} value={tran.value}>
                      {tran.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {active ? (
              <React.Fragment>
                <IconButton
                  aria-label="add to favorites"
                  size="small"
                  onClick={() => addVar(variableIndex)}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    onVariablePositionChange("UP", variable, variableIndex);
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    onVariablePositionChange("DOWN", variable, variableIndex);
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={!showDeleteButton}
                  onClick={() => {
                    onVariablePositionChange("DELETE", variable, variableIndex);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </React.Fragment>
            ) : null}
          </Grid>
        </Grid>
        {isEditorOpen ? (
          <Grid item xs={12}>
            <CodeMirrorEditor
              ref={simpleWorkspace}
              current={variable}
              value={
                (variable.expression &&
                  (variable.expression.text || variable.expression.code)) ||
                ""
              }
              blur={syncCode}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
);
