import React, { useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import { useHistory, useParams } from "react-router-dom";
import classnames from "classnames";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { KeyboardDatePicker } from "@material-ui/pickers";

import FormControl from "@material-ui/core/FormControl";

import StyledTable from "components/StyledTable";
import ContractFormStyles from "./AuditFormStyle";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  BOOL,
  DATE,
  RADIO,
  TABLE,
  TEXT,
} from "common/commonConstants/QuestionCardType";

import {
  calcRisks,
  debug,
  evaluateExpressionWithError,
  extractAnswers,
  setDefaultValue,
  validateFormItems,
  validateNotBlankFormItems,
} from "components/SurveySection/util";
import { getAuditTemplate } from "service/auditTemplate";
import { createAudit, getAudit } from "service/audit";
import {
  boolFalseLabel,
  boolTrueLabel,
  default as Trans,
  FORMAT_TEXT,
} from "common/commonConstants/QuestionTypeFormat";
import { error } from "layout/layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdmin } from "pages/Login/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { scrollTo } from "../../common/helper";
import { useKeyPress } from "../../common/hooks";

const useStyles = makeStyles(ContractFormStyles);

export default () => {
  const dispatch = useDispatch();
  const { auditTemplateId, auditId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  // undefined表示需要loading，Array表示已经loading结束
  const [answers, setAnswers] = useState();
  const [allValidVars, setAllValidVars] = useState(); // 包含中间变量和所有回答。
  const [template, setTemplate] = useState({});
  const [audit, setAudit] = useState({
    questions: {},
    edited: {},
  });
  const [errors, setErrors] = useState([]);
  const [isTemplateLoading, setTemplateLoading] = useState(false); // TODO: loading page
  const [isSubmitting, setSubmitting] = useState(false);
  const isAdmin = useSelector(selectIsAdmin);
  let index = 1;
  //
  const focusRef = useRef();
  const [current, setCurrent] = useState(1);
  const enterPressed = useKeyPress("Tab");

  let button="";
  const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
            const hasChinese = REGEX_CHINESE.test(template.title);

            if (hasChinese)
              button ="生成";
              else
              button ="Generate";
  useEffect(() => {
    if (enterPressed) {
      scrollTo(focusRef.current, () => {
        setCurrent(current + 1);
      });
    }
  });

  useEffect(() => {
    (async () => {
      setTemplateLoading(true);
      let theAuditTemplate;
      let theAudit = audit;
      try {
        if (auditTemplateId) {
          const templateResp = await getAuditTemplate(auditTemplateId);
          theAuditTemplate = templateResp.data;
          theAudit.auditTemplateId = theAuditTemplate.id;
          theAudit.name = `${theAuditTemplate.title}`;
        } else if (auditId) {
          const auditResp = await getAudit(auditId);
          theAudit = auditResp.data;
          const templateResp = await getAuditTemplate(theAudit.auditTemplateId);
          theAuditTemplate = templateResp.data;
        }
        document.title = `DoxAuto - ${theAuditTemplate.title}`;
        // 生成FORM item
        let answerFormItems = (theAuditTemplate.questions || []).reduce(
          (pre, template) => {
            const translator = Trans[template.format || FORMAT_TEXT];
            const answer = theAudit.questions[template.var] || {};
            let input = answer.input || answer.value; // TODO: remove answer.value
            if (input != undefined && input !== "") {
              input = translator.jsonToInput(input);
            }
            const edited = !!answer.edited;
            const formItem = {
              template,
              input,
              translator,
              edited,
            };
            pre[template.var] = formItem;
            return pre;
          },
          {}
        );
        // 初始化好默认值
        const [finalAnswers, finalAllVars] = setDefaultValue(
          theAuditTemplate,
          answerFormItems
        );
        // 校验，对已输入的值进行校验
        const errorMessages = validateNotBlankFormItems(
          theAuditTemplate,
          finalAllVars,
          []
        );
        setErrors(errorMessages);
        setAnswers(finalAnswers);
        setAllValidVars(finalAllVars);
        setTemplate(theAuditTemplate);
        setAudit(theAudit);
      } catch (e) {
        console.error(e);
        dispatch(error(e && e.message ? e.message : e.toString()));
      } finally {
        setTemplateLoading(false);
      }
    })();
  }, []);

  const onChange = (question, func) => (event) => {
    const propName = question.var;
    func = func || ((event) => event.target.value);
    let value = func(event);

    const edited = true;
    const newAnswers = update(answers, {
      [propName]: {
        input: { $set: value },
        edited: { $set: edited },
      },
    });
    try {
      const [finalAnswers, finalAllVars] = setDefaultValue(
        template,
        newAnswers
      );
      setAnswers(finalAnswers);
      setAllValidVars(finalAllVars);
    } catch (e) {
      if (e && e.code && e.message) {
        dispatch(error(e.message));
      }
    }
  };

  // TODO: 考虑一下这里在value为空的情况下，是否需要重新计算一下默认值
  const onBlur = (question, questionIdx, func) => (event) => {
    func = func || ((event) => event.target.value);
    const value = func(event);
    if (
      value === undefined ||
      value === null ||
      (typeof value === "string" && value.trim() === "")
    ) {
      const newError = update(errors, {
        [questionIdx]: {
          $set: "请回答问题",
        },
      });
      setErrors(newError);
      return;
    }
    if (question.validationExpression && question.validationExpression.code) {
      let message;
      try {
        const answer = evaluateExpressionWithError(
          template,
          allValidVars,
          question.validationExpression,
          value,
          answers[question.var]
        );
        if (answer.$error) {
          message = answer.$error;
        } else {
          message = undefined;
        }
      } catch (e) {
        message = e && e.message ? e.message : e.toString();
      }
      const newError = update(errors, {
        [questionIdx]: {
          $set: message,
        },
      });
      setErrors(newError);
    } else {
      const newError = update(errors, {
        [questionIdx]: {
          $set: undefined,
        },
      });
      setErrors(newError);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.main}>
        <Grid className={classes.question}>
          <Card className={classes.templateHeader}>
            <div className={classes.questionBar}></div>
            <CardContent className={classes.questionContent}>
              <Typography variant="h4" className={classes.title}>
                {template.title}
              </Typography>
              <Typography variant="subtitle1" className={classes.titleDesc}>
                {template.description}
              </Typography>
              <Typography variant="subtitle1" className={classes.titleAuthor}>
                <FontAwesomeIcon icon={faUser} /> {template.author || "--"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {(template.questions || []).map((question, questionIdx) => {
          if (!question.enabled) {
            return null;
          }
          if (
            question.dependencyExpression &&
            question.dependencyExpression.code
          ) {
            try {
              if (
                !evaluateExpressionWithError(
                  template,
                  allValidVars,
                  question.dependencyExpression
                ).$result
              ) {
                return null;
              }
            } catch (e) {
              // 这里就不跑错了，
              return null;
            }
          }

          return (
            <Grid
              className={classes.question}
              key={question.var}
              ref={current === index ? focusRef : null}
            >
              <Card
                className={classnames({
                  [classes.templateHeader]: true,
                  [classes.unEditable]:
                    question.type === TEXT && !question.editable,
                })}
              >
                <CardContent>
                  <Grid
                    container
                    spacing={4}
                    justify="space-between"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Typography variant="h6" className={classes.theQuestion}>
                        {`${index++}. ${question.question}`}
                      </Typography>
                    </Grid>
                    {question.moduleLevel1 ? (
                      <Grid
                        item
                        style={{ whiteSpace: "nowrap", paddingRight: 32 }}
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.module}
                        >
                          <FontAwesomeIcon icon={faAngleDoubleRight} />{" "}
                          {question.moduleLevel1}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Typography variant="subtitle2">{question.tips}</Typography>
                  {(() => {
                    switch (question.type) {
                      case TABLE:
                        return (
                          <FormControl
                            component="fieldset"
                            error={!!errors[questionIdx]}
                          >
                            <StyledTable
                              rows={answers[question.var].input || [{}]}
                              name={question.var}
                              columns={question.columns}
                              onChange={onChange(question, (rows) => rows)}
                            />
                            <FormHelperText>
                              {errors[questionIdx]}
                            </FormHelperText>
                          </FormControl>
                        );
                      case RADIO:
                        return (
                          <FormControl
                            component="fieldset"
                            error={!!errors[questionIdx]}
                          >
                            <RadioGroup
                              aria-label="gender"
                              name={question.var}
                              value={answers[question.var].input || ""}
                              onChange={(event) => {
                                onChange(question)(event);
                                onBlur(
                                  question,
                                  questionIdx,
                                  (event) => event.target.value
                                )(event);
                              }}
                            >
                              {(question.options || []).map((op) => {
                                return (
                                  <FormControlLabel
                                    classes={{ label: classes.answerSize }}
                                    key={op}
                                    value={op}
                                    control={
                                      <Radio
                                        color={
                                          answers[question.var].edited
                                            ? undefined
                                            : ""
                                        }
                                      />
                                    }
                                    label={op}
                                  />
                                );
                              })}
                            </RadioGroup>
                            <FormHelperText>
                              {errors[questionIdx]}
                            </FormHelperText>
                          </FormControl>
                        );
                      case BOOL:
                        return (
                          <FormControl
                            component="fieldset"
                            error={!!errors[questionIdx]}
                          >
                            <RadioGroup
                              aria-label="gender"
                              name={question.var}
                              value={answers[question.var].input || ""}
                              onChange={(event) => {
                                onChange(question)(event);
                                onBlur(
                                  question,
                                  questionIdx,
                                  (event) => event.target.value
                                )(event);
                              }}
                            >
                              <FormControlLabel
                                value={"true"}
                                classes={{ label: classes.answerSize }}
                                control={
                                  <Radio
                                    color={
                                      answers[question.var].edited
                                        ? undefined
                                        : ""
                                    }
                                  />
                                }
                                label={boolTrueLabel(question.options[0])}
                              />
                              <FormControlLabel
                                value={"false"}
                                classes={{ label: classes.answerSize }}
                                control={
                                  <Radio
                                    color={
                                      answers[question.var].edited
                                        ? undefined
                                        : ""
                                    }
                                  />
                                }
                                label={boolFalseLabel(question.options[1])}
                              />
                            </RadioGroup>
                            <FormHelperText>
                              {errors[questionIdx]}
                            </FormHelperText>
                          </FormControl>
                        );
                      case TEXT:
                        return (
                          <TextField
                            disabled={!question.editable}
                            value={
                              answers[question.var].input == null
                                ? ""
                                : answers[question.var].input
                            }
                            placeholder={
                              question.defaultValueExpression &&
                              question.defaultValueExpression.code
                                ? evaluateExpressionWithError(
                                    template,
                                    allValidVars,
                                    question.defaultValueExpression
                                  ).$result + "" || ""
                                : ""
                            }
                            onChange={onChange(question)}
                            style={{
                              width: "50%",
                            }} // TODO: 使用 media query
                            multiline
                            onBlur={onBlur(question, questionIdx)}
                            helperText={errors[questionIdx]}
                            error={!!errors[questionIdx]}
                            inputProps={{
                              className: classnames({
                                [classes.noEdited]: !answers[question.var]
                                  .edited,
                                [classes.edited]: answers[question.var].edited,
                                [classes.disabled]: !question.editable,
                              }),
                            }}
                          />
                        );
                      case DATE:
                        return (
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format={question.format}
                            margin="normal"
                            id="date-picker-inline"
                            value={answers[question.var].input || ""}
                            onChange={onChange(question, (date) => {
                              if (date instanceof Date && isNaN(date)) {
                                return date;
                              }
                              onBlur(
                                question,
                                questionIdx,
                                (date) => date
                              )(date);
                              return date;
                            })}
                            helperText={errors[questionIdx]}
                            error={!!errors[questionIdx]}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            inputProps={{
                              className: classnames({
                                [classes.edited]: answers[question.var].edited,
                                [classes.noEdited]: !answers[question.var]
                                  .edited,
                              }),
                            }}
                          />
                        );
                    }
                  })()}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        <Grid container justify="center" spacing={5} className={classes.submit}>
          {isAdmin ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={async () => {
                  try {
                    // 修改对象的状态
                    audit.risks = calcRisks(template, allValidVars);
                    const [theAnswers, edited] = extractAnswers(
                      template,
                      allValidVars
                    );
                    audit.questions = theAnswers;
                    audit.edited = edited;

                    setSubmitting(true);
                    const { data: savedContract } = await createAudit(audit);
                    // 不使用finally，避免进行跳转之后，finally中在对unmount的Component的属性进行修改
                    const message = debug(audit);
                    dispatch(error(message, true));

                    setSubmitting(false);
                  } catch (e) {
                    dispatch(error(e && e.message ? e.message : e.toString()));
                    setSubmitting(false);
                  }
                }}
              >
                保存问卷
              </Button>
            </Grid>
          ) : null}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                try {
                  // validation:
                  const validateResults = validateFormItems(
                    template,
                    allValidVars
                  );
                  if (validateResults.length > 0) {
                    setErrors(validateResults);
                    dispatch(error("回答不符合要求"));
                    return;
                  }

                  // 修改对象的状态
                  audit.risks = calcRisks(template, allValidVars);
                  const [theAnswers, edited] = extractAnswers(
                    template,
                    allValidVars
                  );
                  audit.questions = theAnswers;
                  audit.edited = edited;
                  debug(audit);
                  setSubmitting(true);
                  const { data: savedContract } = await createAudit(audit);
                  // 不使用finally，避免进行跳转之后，finally中在对unmount的Component的属性进行修改
                  setSubmitting(false);
                  history.push(`/AuditRiskForm/update/${savedContract.id}`);
                  // openInNewTab(
                  //   `${window.location.origin}/AuditRiskForm/update/${savedContract.id}`
                  // );
                } catch (e) {
                  dispatch(error(e && e.message ? e.message : e.toString()));
                  setSubmitting(false);
                }
              }}
            >
              {button}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
