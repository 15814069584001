import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import ToolTip from "@material-ui/core/ToolTip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";

import ContractImg from "assets/97433-contractmain.webp";
import AddImg from "assets/forms-add.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { deleteTemplate } from "../service/template";
import { deleteAuditTemplate } from "../service/auditTemplate";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minHeight: 280,
    height: "100%",
    margin: "auto",
  },
  rootAdd: {
    maxWidth: 345,
    height: 280,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    color: theme.palette.grey[500],
  },
  tooltip: {
    backgroundColor: theme.palette.grey[500],
  },
}));

export const TemplateAddCard = () => {
  const classes = useStyles();
  return (
    <Card className={classes.rootAdd}>
      <CardActionArea
        className={classes.rootAdd}
        component={RouterLink}
        to="/auditTemplateForm/create"
      >
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          image={AddImg}
          title="Contemplative Reptile"
        />
      </CardActionArea>
    </Card>
  );
};

export default (props) => {
  const classes = useStyles();
  const { id, title, description, onDelete } = props;

  const [isDialogOpen, setDialogOpen] = useState(false);

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt="Contemplative Reptile"
        height="140"
        image={ContractImg}
        title="Contemplative Reptile"
      />
      <CardContent style={{ height: "calc(100% - 140)" }}>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <ToolTip
          title={description}
          placement="right"
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        >
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </ToolTip>
      </CardContent>
      <CardActions style={{ flexDirection: "row-reverse" }}>
        <Button size="small" color="primary" onClick={openDialog}>
          删除
        </Button>
        <Button
          size="small"
          color="primary"
          component={RouterLink}
          to={`/auditTemplateForm/update/${id}`}
        >
          修改
        </Button>
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            删除模板
          </DialogTitle>
          <DialogContent>
            <DialogContentText>确认删除模板{title}?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeDialog} color="primary">
              取消
            </Button>
            <Button
              onClick={async () => {
                try {
                  await deleteAuditTemplate(id);
                  onDelete();
                } catch (e) {}
                closeDialog();
              }}
              color="primary"
            >
              确认
            </Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
};
