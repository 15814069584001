import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid, Link, Toolbar, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Snackbar from "layout/Components/SnackBar";
import { error, selectErrorMessage } from "layout/layoutSlice";
import { signUp } from "service/session";
import background from "assets/sign-background.jpg";
import Paper from "@material-ui/core/Paper";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,32})/;
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const usernameValidate = (text) => {
  const match = /^[a-zA-Z0-9]{6,32}$/.test(text);
  if (!match) {
    return { username: "用户名由6-32位字母和数字组成" };
  }
  return { username: "" };
};

const confirmPasswordValidate = (confirm, password) => {
  if (confirm !== password) {
    return { confirmPassword: "两次密码输入不一致" };
  }
  return { confirmPassword: "" };
};

const passwordValidate = (password) => {
  const match = passwordRegex.test(password);
  if (!match) {
    return { password: "密码必须长8-32位，必须包含数字，大小写字母和特殊字符" };
  }
  return { password: "" };
};

const emailValidate = (email) => {
  const match = emailRegex.test(email);
  if (!match) {
    return { email: "请输入正确的email格式" };
  }
  return { email: "" };
};

const notNullValidate = (text, key) => {
  if (text == null || text.length === 0) {
    return { [key]: "不能为空" };
  }
  return { [key]: "" };
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${background})  no-repeat center center fixed`,
    backgroundSize: "cover",
    // backgroundPosition: "top",
  },
  greyTitle: {
    color: theme.fontColor.grey2,
  },
  subTitle: {
    display: "flex",
    justifyContent: "center",
    marginTop: -theme.spacing(3),
  },
  loginContainer: {
    padding: theme.spacing(4),
  },
  loginTitle: {
    display: "flex",
    justifyContent: "center",
  },
  formContainer: {},
  logo: {},
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const errors = useSelector(selectErrorMessage);
  const [errMessage, setErrMessage] = useState({});
  const [registerParam, setRegisterParam] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    nickname: "",
    company: "",
  });
  const history = useHistory();

  const setValue = (event) => {
    const idx = event.target.name;
    const value = event.target.value || "";
    setRegisterParam({ ...registerParam, [idx]: value });
  };

  const validate = () => {};

  return (
    <div className={classes.root}>
      <Snackbar />
      <Container maxWidth="md">
        <Paper className={classes.loginContainer}>
          <Toolbar className={classes.loginTitle}>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              className={classes.greyTitle}
            >
              创建您的 DoxAuto 帐户
            </Typography>
          </Toolbar>
          <Toolbar className={classes.subTitle}>
            <Typography variant="subtitle1" className={classes.greyTitle}>
              已有 DoxAuto 帐户？
              <Link component={RouterLink} to={"/login"}>
                登录
              </Link>
            </Typography>
          </Toolbar>
          <Grid container spacing={3} className={classes.formContainer}>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  用户名
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  name="email"
                  error={errMessage.email}
                  helperText={errMessage.email}
                  onChange={setValue}
                  type="email"
                  variant="standard"
                  fullWidth
                  onBlur={(event) => {
                    setErrMessage({
                      ...errMessage,
                      ...emailValidate(event.target.value),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="caption" align="left">
                  您的电子邮件地址即为您的用户名
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  密码
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  name="password"
                  onChange={setValue}
                  error={errMessage.password}
                  helperText={errMessage.password}
                  type="password"
                  variant="standard"
                  fullWidth
                  onBlur={(event) => {
                    setErrMessage({
                      ...errMessage,
                      ...passwordValidate(event.target.value),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="caption" align="left">
                  密码必须包含大小写字母、至少 1
                  个数字和一个特殊符号，并且长度必须至少为 8 且小于 32 个字符。
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  再次输入密码
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  name="confirmPassword"
                  onChange={setValue}
                  error={errMessage.confirmPassword}
                  helperText={errMessage.confirmPassword}
                  type="password"
                  variant="standard"
                  fullWidth
                  onBlur={(event) => {
                    let confirmPassword = "";
                    if (registerParam.password !== event.target.value) {
                      confirmPassword = "两次密码输入不一致";
                    }
                    setErrMessage({ ...errMessage, confirmPassword });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} />
            </Grid>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  别名
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  error={errMessage.nickname}
                  helperText={errMessage.nickname}
                  name="nickname"
                  onChange={setValue}
                  variant="standard"
                  fullWidth
                  onBlur={(event) => {
                    setErrMessage({
                      ...errMessage,
                      ...notNullValidate(event.target.value, "nickname"),
                    });
                  }}
                />{" "}
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="caption" align="left">
                  创建工具时显示的作者名称
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  公司名称
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  name="company"
                  error={errMessage.company}
                  helperText={errMessage.company}
                  onChange={setValue}
                  variant="standard"
                  fullWidth
                  onBlur={(event) => {
                    setErrMessage({
                      ...errMessage,
                      ...notNullValidate(event.target.value, "company"),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="caption" align="left"></Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                onClick={async () => {
                  const newError = {
                    ...passwordValidate(registerParam.password),
                    ...emailValidate(registerParam.email),
                    ...confirmPasswordValidate(
                      registerParam.confirmPassword,
                      registerParam.password
                    ),
                    ...notNullValidate(registerParam.company, "company"),
                    ...notNullValidate(registerParam.company, "nickname"),
                  };
                  // dispatch(login(registerParam));
                  setErrMessage(newError);
                  if (
                    Object.keys(newError)
                      .map((k) => newError[k])
                      .filter((t) => t != null && t.length > 0).length > 0
                  ) {
                  } else {
                    try {
                      await signUp({
                        ...registerParam,
                        username: registerParam.email,
                      });
                      history.push("/login");
                    } catch (e) {
                      dispatch(error(e.data.message));
                    }
                  }
                }}
              >
                创建账号
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};
