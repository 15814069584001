import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import CodeMirrorEditor from "./CodeMirrorEditor";
import {
  faExclamationCircle,
  faExclamationTriangle,
  faTimes,
  faTimesCircle,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = (theme) => ({
  sourceInput: {
    marginBottom: theme.spacing(1),
  },
});

const useStyles = makeStyles(styles);

const handleMouseDown = (event) => {
  event.preventDefault();
};

export const updateCode = (text) => {
  if (text == null || text.length === 0) {
    return text;
  }
  if (text.indexOf("data.") !== -1) {
    return text;
  }
  if (text.indexOf("$$result") === -1 && text.indexOf("$$error") === -1) {
    text = "data.$result = " + text;
  }
  text = text.replace(/\$(?!(result|error|input))/g, "data.");
  return text;
};

export const CodeMirrorInput = ({
  isEditorOpen,
  value,
  placeholder,
  label,
  toggleEditor,
}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.sourceInput}>
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <Input
        id="outlined-adornment-password"
        type="text"
        value={(value && (value.text || value.code)) || ""}
        placeholder={placeholder}
        disabled={true}
        startAdornment={
          value && value.severity ? (
            <InputAdornment position="start">
              <FontAwesomeIcon
                icon={
                  value.severity === "warning"
                    ? faExclamationTriangle
                    : faTimesCircle
                }
                style={{
                  color: value.severity === "warning" ? "#ff9800" : "red",
                }}
              />
            </InputAdornment>
          ) : null
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleEditor}
              onMouseDown={handleMouseDown}
              edge="end"
            >
              {isEditorOpen ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText>{""}</FormHelperText>
    </FormControl>
  );
};

class TextFieldWithCodeMirror extends Component {
  constructor(props) {
    super(props);
    this.simpleWorkspace = React.createRef();
    this.state = {
      isEditorOpen: false,
    };
  }

  onEditorOpen = () => {};

  onEditorClose = () => {
    if (!this.simpleWorkspace.current) {
      console.warn("cannot read code");
      return;
    }
    const text = this.simpleWorkspace.current.inputCode;
    // TODO: update code.
    const code = updateCode(text);
    this.props.onChange({
      target: {
        value: { code, text, severity: window.__monday_code_errors },
        name: this.props.name,
        getAttribute: (name) => this.props[name],
      },
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.isEditorOpen && this.state.isEditorOpen) {
      this.onEditorOpen();
    }
  }

  toggleEditor = (event) => {
    if (this.state.isEditorOpen) {
      this.onEditorClose();
    }
    this.setState({
      isEditorOpen: !this.state.isEditorOpen,
    });
  };

  render() {
    const { value, current } = this.props;
    return (
      <React.Fragment>
        <CodeMirrorInput
          {...this.props}
          isEditorOpen={this.state.isEditorOpen}
          toggleEditor={this.toggleEditor}
          handleMouseDown={handleMouseDown}
        />
        {this.state.isEditorOpen ? (
          <CodeMirrorEditor
            value={(value && (value.text || value.code)) || ""}
            ref={this.simpleWorkspace}
            current={current}
            blur={this.onEditorClose}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default TextFieldWithCodeMirror;
