import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Container, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import commonStyles from "common/commonStyles";
import EmailIcon from "@material-ui/icons/Email";
import weChat from "assets/wechat.png";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {},
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    paddingBottom: theme.spacing(2),
    color: theme.fontColor.grey2,
  },
  text: {
    color: theme.fontColor.grey3,
  },
  contactUs: {
    position: "absolute",
    top: 40,
    right: 32,
  },
  container: {
    position: "relative",
    marginTop: 0,
  },
  mainTitle: {
    color: theme.fontColor.grey2,
    fontSize: "2.5rem",
  },
  highlight: {
    color: theme.fontColor.grey2,
    fontStyle: "italic",
    fontWeight: 700,
  },
  wechat: {},
  ...commonStyles(theme),
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="lg" className={classes.main}>
      <Grid
        container
        spacing={8}
        justify="center"
        className={classes.container}
      >
        <Grid item style={{ paddingTop: 40 }}>
          <Typography variant="h5" align="center" className={classes.mainTitle}>
            我们的服务
          </Typography>
        </Grid>

        <Grid
          item
          container
          spacing={3}
          justify="center"
          direction={matches ? "column-reverse" : "row"}
        >
          <Grid container item spacing={3} md={5}>
            <Grid item className={classes.phone} xs={12}>
              <Typography
                variant="h4"
                component="p"
                style={{ paddingBottom: 10 }}
              >
                联系我们
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                style={{ paddingBottom: 0 }}
              >
                邮箱
              </Typography>
              <TextField
                size="medium"
                value="doxauto@outlook.com"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ width: 220 }}
              />
              <Typography
                component="p"
                variant="subtitle1"
                style={{ paddingTop: 10 }}
              >
                微信号
              </Typography>
              <div style={{ paddingTop: 0 }}>
                <img src={weChat} width={100} />
              </div>
            </Grid>
          </Grid>
          <Grid item container spacing={3} md={7}>
            <Grid item xs={12} md={12} className={classes.textContainer}>
              <Typography variant="subtitle1" className={classes.text}>
                DoxAuto是一个高效、安全与易用的文档自动化平台。
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className={classes.textContainer}>
              <Typography variant="h4" className={classes.title}>
                DoxAuto应用场景
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                DoxAuto的应用场景非常广泛，任何使用文档模板或清单（checklist）的工作流都可以通过DoxAuto平台快捷地自动化。
                例如，律师可以使用DoxAuto自动撰写复杂的交易文件，节约工作时间95%，并将宝贵的经验算法化。（请见
                <Link
                  target="_blank"
                  href="/media/doxauto_law_office_application.pdf"
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "underline",
                  }}
                >
                  DoxAuto律所应用场景示例
                </Link>
                ）；
                又例如，企业可以使用DoxAuto根据业务数据自动生成大量的业务合同，使文档不再成为企业数字化的瓶颈。（请见
                <Link
                  target="_blank"
                  href="/media/doxauto_enterprise_application.pdf"
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "underline",
                  }}
                >
                  DoxAuto企业应用场景示例
                </Link>
                ）。
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                DoxAuto的应用场景是以用户为中心的。由于DoxAuto的自动化过程不需要编写任何代码或IT支持，
                从而能够做到完全由用户的业务需求所驱动，我们相信我们的用户会发现越来越多的DoxAuto的应用场景。
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className={classes.textContainer}>
              <Typography variant="h4" className={classes.title}>
                自助服务
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                您可以自助使用DoxAuto平台开发文档自动化APP。DoxAuto平台非常易用，
                不需要编写任何代码。
                <span className={classes.highlight}>
                  只要您会用WORD写文档，您就会用DoxAuto创建文档自动化工具。
                </span>
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                您不仅可以开发APP自用，也可以提供给其他用户使用。
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className={classes.textContainer}>
              <Typography variant="h4" className={classes.title}>
                定制服务
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                我们也很乐意为您提供定制化服务。
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                我们的技术团队可以根据您的需要，在云端或本地部署DoxAuto平台。Doxauto平台将用户数据安全、保护用户数据隐私置于核心地位,全方面提供数据安全以及隐私保护，每个客户的数据单独存储并加密,用户和平台之间提供多因子认证及端对端的加密等多种安全保障。对于对安全有特殊高要求的客户我们也提供的本地部署服务从而使数据完全存于客户本地
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                除了技术服务以外，我们的精益流程专家和行业专家也能够帮助您利用DoxAuto平台改造传统的文档工作，使之流程化、标准化、数据化与自动化，并将您的经验和合规要求固化为可持续改善的模型与算法。
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className={classes.textContainer}>
              <Typography variant="h4" className={classes.title}>
                数据安全
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                DoxAuto采用行业最佳实践保障数据安全。
                我们对每个客户的数据单独存放，提供双因子认证及端对端交互加密，从物理层面与其他用户数据和操作隔离。我们的数据安全系统具备强大的安全审计功能和完备的数据备份及灾难恢复功能。对于数据安全有特殊要求的客户，我们可以提供符合客户要求的数据保护机制，例如客户化的本地部署。
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
