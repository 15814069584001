import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { useHistory, useParams } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ContractFormStyles from "./AuditRiskFormStyle";
import { getAuditTemplate } from "service/auditTemplate";
import { createAudit, getAudit } from "service/audit";
import Avatar from "@material-ui/core/Avatar";

import { default as KeyValue } from "./Components/KeyValItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

function level(lab) {
  return {
    HIGH: "高",
    MEDIUM: "中",
    LOW: "低",
  }[lab];
}

const useStyles = makeStyles(ContractFormStyles);

export default () => {
  const { auditId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [template, setTemplate] = useState({});
  const [audit, setAudit] = useState({
    questions: {},
    edited: {},
  });
  const [riskList, setRiskList] = useState([]);
  const [isTemplateLoading, setTemplateLoading] = useState(false); // TODO: loading page
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setTemplateLoading(true);
        const { data: theAudit } = await getAudit(auditId);
        const { data: theTemplate } = await getAuditTemplate(
          theAudit.auditTemplateId
        );
        setTemplate(theTemplate);
        setAudit(theAudit);
        // 合并分类信息到risk上
        const riskInstance = theAudit.risks;
        const list = (theTemplate.risks || [])
          .filter((risk) => risk.enabled && riskInstance[risk.key].risk)
          .map((risk) => {
            const r = { ...riskInstance[risk.key] };
            r.description = risk.description;
            r.advice = risk.advice;
            r.moduleLevel1 = risk.moduleLevel1;
            r.moduleLevel2 = risk.moduleLevel2;
            return r;
          })
          .sort((a, b) => b.level - a.level);
        setRiskList(list);
        // 排序一下risk
      } catch (e) {
      } finally {
        setTemplateLoading(false);
      }
    })();
  }, []);

  const onCommentChange = (event) => {
    const idx = event.target.name;
    let value = event.target.value;

    let newRiskList = update(riskList, {
      [idx]: {
        comment: {
          $set: value,
        },
      },
    });
    setRiskList(newRiskList);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.main}>
        <Grid className={classes.question}>
          <Card className={classes.templateHeader}>
            <div className={classes.questionBar}></div>
            <CardContent className={classes.questionContent}>
              <Typography variant="h4" className={classes.title}>
                {template.title && template.title + "- 风险提示"}
              </Typography>
              <Typography variant="subtitle1" className={classes.titleDate}>
                {new Date().toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {(riskList || []).map((risk, riskIdx) => {
          return (
            <Grid className={classes.question} key={risk.key}>
              <Card className={classes.templateHeader}>
                <CardContent>
                  <Grid container spacing={3} direction="column">
                    <Grid item container>
                      <Grid item xs={3}>
                        <Avatar className={classes[risk.light]}>
                          {riskIdx + 1}
                        </Avatar>
                      </Grid>
                      <Grid item xs={3}>
                        <KeyValue label="程度" value={level(risk.extent)} />
                      </Grid>
                      <Grid item xs={3}>
                        <KeyValue label="概率" value={level(risk.ratio)} />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ whiteSpace: "nowrap", paddingRight: 32 }}
                      >
                        <Typography
                          variant="subtitle1"
                          align="right"
                          className={classes.module}
                        >
                          <FontAwesomeIcon icon={faAngleDoubleRight} />{" "}
                          {risk.moduleLevel1}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item>
                        <KeyValue
                          label="描述"
                          value={risk.description}
                          highLightValue
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item xs={12}>
                        <KeyValue label="修改建议" value={risk.advice} />
                      </Grid>
                    </Grid>
                    {/*<Grid item container>*/}
                    {/*  <Grid item xs={12}>*/}
                    {/*    <TextField*/}
                    {/*      fullWidth*/}
                    {/*      variant="outlined"*/}
                    {/*      name={riskIdx + ""}*/}
                    {/*      label="备注"*/}
                    {/*      placeholder="备注"*/}
                    {/*      value={risk.comment || ""}*/}
                    {/*      onChange={onCommentChange}*/}
                    {/*      inputProps={{*/}
                    {/*        className: classes.edited,*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </Grid>*/}
                    {/*</Grid>*/}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        <Grid container justify="center" spacing={5} className={classes.submit}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                riskList.forEach((risk) => {
                  const origin = audit.risks[risk.key];
                  audit.risks[risk.key] = {
                    ...origin,
                    comment: risk.comment,
                  };
                });
                try {
                  setSubmitting(true);
                  const { data: savedAudit } = await createAudit(audit);
                  // 不使用finally，避免进行跳转之后，finally中在对unmount的Component的属性进行修改
                  setSubmitting(false);
                  window.close();
                } catch (e) {
                  setSubmitting(false);
                }
              }}
            >
              关闭
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
