import React, { useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import { format } from "date-fns";
import map from "lodash/map";

import Fab from "@material-ui/core/Fab";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { KeyboardDatePicker } from "@material-ui/pickers";

import FormControl from "@material-ui/core/FormControl";

import StyledTable from "components/StyledTable";
import ContractFormStyles from "./ContractFormStyles";
import { getTemplate } from "service/template";
import { createContract, getContract, updateContract } from "service/contract";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  BOOL,
  CHECK_BOX,
  DATE,
  RADIO,
  TABLE,
  TEXT,
} from "common/commonConstants/QuestionCardType";

import {
  calcAllVariables,
  debug,
  evaluateExpressionWithError,
  extractAnswers,
  setDefaultValue,
  validateFormItems,
  validateNotBlankFormItems,
} from "components/SurveySection/util";
import { useDispatch, useSelector } from "react-redux";
import { error } from "layout/layoutSlice";
import { downloadURI, scrollTo } from "common/helper";

import {
  boolFalseLabel,
  boolTrueLabel,
  default as Trans,
  FORMAT_TEXT,
  toString,
} from "common/commonConstants/QuestionTypeFormat";
import { selectIsAdmin, selectUser } from "pages/Login/userSlice";
import { useHistory } from "react-router";
import {
  faAngleDoubleRight,
  faCodeBranch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKeyPress } from "../../common/hooks";
import { useMediaQuery } from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import Drawer from "@material-ui/core/Drawer";

import sop1 from "assets/sop01.png";
import sop2 from "assets/sop02.png";
import sop3 from "assets/sop03.png";
import ReactMarkdown from "react-markdown";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

const sopPics = {
  "5f8443ce41bae8724ed4d0bf": sop1,
  "5f81ad2741bae8724ed4d0a0": sop2,
  "5f815cd141bae8724ed4d09e": sop3,
};

const useStyles = makeStyles(ContractFormStyles);

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [flowOpen, setFlowOpen] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { templateId, contractId } = useParams();
  const classes = useStyles();
  // undefined表示需要loading，Array表示已经loading结束
  const [answers, setAnswers] = useState();
  const [allValidVars, setAllValidVars] = useState(); // 包含中间变量和所有回答。
  const [template, setTemplate] = useState({});
  const [contract, setContract] = useState({
    questions: {},
    edited: {},
  });
  const [errors, setErrors] = useState([]);
  const [isTemplateLoading, setTemplateLoading] = useState(false); // TODO: loading page
  const [isSubmitting, setSubmitting] = useState(false);
  const isAdmin = useSelector(selectIsAdmin);
  const user = useSelector(selectUser);

  let index = 1;
  let button = "";
  //
  const focusRef = useRef();
  const [current, setCurrent] = useState(1);
  const enterPressed = useKeyPress("Tab");
  //check chinese/english
           const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
          const hasChinese = REGEX_CHINESE.test(template.title);

          if (hasChinese)
            button ="生成";
            else
            button ="Generate";
          // 生成FORM item
  useEffect(() => {
    if (enterPressed) {
      scrollTo(focusRef.current, () => {
        setCurrent(current + 1);
      });
    }
  });

  useEffect(() => {
    setTemplateLoading(true);
    (async () => {
      let theTemplate;
      let theContract = contract;
      try {
        if (templateId) {
          const response = await getTemplate(templateId);
          theTemplate = response.data;
          theContract.name = `${theTemplate.title}`;
          theContract.templateId = theTemplate.id;
        } else if (contractId) {
          const contractResponse = await getContract(contractId);
          theContract = contractResponse.data;
          const templateResp = await getTemplate(theContract.templateId);
          theTemplate = templateResp.data;
        }
        document.title = `DoxAuto - ${theTemplate.title}`;

        let answerFormItems = (theTemplate.questions || []).reduce(
          (pre, template) => {
            // TODO: 这里提取一个方法，对于不同的input，有自定义的转换器。当前特殊的是CHECK BOX
            const getTrans = (q) => {
              const translator = Trans[q.format || FORMAT_TEXT];
              if (q.type !== CHECK_BOX) {
                return translator;
              }
              return {
                label: translator.label,
                value: translator.value,
                inputToJs: (input) => map(input, translator.inputToJs),
                jsToInput: (js) => map(js, translator.jsToInput),
                inputToJson: (input) =>
                  JSON.stringify(map(input, translator.inputToJson)),
                jsonToInput: (json) =>
                  map(JSON.parse(json), translator.jsonToInput),
                formatInput: (input) => map(input, translator.formatInput),
              };
            };
            const translator = getTrans(template);
            const answer = theContract.questions[template.var] || {};
            let input = answer.input;
            if (input !== undefined && input !== "") {
              input = translator.jsonToInput(input);
            }
            const edited = !!answer.edited;
            const formItem = {
              template,
              input,
              translator,
              edited,
            };
            pre[template.var] = formItem;
            return pre;
          },
          {}
        );
        // 初始化好默认值
        const [finalAnswers, finalAllVars] = setDefaultValue(
          theTemplate,
          answerFormItems
        );
        // 校验，对已输入的值进行校验
        const errorMessages = validateNotBlankFormItems(
          theTemplate,
          finalAllVars,
          []
        );
        setErrors(errorMessages);
        setAnswers(finalAnswers);
        setAllValidVars(finalAllVars);
        setTemplate(theTemplate);
        setContract(theContract);
      } catch (e) {
        // TODO http 和自定义的不统一，后续同意
        const message = e.message || (e.data && e.data.message);
        dispatch(error(message));
      } finally {
        setTemplateLoading(false);
      }
    })();
  }, []);

  const onChange = (question, func) => (event) => {
    const propName = question.var;
    func = func || ((event) => event.target.value);
    let value = func(event, (answers[propName] || {}).input || []);

    let newAnswers = update(answers, {
      [propName]: {
        input: { $set: value },
        edited: { $set: true },
      },
    });
    try {
      const [finalAnswers, finalAllVars] = setDefaultValue(
        template,
        newAnswers
      );
      setAnswers(finalAnswers);
      setAllValidVars(finalAllVars);
    } catch (e) {
      if (e && e.code && e.message) {
        dispatch(error(e.message));
      }
    }
  };

  const onBlur = (question, questionIdx, func) => (event) => {
    func = func || ((event) => event.target.value);
    const value = func(event, (answers[question.var] || {}).input || []);
    if (
      value === undefined ||
      value === null ||
      (typeof value === "string" && value.trim() === "")
    ) {
      const newError = update(errors, {
        [questionIdx]: {
          $set: "请回答问题",
        },
      });
      setErrors(newError);
      return;
    }
    if (question.validationExpression && question.validationExpression.code) {
      let message;
      try {
        const answer = evaluateExpressionWithError(
          template,
          allValidVars,
          question.validationExpression,
          value,
          answers[question.var]
        );
        if (answer.$error) {
          message = answer.$error;
        } else {
          message = undefined;
        }
      } catch (e) {
        message = e && e.message ? e.message : e.toString();
      }
      const newError = update(errors, {
        [questionIdx]: {
          $set: message,
        },
      });
      setErrors(newError);
    } else {
      const newError = update(errors, {
        [questionIdx]: {
          $set: undefined,
        },
      });
      setErrors(newError);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.main}>
        <Grid className={classes.question}>
          <Card className={classes.templateHeader}>
            <div className={classes.questionBar}></div>
            <CardContent className={classes.questionContent}>
              <Typography variant="h4">
                <TextField
                  fullWidth
                  multiline
                  margin="normal"
                  placeholder="模板标题"
                  name="title"
                  value={contract.name || ""}
                  InputProps={{
                    className: classes.mainTitle,
                  }}
                  onChange={(event) => {
                    const newContract = update(contract, {
                      name: {
                        $set: event.target.value,
                      },
                    });
                    setContract(newContract);
                  }}
                />
              </Typography>
              <Typography variant="subtitle1" className={classes.titleDesc}>
                {template.description}
              </Typography>
              <Typography variant="subtitle1" className={classes.titleAuthor}>
                <FontAwesomeIcon icon={faUser} /> {template.author || "--"}
              </Typography>
            </CardContent>
            <CardContent className={classes.questionContent}>
<Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.container}
        >
             <Grid justify="center" item>
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || !template || !template.docTemplatePath}
                                onClick={async () => {
                                  // validation:
                                  try {
                                    const validateResults = validateFormItems(
                                      template,
                                      allValidVars
                                    );
                                    if (validateResults.length > 0) {
                                      setErrors(validateResults);
                                      dispatch(error("回答不符合要求"));
                                      return;
                                    }
                                    // 改动contract对象。
                                    contract.variables = calcAllVariables(template, answers);
                                    const [theAnswers, edited] = extractAnswers(
                                      template,
                                      allValidVars
                                    );
                                    contract.questions = theAnswers;
                                    contract.edited = edited;
                                    debug(contract);
                                    setSubmitting(true);
                                    const { data: savedContract } = await createContract({
                                      ...contract,
                                      id: undefined,
                                      shown: false,
                                    });
                                    // 不使用finally，避免进行跳转之后，finally中在对unmount的Component的属性进行修改
                                    if (template.category === "数据分析") {
                                      setSubmitting(false);
                                      // openInNewTab(
                                      //   `${window.location.origin}/statistic/${savedContract.id}`
                                      // );
                                      history.push(`/statistic/${savedContract.id}`);
                                      return;
                                    }

                                    downloadURI(
                                      `/download-contract/${savedContract.id}`,
                                      `${template.title}${format(
                                        new Date(),
                                        "yyyy_MM_dd-HH_mm_ss"
                                      )}`
                                    );

                                    // const preview = `${window.location.origin}/preview/${savedContract.id}?name=${template.title}`;
                                    // openInNewTab(preview);
                                    setSubmitting(false);
                                    history.push(
                                      `/preview/${savedContract.id}?name=${template.title}`
                                    );
                                  } catch (e) {
                                    // TODO http 和自定义的不统一，后续同意
                                    const message = e.message || (e.data && e.data.message);
                                    dispatch(error(message));
                                    setSubmitting(false);
                                  }
                                }}
                              >
                                {button}
                              </Button>
                            </Grid>
                                    </Grid>

                                        </CardContent>
        </Card>
        </Grid>

        {(template.questions || []).map((question, questionIdx) => {
          if (!question.enabled) {
            return null;
          }
          if (
            question.dependencyExpression &&
            question.dependencyExpression.code
          ) {
            try {
              if (
                !evaluateExpressionWithError(
                  template,
                  allValidVars,
                  question.dependencyExpression
                ).$result
              ) {
                return null;
              }
            } catch (e) {
              return null;
            }
          }

          return (
            <Grid
              className={classes.question}
              key={question.var}
              ref={current === index ? focusRef : null}
              onClick={((idx) => () => {
                setCurrent(idx);
              })(index)}
            >
              <Card
                className={classnames({
                  [classes.templateHeader]: true,
                  [classes.unEditable]: !question.editable,
                })}
              >
                <CardContent>
                  <Grid
                    container
                    spacing={4}
                    justify="space-between"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Typography variant="h6" className={classes.theQuestion}>
                        {`${index++}. ${question.question}`}
                      </Typography>
                    </Grid>
                    {question.moduleLevel1 ? (
                      <Grid
                        item
                        style={{ whiteSpace: "nowrap", paddingRight: 32 }}
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.module}
                        >
                          <FontAwesomeIcon icon={faAngleDoubleRight} />{" "}
                          {question.moduleLevel1}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Typography
                    variant="subtitle2"
                    className={classes.theQuestionHit}
                  >
                    <ReactMarkdown source={question.tips} linkTarget="_blank" />
                  </Typography>
                  {(() => {
                    switch (question.type) {
                      case TABLE:
                        return (
                          <FormControl
                            component="fieldset"
                            error={!!errors[questionIdx]}
                          >
                            <StyledTable
                              name={question.var}
                              rows={answers[question.var].input || [{}]}
                              columns={question.columns}
                              onChange={onChange(question, (rows) => rows)}
                            />
                            <FormHelperText>
                              {errors[questionIdx]}
                            </FormHelperText>
                          </FormControl>
                        );
                      case CHECK_BOX:
                        return (
                          <FormControl
                            component="fieldset"
                            error={!!errors[questionIdx]}
                          >
                            <FormGroup>
                              {(question.options || []).map((op, opIdx) => {
                                return (
                                  <FormControlLabel
                                    classes={{ label: classes.answerSize }}
                                    key={op}
                                    value={op}
                                    control={
                                      <Checkbox
                                        disabled={!question.editable}
                                        name={op}
                                        checked={(
                                          answers[question.var].input || []
                                        ).find((v) => v === `opt${opIdx + 1}`)}
                                        onChange={(event) => {
                                          const getVal = (event, preAnswer) => {
                                            const optValue = `opt${opIdx + 1}`;
                                            const p = (
                                              preAnswer || []
                                            ).findIndex(
                                              (val) => optValue === val
                                            );
                                            if (event.target.checked) {
                                              if (p < 0) {
                                                return [...preAnswer, optValue];
                                              } else {
                                                return preAnswer;
                                              }
                                            } else {
                                              if (p < 0) {
                                                return preAnswer;
                                              } else {
                                                const result = [...preAnswer];
                                                result.splice(p, 1);
                                                return result;
                                              }
                                            }
                                            return {
                                              ...preAnswer,
                                              [event.target.name]:
                                                event.target.checked,
                                            };
                                          };
                                          onChange(question, getVal)(event);
                                          onBlur(
                                            question,
                                            questionIdx,
                                            getVal
                                          )(event);
                                        }}
                                        color={
                                          answers[question.var].edited
                                            ? undefined
                                            : ""
                                        }
                                      />
                                    }
                                    label={op}
                                  />
                                );
                              })}
                            </FormGroup>
                            <FormHelperText>
                              {errors[questionIdx]}
                            </FormHelperText>
                          </FormControl>
                        );
                      case RADIO:
                        return (
                          <FormControl
                            component="fieldset"
                            error={!!errors[questionIdx]}
                          >
                            <RadioGroup
                              aria-label="gender"
                              name={question.var}
                              value={answers[question.var].input + "" || ""}
                              onChange={(event) => {
                                onChange(question)(event);
                                onBlur(
                                  question,
                                  questionIdx,
                                  (event) => event.target.value
                                )(event);
                              }}
                            >
                              {(question.options || []).map((op) => {
                                return (
                                  <FormControlLabel
                                    classes={{ label: classes.answerSize }}
                                    key={op}
                                    value={op}
                                    control={
                                      <Radio
                                        disabled={!question.editable}
                                        color={
                                          answers[question.var].edited
                                            ? undefined
                                            : ""
                                        }
                                      />
                                    }
                                    label={op}
                                  />
                                );
                              })}
                            </RadioGroup>
                            <FormHelperText>
                              {errors[questionIdx]}
                            </FormHelperText>
                          </FormControl>
                        );
                      case BOOL:
                        return (
                          <FormControl
                            component="fieldset"
                            error={!!errors[questionIdx]}
                          >
                            <RadioGroup
                              aria-label="gender"
                              name={question.var}
                              value={
                                answers[question.var].input == null
                                  ? ""
                                  : answers[question.var].input
                              }
                              onChange={(event) => {
                                onChange(question)(event);
                                onBlur(
                                  question,
                                  questionIdx,
                                  (event) => event.target.value
                                )(event);
                              }}
                            >
                              <FormControlLabel
                                value={"true"}
                                classes={{ label: classes.answerSize }}
                                control={
                                  <Radio
                                    disabled={!question.editable}
                                    color={
                                      answers[question.var].edited
                                        ? undefined
                                        : ""
                                    }
                                  />
                                }
                                label={boolTrueLabel(question.options[0])}
                              />
                              <FormControlLabel
                                value={"false"}
                                classes={{ label: classes.answerSize }}
                                control={
                                  <Radio
                                    disabled={!question.editable}
                                    color={
                                      answers[question.var].edited
                                        ? undefined
                                        : ""
                                    }
                                  />
                                }
                                label={boolFalseLabel(question.options[1])}
                              />
                            </RadioGroup>
                            <FormHelperText>
                              {errors[questionIdx]}
                            </FormHelperText>
                          </FormControl>
                        );
                      case TEXT:
                        return question.editable ? (
                          <TextField
                            disabled={!question.editable}
                            name={question.var}
                            value={
                              answers[question.var].input == null
                                ? ""
                                : answers[question.var].input
                            }
                            placeholder={
                              question.defaultValueExpression &&
                              question.defaultValueExpression.code
                                ? toString(
                                    evaluateExpressionWithError(
                                      template,
                                      allValidVars,
                                      question.defaultValueExpression
                                    ).$result
                                  )
                                : ""
                            }
                            onChange={onChange(question)}
                            style={{
                              width: isMobile ? "100%" : "50%",
                              marginTop: 8,
                            }} // TODO: 使用 media query
                            onBlur={onBlur(question, questionIdx)}
                            helperText={errors[questionIdx]}
                            error={!!errors[questionIdx]}
                            multiline={!question.editable}
                            inputProps={{
                              className: classnames({
                                [classes.noEdited]: !answers[question.var]
                                  .edited,
                                [classes.edited]: answers[question.var].edited,
                                [classes.disabled]: !question.editable,
                              }),
                            }}
                          />
                        ) : (
                          <React.Fragment>
                            <Typography
                              variant="subtitle1"
                              className={classes.disabledText}
                            >
                              <ReactMarkdown
                                source={answers[question.var].input}
                                linkTarget="_blank"
                              />
                            </Typography>
                            <Divider
                              style={{
                                margin: "-16px 0 0 0",
                                width: isMobile ? "100%" : "50%",
                              }}
                            />
                          </React.Fragment>
                        );
                      case DATE:
                        return (
                          <KeyboardDatePicker
                            disableToolbar
                            disabled={!question.editable}
                            variant="inline"
                            format={question.format}
                            margin="normal"
                            id="date-picker-inline"
                            name={question.var}
                            value={answers[question.var].input || ""}
                            onChange={onChange(question, (date) => {
                              if (date instanceof Date && isNaN(date)) {
                                return date;
                              }
                              onBlur(
                                question,
                                questionIdx,
                                (date) => date
                              )(date);
                              return date;
                            })}
                            helperText={errors[questionIdx]}
                            error={!!errors[questionIdx]}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            inputProps={{
                              className: classnames({
                                [classes.edited]: answers[question.var].edited,
                                [classes.noEdited]: !answers[question.var]
                                  .edited,
                              }),
                            }}
                          />
                        );
                    }
                  })()}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        <Grid container justify="center" spacing={5} className={classes.submit}>
          {user.username ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={async () => {
                  // 改动contract对象。
                  try {
                    contract.variables = calcAllVariables(template, answers);
                    const [theAnswers, edited] = extractAnswers(
                      template,
                      allValidVars
                    );
                    contract.questions = theAnswers;
                    contract.edited = edited;
                    contract.shown = true;

                    setSubmitting(true);
                    if (contract.id) {
                      const { data: savedContract } = await updateContract(
                        contract
                      );
                    } else {
                      const { data: savedContract } = await createContract(
                        contract
                      );
                    }
                    // 不使用finally，避免进行跳转之后，finally中在对unmount的Component的属性进行修改
                    if (isAdmin) {
                      const message = debug(contract);
                      dispatch(error(message, true));
                      console.log(contract);
                    }
                    setSubmitting(false);
                  } catch (e) {
                    // TODO http 和自定义的不统一，后续同意
                    const message = e.message || (e.data && e.data.message);
                    dispatch(error(message));
                    setSubmitting(false);
                  }
                }}
              >
                保存回答
              </Button>
            </Grid>
          ) : null}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting || !template || !template.docTemplatePath}
              onClick={async () => {
                // validation:
                try {
                  const validateResults = validateFormItems(
                    template,
                    allValidVars
                  );
                  if (validateResults.length > 0) {
                    setErrors(validateResults);
                    dispatch(error("回答不符合要求"));
                    return;
                  }
                  // 改动contract对象。
                  contract.variables = calcAllVariables(template, answers);
                  const [theAnswers, edited] = extractAnswers(
                    template,
                    allValidVars
                  );
                  contract.questions = theAnswers;
                  contract.edited = edited;
                  debug(contract);
                  setSubmitting(true);
                  const { data: savedContract } = await createContract({
                    ...contract,
                    id: undefined,
                    shown: false,
                  });
                  // 不使用finally，避免进行跳转之后，finally中在对unmount的Component的属性进行修改
                  if (template.category === "数据分析") {
                    setSubmitting(false);
                    // openInNewTab(
                    //   `${window.location.origin}/statistic/${savedContract.id}`
                    // );
                    history.push(`/statistic/${savedContract.id}`);
                    return;
                  }

                  downloadURI(
                    `/download-contract/${savedContract.id}`,
                    `${template.title}${format(
                      new Date(),
                      "yyyy_MM_dd-HH_mm_ss"
                    )}`
                  );

                  // const preview = `${window.location.origin}/preview/${savedContract.id}?name=${template.title}`;
                  // openInNewTab(preview);
                  setSubmitting(false);
                  history.push(
                    `/preview/${savedContract.id}?name=${template.title}`
                  );
                } catch (e) {
                  // TODO http 和自定义的不统一，后续同意
                  const message = e.message || (e.data && e.data.message);
                  dispatch(error(message));
                  setSubmitting(false);
                }
              }}
            >
              {button}
            </Button>
          </Grid>
        </Grid>
      </Container>

      {template && template.category === "智能SOP" ? (
        <React.Fragment>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.flowButton}
            onClick={() => {
              setFlowOpen(true);
            }}
          >
            <FontAwesomeIcon
              icon={faCodeBranch}
              className={classes.flowButtonIcon}
            />
          </Fab>
          <Drawer
            anchor="right"
            open={flowOpen}
            onClose={() => setFlowOpen(false)}
          >
            <img
              src={sopPics[template.id] || sop1}
              className={classes.flowDrawer}
            />
          </Drawer>
        </React.Fragment>
      ) : null}
    </div>
  );
};
