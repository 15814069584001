import Paper from "@material-ui/core/Paper";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toolkit: {
    position: "absolute",
    transition: "top .5s ease",
    right: theme.spacing(5) * -1,
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  flipImage: {
    transform: "scaleX(-1)",
  },
}));

export default React.forwardRef(({ children }, ref) => {
  const classes = useStyles();

  return (
    <Paper className={classes.toolkit} ref={ref}>
      {children}
    </Paper>
  );
});
