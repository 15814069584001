/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define custom blocks.
 * @author samelh@google.com (Sam El-Husseini)
 */

// More on defining blocks:
// https://developers.google.com/blockly/guides/create-custom-blocks/define-blocks

import * as Blockly from "blockly/core";

// Since we're using json to initialize the field, we'll need to import it.

const getQuestionVarField = {
  type: "get_question_var",
  message0: "问题 %1 的答案",
  args0: [
    {
      type: "input_dummy",
      name: "FIELD",
    },
  ],
  output: null,
  extensions: ["dynamic_menu_extension"],
};

Blockly.Extensions.register("dynamic_menu_extension", function () {
  this.getInput("FIELD").appendField(
    new Blockly.FieldDropdown(function () {
      return (
        [{ question: "无", var: "__undefined" }, ...window.__questions] || [
          { question: "无", var: "__undefined" },
        ]
      )
        .filter((q) => !!q.question && !!q.var)
        .map((q) => [q.question, q.var]);
    }),
    "VAR_NAME"
  );
});

const getMidVarField = {
  type: "get_mid_var",
  message0: "中间变量 %1 的值",
  args0: [
    {
      type: "input_dummy",
      name: "FIELD",
    },
  ],
  output: null,
  extensions: ["dynamic_mid__var_extension"],
};

Blockly.Extensions.register("dynamic_mid__var_extension", function () {
  this.getInput("FIELD").appendField(
    new Blockly.FieldDropdown(function () {
      return (
        [{ variableName: "无", var: "__undefined" }, ...window.__midVars] || [
          { variableName: "无", var: "__undefined" },
        ]
      )
        .filter((q) => !!q.variableName)
        .map((q) => [q.variableName, q.variableName]);
    }),
    "MID_VAR_NAME"
  );
});

Blockly.Blocks["get_mid_var"] = {
  init: function () {
    this.jsonInit(getMidVarField);
    this.setStyle("variable_blocks");
  },
};

Blockly.Blocks["get_question_var"] = {
  init: function () {
    this.jsonInit(getQuestionVarField);
    this.setStyle("variable_blocks");
  },
};

const inputGet = {
  type: "get_input_var",
  message0: "被校验的输入值",
  output: null,
};

Blockly.Blocks["get_input_var"] = {
  init: function () {
    this.jsonInit(inputGet);
    this.setStyle("variable_blocks");
  },
};

const reactDateField = {
  type: "set_result_val",
  message0: "设置返回值 %1",
  args0: [
    {
      type: "input_value",
      name: "VALUE",
    },
  ],
  previousStatement: null,
  nextStatement: null,
};

Blockly.Blocks["set_result_val"] = {
  init: function () {
    this.jsonInit(reactDateField);
    this.setStyle("loop_blocks");
  },
};

const setErrorVal = {
  type: "set_error_val",
  message0: "设置错误提示信息 %1",
  args0: [
    {
      type: "input_value",
      name: "VALUE",
    },
  ],
  previousStatement: null,
  nextStatement: null,
};

Blockly.Blocks["set_error_val"] = {
  init: function () {
    this.jsonInit(setErrorVal);
    this.setStyle("loop_blocks");
  },
};
