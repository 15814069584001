import React from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import update from "immutability-helper";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import TextFieldWithCodeMirror from "../../TextFieldWithCodeMirror";

const useStyles = makeStyles((theme) => ({
  test: {
    fontSize: "larger",
    "&::before": {
      borderBottom: 0,
    },
  },

  smallGutterTop: {
    marginTop: theme.spacing(2),
  },
}));

const theme = createMuiTheme({});

export default ({
  active,
  options,
  defaultValue,
  defaultValueExpression,
  onChange,
  question,
}) => {
  const classes = useStyles();

  const onOptionsChange = (newOptions) => {
    onChange({
      target: {
        name: "options",
        value: newOptions,
      },
    });
  };

  const onDefaultValueChange = (event) => {
    if (!active) {
      return;
    }
    const idx = event.currentTarget.getAttribute("x-index") - 0;
    let newVal;
    if (defaultValue === idx) {
      newVal = undefined;
    } else {
      newVal = idx;
    }
    onChange({
      target: {
        name: "defaultValue",
        value: newVal,
      },
    });
  };

  const onDefaultValueExpressionChange = (event) => {
    if (!active) {
      return;
    }
    let newVal = event.target.value;
    onChange({
      target: {
        name: "defaultValueExpression",
        value: newVal,
      },
    });
  };

  const addOption = active ? (
    <Grid item xs={12} container key="__addOption">
      <Grid item xs={1} style={{ marginRight: theme.spacing(-3) }}>
        <CheckBoxOutlineBlankIcon />
      </Grid>
      <Grid
        item
        xs={8}
        onClick={() => {
          const newOptions = update(options, {
            $push: [`option ${options.length + 1}`],
          });
          onOptionsChange(newOptions);
        }}
      >
        <TextField disabled placeholder="添加选项" className={classes.test} />
      </Grid>
    </Grid>
  ) : null;

  return (
    <Grid container spacing={3} item xs={12}>
      {[
        ...(options || []).map((option, optionIndex, options) => {
          return (
            <Grid item xs={12} container spacing={3} key={optionIndex}>
              <Grid
                item
                xs={1}
                style={{
                  marginRight: theme.spacing(-3),
                }}
                x-index={optionIndex}
                onClick={onDefaultValueChange}
              >
                {defaultValue[option] === true ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  disabled={!active}
                  value={option}
                  onChange={(event) => {
                    const newOptions = update(options, {
                      [optionIndex]: {
                        $set: event.target.value,
                      },
                    });
                    onOptionsChange(newOptions);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                {active ? (
                  <React.Fragment>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        if (optionIndex === 0) {
                          return;
                        }
                        const newOptions = update(options, {
                          $splice: [
                            [optionIndex, 1],
                            [optionIndex - 1, 0, option],
                          ],
                        });
                        onOptionsChange(newOptions);
                      }}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        if (optionIndex === options.length - 1) {
                          return;
                        }
                        const newOptions = update(options, {
                          $splice: [
                            [optionIndex, 1],
                            [optionIndex + 1, 0, option],
                          ],
                        });
                        onOptionsChange(newOptions);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={() => {
                        const newOptions = update(options, {
                          $splice: [[optionIndex, 1]],
                        });
                        onOptionsChange(newOptions);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
          );
        }),
        addOption,
      ]}
      <Grid item xs={12}>
        {/*<TextField*/}
        {/*  name="defaultValueExpression"*/}
        {/*  fullWidth*/}
        {/*  value={field.defaultValueExpression}*/}
        {/*  label="默认值公式"*/}
        {/*  variant="filled"*/}
        {/*  onChange={onValueChange}*/}
        {/*/>*/}
        <TextFieldWithCodeMirror
          current={question}
          value={defaultValueExpression}
          name="defaultValueExpression"
          onChange={onDefaultValueExpressionChange}
          disabled={!active}
          label="默认值公式"
          placeholder="默认值公式"
        />
      </Grid>
    </Grid>
  );
};
