import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { resetError, selectErrorMessage } from "../layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

export default () => {
  const errorMessage = useSelector(selectErrorMessage);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(resetError());

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={errorMessage && errorMessage.length > 0}
      onClose={handleClose}
      key={"topCenter"}
    >
      <Alert onClose={handleClose} severity="error">
        {errorMessage.map((ms) => (
          <div>{ms}</div>
        ))}
      </Alert>
    </Snackbar>
  );
};
