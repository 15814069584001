import axios from "axios";

export function loginUser({ username, password }) {
  return axios
    .post("/api/session/login", { username, password })
    .then((resp) => {
      const { token } = resp.data;
      axios.defaults.headers.common["Authorization"] = token;
      return resp;
    });
}

export function logoutUser() {
  return axios.post("/api/session/logout").then((resp) => {
    delete axios.defaults.headers.common["Authorization"];
    return resp;
  });
}

export function getUsers() {
  return axios.get("/api/users");
}

export function updateUser(id, userParam) {
  return axios.patch(`/api/users/${id}`, userParam);
}

export function createUser(userParam) {
  return axios.post("/api/users", userParam);
}

export function updatePassword(userParam) {
  return axios.post(`/api/session/password`, userParam);
}

export function signUp(userParam) {
  return axios.post(`/api/session/sign-up`, userParam);
}
