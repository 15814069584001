import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ContractCreateCard from "pages/Main/ContractCard";
import ContractCard from "components/ContractCard";
import { fetchTemplates } from "service/template";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import { fetchContracts } from "../../service/contract";
import commonStyles from "../../common/commonStyles";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "../Login/userSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    maxWidth: 1200,
    margin: "0 auto",
  },
  templateContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  ...commonStyles(theme),
}));

export default () => {
  const classes = useStyles();
  const isAdmin = useSelector(selectIsAdmin);
  const [reloading, setReloading] = useState({});
  const [groupedTemplates, setGroupedTemplates] = useState([]);
  const [isTemplateLoading, setTemplateLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [isContractLoading, setContractLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setTemplateLoading(true);
      try {
        const { data: responseBody } = await fetchTemplates(true);
        const groupedTemplates = groupBy(responseBody.members, "category");
        setGroupedTemplates(groupedTemplates);
      } catch (e) {
      } finally {
        setTemplateLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isAdmin) {
        setContractLoading(true);
        try {
          const { data: responseBody } = await fetchContracts();
          setContracts(responseBody.members);
        } catch (e) {
        } finally {
          setContractLoading(false);
        }
      }
    })();
  }, [reloading]);

  return (
    <Grid className={classes.root}>
      {map(groupedTemplates, (templates, category) => {
        return (
          <Grid item key={category}>
            <Typography variant="h5">
              {category === "null" ? "未分类模板" : category}
            </Typography>
            <Grid container spacing={5} className={classes.templateContainer}>
              {map(templates, (template, templateIndex) => {
                return (
                  <Grid item xs={3} key={template.id}>
                    <ContractCreateCard
                      title={template.title}
                      description={template.description}
                      to={`/contractForm/create/${template.id}`}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
      {isAdmin ? (
        <Grid item key="__savedContract" className={classes.largeGutterTop}>
          <Typography variant="h5">已保存的回答</Typography>
          <Grid container spacing={5} className={classes.templateContainer}>
            {map(contracts, (contract, contractIdx) => {
              return (
                <Grid item xs={3} key={contract.id}>
                  <ContractCard
                    id={contract.id}
                    title={contract.name}
                    description={`修改于${new Date(
                      contract.updateTime
                    ).toLocaleString()}`}
                    onDelete={() => {
                      setReloading({});
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
