import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import update from "immutability-helper";
import Grid from "@material-ui/core/Grid";

import QuestionCard from "components/QuestionTemplateSection/QuestionTemplateCard";
import BlocklyEditor from "../CodeMirrorEditor";
import TextFieldWithBlockly from "../TextFieldWithBlockly";

const TITLE_KEY = "title";

class QuestionTemplateSection extends Component {
  constructor(props) {
    super(props);
  }

  isActive = (key) => {
    return this.props.activityCardKey === key;
  };

  setActivityCard = (activityCardKey) => {
    this.props.onFocus(activityCardKey);
  };

  setActivityGrid = (event) => {
    const key = event.currentTarget.getAttribute("x-key");
    if (key === this.props.activityCardKey) {
      return;
    }
    this.props.onFocus(key);
  };

  onQuestionChange = (newQuestion, originQuestion) => {
    const questionIndex = this.props.questions.findIndex(
      (question) => question === originQuestion
    );
    const newQuestions = update(this.props.questions, {
      [questionIndex]: {
        $set: newQuestion,
      },
    });
    this.props.onQuestionsChange(newQuestions, this.props.questions);
    if (
      newQuestion.moduleLevel1 === originQuestion.moduleLevel1 &&
      newQuestion.moduleLevel2 === originQuestion.moduleLevel2
    ) {
      return;
    }
    if (
      this.props.modules[newQuestion.moduleLevel1] &&
      this.props.modules[newQuestion.moduleLevel1][newQuestion.moduleLevel2]
    ) {
      return;
    }
    // const modules = this.getModules(newQuestions);
    this.props.onModuleChange(newQuestions);
  };

  onQuestionPositionChange = (type, originQuestion, event) => {
    const questionIdx = this.props.questions.findIndex(
      (question) => question === originQuestion
    );
    let newQuestions;
    switch (type) {
      case "DELETE":
        if (this.props.questions.length <= 1) {
          return;
        }
        newQuestions = update(this.props.questions, {
          $splice: [[questionIdx, 1]],
        });
        if (newQuestions.length === 0) {
          this.setActivityCard(TITLE_KEY);
        } else {
          const newActive =
            newQuestions[Math.min(newQuestions.length - 1, questionIdx)].key;
          this.setActivityCard(newActive);
        }
        this.props.onQuestionsChange(newQuestions, this.props.questions);
        event.stopPropagation(); // 防止冒泡上去触发card的onclick事件, TODO: 这部分逻辑下放比较好
        break;
      case "UP":
        if (questionIdx === 0) {
          return;
        }
        newQuestions = update(this.props.questions, {
          $splice: [
            [questionIdx, 1],
            [questionIdx - 1, 0, originQuestion],
          ],
        });
        this.props.onQuestionsChange(newQuestions, this.props.questions);
        break;
      case "DOWN":
        if (questionIdx === this.props.questions.length - 1) {
          return;
        }
        newQuestions = update(this.props.questions, {
          $splice: [
            [questionIdx, 1],
            [questionIdx + 1, 0, originQuestion],
          ],
        });
        this.props.onQuestionsChange(newQuestions, this.props.questions);
        break;
      default:
        break;
    }
  };

  render() {
    const { classes, questions, modules, activeRef, addQuestion } = this.props;

    return (
      <React.Fragment>
        {questions.map((question) => {
          return (
            <Grid
              className={classes.question}
              key={question.key}
              x-key={question.key}
              onClick={this.setActivityGrid}
              ref={this.isActive(question.key) ? activeRef : null}
            >
              <QuestionCard
                // editor={TextFieldWithBlockly}
                active={this.isActive(question.key)}
                onChange={this.onQuestionChange}
                modules={modules}
                question={question}
                onPositionChange={this.onQuestionPositionChange}
                addQuestion={addQuestion}
                showDeleteButton={questions.length > 1}
              />
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withStyles((theme) => ({
  root: {},
}))(QuestionTemplateSection);
