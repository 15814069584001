import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid, Toolbar, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import Logo from "assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { error, selectErrorMessage } from "layout/layoutSlice";
import { selectUser } from "../Login/userSlice";
import { updatePassword } from "../../service/session";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "layout/Components/SnackBar";
import background from "assets/sign-background.jpg";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";

const confirmPasswordValidate = (confirm, password) => {
  if (confirm !== password) {
    return { confirmPassword: "两次密码输入不一致" };
  }
  return { confirmPassword: "" };
};
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,32})/;
const passwordValidate = (password) => {
  const match = passwordRegex.test(password);
  if (!match) {
    return { password: "密码必须长8-32位，必须包含数字，大小写字母和特殊字符" };
  }
  return { password: "" };
};
const notNullValidate = (text, key) => {
  if (text == null || text.length === 0) {
    return { [key]: "不能为空" };
  }
  return { [key]: "" };
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${background})  no-repeat center center fixed`,
    backgroundSize: "cover",
  },
  loginContainer: {
    padding: theme.spacing(4),
  },
  greyTitle: {
    color: theme.fontColor.grey2,
  },
  loginTitle: {
    display: "flex",
    justifyContent: "center",
  },
  formContainer: {},
  logo: {},
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errMessage, setErrMessage] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector(selectUser);
  const [userParam, setUserParam] = useState({
    password: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const history = useHistory();

  const setValue = (event) => {
    const idx = event.target.name;
    const value = event.target.value || "";
    setUserParam({ ...userParam, [idx]: value });
  };

  return (
    <div className={classes.root}>
      <Snackbar />
      <Container maxWidth="md">
        <Paper className={classes.loginContainer}>
          <Toolbar className={classes.loginTitle}>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              className={classes.greyTitle}
            >
              修改您的 DoxAuto 帐户密码
            </Typography>
          </Toolbar>
          <Grid container spacing={3} className={classes.formContainer}>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  用户名
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  disabled
                  value={user.username}
                  name="username"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={5}></Grid>
            </Grid>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  新密码
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  name="password"
                  onChange={setValue}
                  error={errMessage.password}
                  helperText={errMessage.password}
                  type="password"
                  variant="standard"
                  fullWidth
                  onBlur={(event) => {
                    setErrMessage({
                      ...errMessage,
                      ...passwordValidate(event.target.value),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="caption" align="left">
                  密码必须包含大小写字母、至少 1
                  个数字和一个特殊符号，并且长度必须至少为 8 且小于 32 个字符。
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  重新键入密码
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  name="confirmPassword"
                  onChange={setValue}
                  error={errMessage.confirmPassword}
                  helperText={errMessage.confirmPassword}
                  type="password"
                  variant="standard"
                  fullWidth
                  onBlur={(event) => {
                    let confirmPassword = "";
                    if (userParam.password !== event.target.value) {
                      confirmPassword = "两次输入密码不一致";
                    }
                    setErrMessage({ ...errMessage, confirmPassword });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} />
            </Grid>
            <Grid container item xs={12} spacing={isMobile ? 0 : 2}>
              <Grid item xs={12} md={2}>
                <Typography variant="body1" align={isMobile ? "left" : "right"}>
                  原始密码
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  name="oldPassword"
                  onChange={setValue}
                  error={errMessage.oldPassword}
                  helperText={errMessage.oldPassword}
                  type="password"
                  variant="standard"
                  fullWidth
                  onBlur={(event) => {
                    let oldPassword = "";
                    if (!event.target.value) {
                      oldPassword = "原始密码不能为空";
                    }
                    setErrMessage({ ...errMessage, oldPassword });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                onClick={async () => {
                  const newError = {
                    ...passwordValidate(userParam.password),
                    ...confirmPasswordValidate(
                      userParam.confirmPassword,
                      userParam.password
                    ),
                    ...notNullValidate(userParam.oldPassword, "oldPassword"),
                  };
                  setErrMessage(newError);
                  if (
                    Object.keys(newError)
                      .map((k) => newError[k])
                      .filter((t) => t != null && t.length > 0).length > 0
                  ) {
                    return;
                  }
                  try {
                    await updatePassword({
                      ...userParam,
                      username: user.username,
                    });
                    history.push("/");
                  } catch (e) {
                    dispatch(error(e.data && e.data.message));
                  }
                }}
              >
                修改密码
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};
