/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define generation methods for custom blocks.
 * @author samelh@google.com (Sam El-Husseini)
 */

// More on generating code:
// https://developers.google.com/blockly/guides/create-custom-blocks/generating-code

import * as Blockly from "blockly/core";
import "blockly/javascript";

Blockly.JavaScript["get_question_var"] = function (block) {
  return [
    "data." + block.getFieldValue("VAR_NAME"),
    Blockly.JavaScript.ORDER_MEMBER,
  ];
};

Blockly.JavaScript["get_mid_var"] = function (block) {
  return [
    "data." + block.getFieldValue("MID_VAR_NAME"),
    Blockly.JavaScript.ORDER_MEMBER,
  ];
};

Blockly.JavaScript["get_input_var"] = function (block) {
  return ["data.$input", Blockly.JavaScript.ORDER_MEMBER];
};

Blockly.JavaScript["set_result_val"] = function (block) {
  const argument0 =
    Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_FUNCTION_CALL
    ) || "''";
  return "data.$result = " + argument0 + ";\n";
};

Blockly.JavaScript["set_error_val"] = function (block) {
  const argument0 =
    Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_FUNCTION_CALL
    ) || "''";
  return "data.$error = " + argument0 + ";\n";
};
