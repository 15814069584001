import axios from "axios";

export function fetchAuditTemplates(isEditable) {
  return axios.get(`/api/audit-templates/profiles?editable=${!!isEditable}`);
}

export function createAuditTemplate(template) {
  return axios.post("/api/audit-templates", template);
}

export function updateAuditTemplate(template) {
  return axios.patch(`/api/audit-templates/${template.id}`, template);
}

export function getAuditTemplate(templateId) {
  return axios.get(`/api/audit-templates/${templateId}`);
}

export function getAuditTemplateCategories() {
  return axios.get("/api/audit-template-categories");
}

export function deleteAuditTemplate(templateId) {
  return axios.delete(`/api/audit-templates/${templateId}`);
}
