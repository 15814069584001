import axios from "axios";

export function fetchTemplates(isEditable) {
  return axios.get(`/api/templates/profiles?editable=${!!isEditable}`);
}

export function createTemplate(template) {
  return axios.post("/api/templates", template);
}

export function updateTemplate(template) {
  return axios.patch(`/api/templates/${template.id}`, template);
}

export function getTemplate(templateId) {
  return axios.get(`/api/templates/${templateId}`);
}

export function getTemplateCategories() {
  return axios.get("/api/template-categories");
}

export function uploadDoc(templateId, file) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("templateId", templateId);
  return axios.post("/doc-template-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteTemplate(templateId) {
  return axios.delete(`/api/templates/${templateId}`);
}
