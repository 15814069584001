import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import map from "lodash/map";
import TemplateCard, { TemplateAddCard } from "components/AuditTemplateCard";
import { fetchAuditTemplates } from "service/auditTemplate";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    maxWidth: 1200,
    margin: "0 auto",
  },
  templateContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export default () => {
  const classes = useStyles();
  const [reloading, setReloading] = useState({});
  const [auditTemplates, setAuditTemplates] = useState([]);
  const [isTemplateLoading, setTemplateLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setTemplateLoading(true);
      try {
        const { data: responseBody } = await fetchAuditTemplates(true);
        setAuditTemplates(responseBody.members);
      } catch (e) {
      } finally {
        setTemplateLoading(false);
      }
    })();
  }, [reloading]);

  return (
    <div className={classes.root}>
      <Grid key="__new">
        <Typography variant="h5">新风险模板</Typography>
        <Grid container spacing={5} className={classes.templateContainer}>
          <Grid item xs={3}>
            <TemplateAddCard />
          </Grid>
        </Grid>
      </Grid>
      <div key="__auditTemplates">
        <Typography variant="h5">已存在的风险模板</Typography>
        <Grid container spacing={5} className={classes.templateContainer}>
          {map(auditTemplates, (template, templateIndex) => {
            return (
              <Grid item xs={3} key={template.id}>
                <TemplateCard
                  id={template.id}
                  title={template.title}
                  description={template.description}
                  onDelete={() => {
                    setReloading({});
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};
