import React from "react";
import { withStyles } from "@material-ui/core/styles";
// 加载全局变量

import { UnControlled as CodeMirrors } from "react-codemirror2";
import CodeMirror from "codemirror";

import "codemirror/mode/javascript/javascript";
import "codemirror/addon/edit/closebrackets";

import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";

import "codemirror/addon/lint/lint";
import "./config/javaScriptLint";
// import "codemirror/addon/lint/javascript-lint";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/hint/show-hint.css";

const styles = (theme) => ({
  editor: {
    height: 500,
    width: "100%",
  },
  completeLine: {
    fontSize: "2rem",
  },
});

class BlocklyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.inputCode = props.value;
  }

  componentDidMount() {}

  get workspace() {}

  getVars = (prefix) => {
    prefix = prefix || "";
    const { classes } = this.props;
    const current = this.props.current;
    const list = [
      {
        text: `${prefix}$result`,
        displayText: `${prefix}$result(设置返回结果)`,
        className: classes.completeLine,
      },
    ];
    // let idx = (window.__questions || []).findIndex((q) => q === current);
    // if (idx == -1) {
    //   idx = window.__questions.length;
    // }
    list.push(
      ...window.__questions
        .filter((q) => q !== current)
        .map((q) => ({
          text: `${prefix}${q.var}`,
          displayText: `${prefix}${q.var}(${q.question})`,
          className: classes.completeLine,
        }))
    );
    let idx = (window.__midVars || []).findIndex((v) => v === current);
    if (idx == -1) {
      idx = window.__midVars.length;
    }
    list.push(
      ...window.__midVars.slice(0, idx).map((v) => ({
        text: `${prefix}${v.variableName}`,
        displayText: `${prefix}${v.variableName}(中间变量)`,
        className: classes.completeLine,
      }))
    );
    list.push(
      {
        text: `${prefix}$input`,
        displayText: `${prefix}$input(待校验输入值)`,
        className: classes.completeLine,
      },
      {
        text: `${prefix}$error`,
        displayText: `${prefix}$error(设置校验的报错信息)`,
        className: classes.completeLine,
      }
    );
    return list;
  };

  synonyms = (cm, option) => {
    return new Promise((accept) => {
      setTimeout(() => {
        let cursor = cm.getCursor(),
          line = cm.getLine(cursor.line);
        let start = cursor.ch,
          end = cursor.ch;
        // 如果是dot，判断是不是data变量，是的话，给出代码提示。
        let prefix = "";
        while (start && /\w|\$/.test(line.charAt(start - 1))) --start;
        prefix = line.slice(start, end);

        if (line.charAt(start - 1) === ".") {
          end = start;

          start -= 1;
          while (start && /\w|\$/.test(line.charAt(start - 1))) --start;
          const word = line.slice(start, end);

          if (word === "data.") {
            return accept({
              list: this.getVars().filter((item) =>
                item.text.startsWith(prefix)
              ),
              from: CodeMirror.Pos(cursor.line, end),
              to: CodeMirror.Pos(cursor.line, cursor.ch),
            });
          } else {
            return accept(null);
          }
        } else if (line.charAt(start) === "$") {
          return accept({
            list: this.getVars("$").filter((item) =>
              item.text.startsWith(prefix)
            ),
            from: CodeMirror.Pos(cursor.line, start),
            to: CodeMirror.Pos(cursor.line, cursor.ch),
          });
        } else {
          return accept(null);
        }
      }, 100);
    });
  };

  completeAfter = (ch) => (cm, pre) => {
    let cursor = cm.getCursor(),
      line = cm.getLine(cursor.line);
    let start = cursor.ch,
      end = cursor.ch;
    while (start && /\w/.test(line.charAt(start - 1))) --start;
    const word = line.slice(start, end);
    if (ch === "." && word === "data" && (!pre || pre())) {
      if (!cm.state.completionActive) cm.showHint({ completeSingle: false });
    } else if (ch === "$" && word === "" && (!pre || pre())) {
      if (!cm.state.completionActive) {
        cm.showHint({ completeSingle: false });
      }
    }
    return CodeMirror.Pass;
  };

  render() {
    const globals = this.getVars("$").reduce((pre, v) => {
      pre[v.text] = true;
      return pre;
    }, {});
    globals.data = true;
    globals.addDay = true;
    return (
      <React.Fragment>
        <div>
          <CodeMirrors
            value={this.props.value}
            options={{
              mode: { name: "javascript", globalVars: false },
              lineNumbers: true,
              autoCloseBrackets: true,
              lint: {
                asi: true,
                strict: true,
                undef: true,
                globals,
                expr: true,
              },
              gutters: ["CodeMirror-lint-markers"],
              hintOptions: { hint: this.synonyms },
              extraKeys: {
                "Ctrl-Space": "autocomplete",
                "Shift-4": this.completeAfter("$"),
                ".": this.completeAfter("."),
              },
            }}
            onChange={(editor, data, value) => {
              this.inputCode = value;
            }}
            onBlur={this.props.blur}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BlocklyEditor);
