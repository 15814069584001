import React from "react";
import {
  faBriefcase,
  faCalculator,
  faChartPie,
  faClipboardList,
  faLayerGroup,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default [
  {
    title: "合规工具",
    hash: "generate",
    link: (id) => `/contractForm/create/${id}`,
    category: "合规工具",
    varName: "templateOfGenerate",
    icon: <FontAwesomeIcon icon={faLayerGroup} />,
  },
  {
    title: "HR工具箱",
    hash: "generate",
    link: (id) => `/contractForm/create/${id}`,
    category: "HR工具箱",
    varName: "templateOfGenerate",
    icon: <FontAwesomeIcon icon={faLayerGroup} />,
  },
  {
    title: "自动生成",
    hash: "generate",
    link: (id) => `/contractForm/create/${id}`,
    category: "自动生成",
    varName: "templateOfGenerate",
    icon: <FontAwesomeIcon icon={faLayerGroup} />,
  },
  {
    title: "自动审核",
    hash: "audit",
    link: (id) => `/auditForm/create/${id}`,
    category: "自动审核",
    varName: "templateOfAudit",
    icon: <FontAwesomeIcon icon={faClipboardList} />,
  },
  {
    title: "数据分析",
    hash: "statistic",
    link: (id) => `/contractForm/create/${id}`,
    category: "数据分析",
    varName: "templateOfStatistic",
    icon: <FontAwesomeIcon icon={faChartPie} />,
  },
  {
    title: "智能SOP",
    hash: "intelligentSOP",
    link: (id) => `/contractForm/create/${id}`,
    category: "智能SOP",
    varName: "intelligentSOP",
    icon: <FontAwesomeIcon icon={faBriefcase} />,
  },
  {
    title: "问卷与计算器",
    hash: "calc",
    link: (id) => `/contractForm/create/${id}`,
    category: "问卷与计算器",
    varName: "templateOfCalc",
    icon: <FontAwesomeIcon icon={faCalculator} />,
  },
];
