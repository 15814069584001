import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import ToolTip from "@material-ui/core/ToolTip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";

import ContractImg from "assets/home/calc1.jpg";
import AddImg from "assets/forms-add.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { deleteTemplate } from "../service/template";
import { useDispatch, useSelector } from "react-redux";
import { selectIsCreator } from "pages/Login/userSlice";
import { error } from "layout/layoutSlice";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    minHeight: 280,
    height: "100%",
    margin: "auto",
  },
  rootAdd: {
    maxWidth: 345,
    height: 280,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    color: theme.palette.grey[500],
  },
  tooltip: {
    backgroundColor: theme.palette.grey[500],
  },
}));

export const TemplateAddCard = ({ total }) => {
  const classes = useStyles();
  const isCreator = useSelector(selectIsCreator);
  const dispatch = useDispatch();
  return (
    <Card className={classes.rootAdd}>
      <CardActionArea
        className={classes.rootAdd}
        component={RouterLink}
        to="/templateForm/create"
        onClick={(event) => {
          if (isCreator && total >= 3) {
            dispatch(error("模板数量超出限制，试用用户最多创建三个模板。"));
            event.preventDefault();
          }
        }}
      >
        <CardMedia component="img" image={AddImg} />
      </CardActionArea>
    </Card>
  );
};

export default (props) => {
  const classes = useStyles();
  const { id, title, description, onDelete, isLogin } = props;

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const linkRef = useRef(null);

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const closeShareDialog = () => {
    setShareDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt="Contemplative Reptile"
        height="140"
        image={ContractImg}
        title="Contemplative Reptile"
      />
      <CardContent style={{ height: "calc(100% - 140)" }}>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <ToolTip
          title={description}
          placement="right"
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        >
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </ToolTip>
      </CardContent>
      <CardActions style={{ flexDirection: "row-reverse" }}>
        {isLogin ? (
          <Button size="small" color="primary" onClick={openDialog}>
            删除
          </Button>
        ) : null}
        <Button
          size="small"
          color="primary"
          component={RouterLink}
          style={{ marginLeft: 0 }}
          to={`/templateForm/update/${id}`}
        >
          修改
        </Button>
        <Button
          size="small"
          color="primary"
          style={{ marginLeft: 0 }}
          component={RouterLink}
          target="_blank"
          to={`/contractForm/create/${id}`}
        >
          使用
        </Button>
        <Button
          size="small"
          color="primary"
          style={{ marginLeft: 0 }}
          onClick={() => setShareDialogOpen(true)}
        >
          分享
        </Button>
        <Dialog
          open={isShareDialogOpen}
          onClose={closeShareDialog}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            工具链接
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                autoFocus
                inputRef={linkRef}
                value={`${window.location.origin}/contractForm/create/${id}`}
                fullWidth
              />
            </DialogContentText>
            <span
              style={{ height: 0, display: "inline-block", overflow: "hidden" }}
            >{`${window.location.origin}/contractForm/create/${id}`}</span>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async (event) => {
                linkRef.current.select();
                document.execCommand("copy");
              }}
              variant="contained"
              color="primary"
            >
              拷贝链接
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            删除工具
          </DialogTitle>
          <DialogContent>
            <DialogContentText>确认删除工具{title}?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeDialog} color="primary">
              取消
            </Button>
            <Button
              onClick={async () => {
                try {
                  await deleteTemplate(id);
                  onDelete();
                } catch (e) {}
                closeDialog();
              }}
              color="primary"
            >
              确认
            </Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
};
