import { createSlice } from "@reduxjs/toolkit";

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const layoutSlice = createSlice({
  name: "globalError",
  initialState: {
    message: [],
  },
  reducers: {
    error: (state, action) => {
      state.message = action.payload.message;
      state.needClose = action.payload.needClose;
    },
    resetError: (state) => {
      state.message = [];
    },
  },
});

// export const { error, resetError } = layoutSlice.actions;

export const error = (message, needClose) => {
  if (!(message instanceof Array)) {
    message = [message];
  }
  return layoutSlice.actions.error({ message, needClose });
};

export const { resetError } = layoutSlice.actions;

export const selectErrorMessage = (state) => state.globalErrors.message;

export default layoutSlice.reducer;
