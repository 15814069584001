import update from "immutability-helper";

const defaultOf3DPie = {
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
      beta: 0,
    },
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      depth: 35,
      dataLabels: {
        enabled: true,
        format: "{point.name}",
      },
    },
  },
};

const default3DBar = {
  chart: {
    renderTo: "container",
    type: "column",
    options3d: {
      enabled: true,
      alpha: 15,
      beta: 15,
      depth: 50,
      viewDistance: 25,
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      depth: 25,
    },
  },
};

const defaultLine = {
  legend: {
    enabled: false,
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 0,
    },
  },

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

const template01 = {
  title: "VC Term Analyzer",
  description: "Automatically analyze data of VC projects",
  author: "VC Lawyer Adam",
  duration: "2017/1/1 - 2020/8/31",
  total: "100",

  charts: (data) => [
    {
      width: 12,
      key: "frequency",
      data: {
        title: {
          text: "Closing Date",
        },
        subtitle: {
          text: data.Date_Closing,
          style: {
            color: "#7eb2e0;",
            fontWeight: "bold",
          },
        },

        yAxis: {
          title: {
            text: "Projects",
          },
        },

        xAxis: {
          title: {
            text: "Year",
          },
          categories: [2017, 2018, 2019, 2020],
        },

        legend: {
          enabled: false,
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
          },
        },

        series: [
          {
            name: "Projects",
            data: [35, 37, 20, 8],
          },
        ],

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      },
    },
    {
      width: 6,
      key: "investmentAmount",
      data: update(default3DBar, {
        title: {
          $set: {
            text: "Investment Amount",
          },
        },
        subtitle: {
          $set: {
            text: data.Am_Investment,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: ["<5", "5-10", "10-20", ">20"],
          },
        },
        series: {
          $set: [
            {
              name: "Projects",
              data: [5, 35, 50, 10],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "perMoneyValuation",
      data: update(default3DBar, {
        title: {
          $set: {
            text: " Per-Money Valuation",
          },
        },
        subtitle: {
          $set: {
            text: data.Am_Pre_Valuation,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: ["<20", "20-100", "100-200", ">200"],
          },
        },
        series: {
          $set: [
            {
              name: "Projects",
              data: [6, 74, 18, 2],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "postClosingESOP",
      data: update(default3DBar, {
        title: {
          $set: {
            text: " Post-Closing ESOP",
          },
        },
        subtitle: {
          $set: {
            text: data.Per_ESOP,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: [
              "<5%",
              "5%-10%",
              "10%-15%",
              "15%-20%",
              "20%-25%",
              ">25%",
            ],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "Projects",
            },
          },
        },
        series: {
          $set: [
            {
              name: "Projects",
              data: [1, 12, 28, 50, 8, 1],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "typesOfDividend",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: "Types of Dividend",
          },
        },
        subtitle: {
          $set: {
            text: data.Dividend_Type,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "Projects",
              data: [
                ["As converted", 80],
                ["Non-cumulative", 19],
                ["Cumulative", 1],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 12,
      key: "typesOfLiquidationPreference",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: "Types of Liquidation Preference",
          },
        },
        subtitle: {
          $set: {
            text: data.Type_Liquidation_Preference,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "Projects",
              data: [
                ["Non-participating", 76],
                ["Fully participating", 10],
                ["Capped participating", 13],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "isThereRedemption",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: "Redemption Right?",
          },
        },
        subtitle: {
          $set: {
            text: data.If_Redemption === "true" ? "Yes" : "No",
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "Projects",
              data: [
                ["Yes", 65],
                ["No", 35],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "isThereDragAlong",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: "Drag-Along Right?",
          },
        },
        subtitle: {
          $set: {
            text: data.If_Drag === "true" ? "Yes" : "No",
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "Projects",
              data: [
                ["Yes", 55],
                ["No", 45],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "isThereShareRestricted",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: "Founder Restricted Shares?",
          },
        },
        subtitle: {
          $set: {
            text: data.If_Founder_Restricted === "true" ? "Yes" : "No",
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "Projects",
              data: [
                ["Yes", 55],
                ["No", 45],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "Dispute_Resolution_Type",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: "Method of Dispute Resolution",
          },
        },
        subtitle: {
          $set: {
            text: data.Dispute_Resolution_Type,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "Projects",
              data: [
                ["Litiation", 24],
                ["Arbitration", 76],
              ],
            },
          ],
        },
      }),
    },
  ],
};

const template02 = {
  title: "房屋租赁数据分析器",
  description: "自动对房屋租赁项目数据进行统计分析",
  author: "商业地产刘律师",
  duration: "2019/1/1 - 2020/9/30",
  total: "352",

  charts: (data) => [
    {
      width: 12,
      key: "signingDate",
      data: {
        title: {
          text: `签约时间`,
        },

        subtitle: {
          text: data.Date_Sigining,
          style: {
            color: "#7eb2e0;",
            fontWeight: "bold",
          },
        },

        yAxis: {
          title: {
            text: "签约数",
          },
        },

        xAxis: {
          title: {
            text: "季度",
          },
          categories: [
            "2019-Q1",
            "2019-Q2",
            "2019-Q3",
            "2019-Q4",
            "2020-Q1",
            "2020-Q2",
            "2020-Q3",
          ],
        },

        legend: {
          enabled: false,
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
          },
        },

        series: [
          {
            name: "签约数",
            data: [35, 76, 82, 122, 0, 15, 22],
          },
        ],

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      },
    },
    {
      width: 6,
      key: "propertyAddress",
      data: update(default3DBar, {
        title: {
          $set: {
            text: `租赁房屋地址`,
          },
        },
        subtitle: {
          $set: {
            text: data.Property_Address,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: ["北京", "上海", "深圳", "广州", "其他"],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "签约数",
            },
          },
        },
        legend: {
          $set: {
            enabled: false,
          },
        },
        series: {
          $set: [
            {
              name: "签约数",
              data: [98, 130, 45, 23, 56],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "propertyArea",
      data: update(default3DBar, {
        title: {
          $set: {
            text: `租赁房屋面积(平方米)`,
          },
        },
        subtitle: {
          $set: {
            text: data.Nu_Property_Area,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: [
              "<50",
              "50-100",
              "100-150",
              "150-200",
              "200-300",
              ">300",
            ],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "签约数",
            },
          },
        },
        series: {
          $set: [
            {
              name: "签约数",
              data: [7, 45, 183, 87, 22, 8],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "yearTerm",
      data: update(default3DBar, {
        title: {
          $set: {
            text: `租赁期(不包括装修期)年数`,
          },
        },
        subtitle: {
          $set: {
            text: data.Nu_Year_Term,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: ["<3", "3", "4", "5", "6", ">6"],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "签约数",
            },
          },
        },
        series: {
          $set: [
            {
              name: "签约数",
              data: [5, 113, 97, 124, 12, 1],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "yearTerm",
      data: update(default3DBar, {
        title: {
          $set: {
            text: `装修期(不计入租赁期)天数`,
          },
        },
        subtitle: {
          $set: {
            text: data.Nu_Day_Renovation,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: ["<15", "15-30", "30-45", "45-60", "60-90", ">90"],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "签约数",
            },
          },
        },
        series: {
          $set: [
            {
              name: "签约数",
              data: [45, 77, 154, 74, 2, 0],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "ifKeepCopy",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: `是否租金递增`,
          },
        },
        subtitle: {
          $set: {
            text: data.If_Rent_Increase === "true" ? "是" : "否",
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "签约数",
              data: [
                ["是", 212],
                ["否", 140],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "rentPeriod",
      data: update(default3DBar, {
        title: {
          $set: {
            text: `租金几月一付`,
          },
        },
        subtitle: {
          $set: {
            text: data.Nu_Rent_Period,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: ["1", "2", "3", "3-6", "6-12", ">12"],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "签约数",
            },
          },
        },
        series: {
          $set: [
            {
              name: "签约数",
              data: [198, 61, 75, 11, 6, 1],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "monthDeposit",
      data: update(default3DBar, {
        title: {
          $set: {
            text: `押金相当于几个月的租金`,
          },
        },
        subtitle: {
          $set: {
            text: data.Nu_Month_Deposit,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: ["1", "2", "3", "3-6", ">6"],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "签约数",
            },
          },
        },
        series: {
          $set: [
            {
              name: "签约数",
              data: [243, 65, 31, 11, 2],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "monthDeposit",
      data: update(default3DBar, {
        title: {
          $set: {
            text: `提前解约需支付相当于几个月租金的违约金`,
          },
        },
        subtitle: {
          $set: {
            text: data.Nu_Month_Penalty,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: ["1", "2", "3", "4", "5", "6", ">6"],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "签约数",
            },
          },
        },
        series: {
          $set: [
            {
              name: "签约数",
              data: [2, 6, 104, 56, 67, 113, 4],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "ifLandlordPerson",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: `是否出租方是个人`,
          },
        },
        subtitle: {
          $set: {
            text: data.If_Landlord_Person === "true" ? "是" : "否",
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "签约数",
              data: [
                ["是", 189],
                ["否", 163],
              ],
            },
          ],
        },
      }),
    },
  ],
};

const template03 = {
  title: "保密协议数据分析器",
  description: "自动对保密协议项目数据进行统计分析",
  author: "公司法务张总监",
  duration: "2020/1/1 - 2020/8/31",
  total: "58",

  charts: (data) => [
    {
      width: 12,
      key: "signingDate",
      data: {
        title: {
          text: `签约时间`,
        },
        subtitle: {
          text: data.Date_Signing,
          style: {
            color: "#7eb2e0;",
            fontWeight: "bold",
          },
        },
        yAxis: {
          title: {
            text: "签约数",
          },
        },

        xAxis: {
          title: {
            text: "月份",
          },
          categories: [
            "2020-01",
            "2020-02",
            "2020-03",
            "2020-04",
            "2020-05",
            "2020-06",
            "2020-07",
            "2020-08",
          ],
        },

        legend: {
          enabled: false,
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 0,
          },
        },

        series: [
          {
            name: "签约数",
            data: [5, 0, 0, 2, 8, 15, 16, 12],
          },
        ],

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      },
    },
    {
      width: 6,
      key: "NDAType",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: `是双向保密协议还是单向保密协议`,
          },
        },
        subtitle: {
          $set: {
            text: data.NDA_Type,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "签约数",
              data: [
                ["双向", 14],
                ["单向", 44],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "purpose",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: `保密信息的用途`,
          },
        },
        subtitle: {
          $set: {
            text: data.Purpose1,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "签约数",
              data: [
                ["投资", 20],
                ["业务合作", 30],
                ["其他用途", 8],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "monthTerm",
      data: update(default3DBar, {
        title: {
          $set: {
            text: `保密期长度(月数)`,
          },
        },
        subtitle: {
          $set: {
            text: data.Nu_Month_Term,
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        xAxis: {
          $set: {
            categories: [
              "<6",
              "6-12",
              "12-18",
              "18-24",
              "24-30",
              "30-36",
              ">36",
            ],
          },
        },
        yAxis: {
          $set: {
            title: {
              text: "签约数",
            },
          },
        },
        series: {
          $set: [
            {
              name: "签约数",
              data: [0, 5, 25, 24, 2, 1, 1],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "ifOutsider",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: `是否允许接收方向其特定外部人员披露`,
          },
        },
        subtitle: {
          $set: {
            text: data.If_Outsider === "true" ? "是" : "否",
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "签约数",
              data: [
                ["是", 45],
                ["否", 13],
              ],
            },
          ],
        },
      }),
    },
    {
      width: 6,
      key: "ifKeepCopy",
      data: update(defaultOf3DPie, {
        title: {
          $set: {
            text: `是否允许接收方为合规的目的保留保密信息副本`,
          },
        },
        subtitle: {
          $set: {
            text: data.If_Keep_Copy === "true" ? "是" : "否",
            style: {
              color: "#7eb2e0;",
              fontWeight: "bold",
            },
          },
        },
        series: {
          $set: [
            {
              type: "pie",
              name: "签约数",
              data: [
                ["是", 45],
                ["否", 13],
              ],
            },
          ],
        },
      }),
    },
  ],
};

export default {
  "5f63013b061dac322e0fe27c": template01,
  "5f630b1f061dac322e0fe280": template02,
  "5f630aa0061dac322e0fe27f": template03,
};
