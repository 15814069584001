import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useLocation, useParams } from "react-router";
import { downloadURI } from "../../common/helper";
import { makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iframe: {
    width: "100vw",
    height: "90vh",
    paddingTop: theme.spacing(5),
    boxSizing: "border-box",
  },
  button: {
    height: "9vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hit: {
    color: theme.fontColor.grey2,
    paddingBottom: theme.spacing(1),
  },
}));

export default () => {
  const { contractId } = useParams();
  // const location = useLocation();
  const downloadUrl = `http://www.doxauto.com/download-contract/${contractId}.docx`;
  const classes = useStyles();
  return (
    <div>
      <iframe
        className={classes.iframe}
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          downloadUrl
        )}`}
      />
      <Grid container className={classes.button} direction={"column"}>
        <Typography className={classes.hit}>
          以上是快速预览，格式更完美的WORD文档已下载到本地
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            // 下载
            // const { name: docName } = queryString.parse(location.search);
            // downloadURI(
            //   `/download-contract/${contractId}`,
            //   `${docName}${new Date().toLocaleString()}`
            // );
            window.close();
          }}
        >
          关闭
        </Button>
      </Grid>
    </div>
  );
};
