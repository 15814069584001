import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import update from "immutability-helper";
import Grid from "@material-ui/core/Grid";
import RiskCard from "./RiskCard";

const TITLE_KEY = "title";

class RiskSection extends Component {
  constructor(props) {
    super(props);
  }

  isActive = (key) => {
    return this.props.activityCardKey === key;
  };

  setActivityCard = (activityCardKey) => {
    this.props.onFocus(activityCardKey);
  };

  setActivityGrid = (event) => {
    const key = event.currentTarget.getAttribute("x-key");
    if (key === this.props.activityCardKey) {
      return;
    }
    this.props.onFocus(key);
  };

  onRiskChange = (newRisk, originRisk) => {
    const riskIndex = this.props.risks.findIndex((risk) => risk === originRisk);
    const newRisks = update(this.props.risks, {
      [riskIndex]: {
        $set: newRisk,
      },
    });
    this.props.onRisksChange(newRisks, this.props.risks);
    if (
      newRisk.moduleLevel1 === originRisk.moduleLevel1 &&
      newRisk.moduleLevel2 === originRisk.moduleLevel2
    ) {
      return;
    }
    if (
      this.props.modules[newRisk.moduleLevel1] &&
      this.props.modules[newRisk.moduleLevel1][newRisk.moduleLevel2]
    ) {
      return;
    }
    this.props.onModuleChange(newRisks);
  };

  onRiskPositionChange = (type, originRisk, event) => {
    // TODO: 要处理一下 question 和 risk的问题
    const { risks } = this.props;
    const riskIdx = risks.findIndex((risk) => risk === originRisk);
    let newRisks;
    switch (type) {
      case "DELETE":
        newRisks = update(risks, {
          $splice: [[riskIdx, 1]],
        });
        if (newRisks.length === 0) {
          this.setActivityCard(TITLE_KEY);
        } else {
          const newActive =
            newRisks[Math.min(newRisks.length - 1, riskIdx)].key;
          this.setActivityCard(newActive);
        }
        this.props.onRisksChange(newRisks, risks);
        event.stopPropagation(); // 防止冒泡上去触发card的onclick事件, TODO: 这部分逻辑下放比较好
        break;
      case "UP":
        if (riskIdx === 0) {
          return;
        }
        newRisks = update(risks, {
          $splice: [
            [riskIdx, 1],
            [riskIdx - 1, 0, originRisk],
          ],
        });
        this.props.onRisksChange(newRisks, risks);
        break;
      case "DOWN":
        if (riskIdx === risks.length - 1) {
          return;
        }
        newRisks = update(risks, {
          $splice: [
            [riskIdx, 1],
            [riskIdx + 1, 0, originRisk],
          ],
        });
        this.props.onRisksChange(newRisks, risks);
        break;
      default:
        break;
    }
  };

  render() {
    const { classes, risks, modules, activeRef } = this.props;

    return (
      <React.Fragment>
        {risks.map((risk) => {
          return (
            <Grid
              className={classes.question}
              key={risk.key}
              x-key={risk.key}
              onClick={this.setActivityGrid}
              ref={this.isActive(risk.key) ? activeRef : null}
            >
              <RiskCard
                active={this.isActive(risk.key)}
                onChange={this.onRiskChange}
                modules={modules}
                risk={risk}
                onPositionChange={this.onRiskPositionChange}
              />
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withStyles((theme) => ({
  root: {},
}))(RiskSection);
