import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Container, Link, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import commonStyles from "common/commonStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link as RouterLink } from "react-router-dom";

import homeImg from "assets/faq/homepage.png";
import home2Img from "assets/faq/homepage2.png";
import myDoxAutoImg from "assets/faq/mydoxauto.png";
import sampleImg from "assets/faq/sample.png";
import inputImg from "assets/faq/input.png";
import inputResultImg from "assets/faq/input-result.png";
import pDelete from "assets/faq/pdelete.png";
import lineDelete from "assets/faq/linedelete.png";
import ipDelete from "assets/faq/ipdelete.png";
import mpDelete from "assets/faq/mpdelete.png";
import onlineQ from "assets/faq/onlineq.png";
import onlineQTitle from "assets/faq/onlineqtitle.png";
import onlineQDetail from "assets/faq/onlineqdetail.png";
import onlineQVars from "assets/faq/onlineqvars.png";
import onlineQSubmit from "assets/faq/onlineqsubmit.png";
import onlineQUpload from "assets/faq/onlinequpload.png";

import myTool from "assets/faq/mytool.png";
import myAnswer from "assets/faq/myanswer.png";
import myPreview from "assets/faq/mypreview.png";
import editOnlineQ from "assets/faq/editonlineq.png";
import varsList from "assets/faq/varslist.png";
import expression01 from "assets/faq/expression01.png";
import expression02 from "assets/faq/expression02.png";
import expression03 from "assets/faq/expression03.png";
import expression04 from "assets/faq/expression04.png";
import expression05 from "assets/faq/expression05.png";
import expression06 from "assets/faq/expression06.png";
import expression07 from "assets/faq/expression07.png";
import expression08 from "assets/faq/expression08.png";
import expression09 from "assets/faq/expression09.png";
import expression10 from "assets/faq/expression10.png";
import expression11 from "assets/faq/expression11.png";
import expression12 from "assets/faq/expression12.png";
import expression13 from "assets/faq/expression13.png";
import expression14 from "assets/faq/expression14.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {},
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 2,
  },
  title: {
    fontSize: "2.5rem",
    paddingTop: theme.spacing(2),
    color: theme.fontColor.grey2,
  },
  text: {
    color: theme.fontColor.grey3,
  },
  container: {
    position: "relative",
    marginTop: 0,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },

  question: {
    color: theme.fontColor.grey3,
    fontWeight: 600,
  },
  answer: {
    color: theme.fontColor.grey3,
  },
  img: {
    maxWidth: "70%",
    display: "block",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

  smallImg: {
    width: "50%",
    display: "block",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

  link: {
    color: theme.fontColor.grey3,
  },
  code: {
    backgroundColor: "rgba(255, 229, 100, 0.2)",
    borderRadius: 2,
    display: "inline-block",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  codeEditor: {
    display: "block",
    padding: theme.spacing(1),
  },
  anchor: {
    marginTop: -78 /* Size of fixed header */,
    paddingBottom: 78,
    display: "block",
    visibility: "hidden",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    paddingTop: "60px!important",
    zIndex: 1,

    [theme.breakpoints.down("sm")]: {
      position: "static",
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
  },
  menu: {
    minWidth: "170px",
    paddingRight: theme.spacing(5),
  },
  menuTitle: {
    marginBottom: theme.spacing(1),
  },

  menuItem: {
    cursor: "pointer",
  },
  ...commonStyles(theme),
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="lg" className={classes.main}>
      <Grid container spacing={8} className={classes.container}>
        {!isPhone ? (
          <React.Fragment>
            <Grid item md={2}></Grid>
          </React.Fragment>
        ) : null}
        <Grid item md={2} className={classes.menuContainer}>
          <List className={classes.menu}>
            <ListItem
              alignItems="flex-start"
              className={classes.menuItem}
              component={Link}
              href="#title-faq"
            >
              <ListItemText
                primary="使用指南"
                primaryTypographyProps={{
                  style: {
                    color: theme.fontColor.grey3,
                  },
                }}
              />
            </ListItem>
            <Divider />

            <ListItem
              alignItems="flex-start"
              component={Link}
              href="#title-video"
            >
              <ListItemText
                primary="资讯与活动"
                primaryTypographyProps={{
                  style: {
                    color: theme.fontColor.grey3,
                  },
                }}
              />
            </ListItem>
            <Divider />
          </List>
        </Grid>
        <Grid
          container
          spacing={2}
          item
          xs={12}
          md={10}
          className={classes.textContainer}
        >
          <div className={classes.anchor} id="title-faq" />
          <Typography variant="h5" className={classes.title} align="center">
            使用指南
          </Typography>
          <div
            style={{
              paddingTop: "30px",
            }}
          >
            <a
              style={{
                textDecoration: "",
              }}
              target="_blank"
              href="https://mp.weixin.qq.com/s?__biz=MzIwODMwMTEyMw==&mid=2247483956&idx=1&sn=6e0530e57a2f491acd1f12ed49814de2&chksm=97047f18a073f60e7162116c4b0152d951e6c4823d5be59e84e3a7e0bc44ec387006de14fe15&token=178338073&lang=zh_CN"
            >
              使用指南
            </a>
          </div>

          <div className={classes.anchor} id="title-video" />
          <Typography
            id="title_faq"
            variant="h5"
            className={classes.title}
            align="center"
          >
            资讯与活动
          </Typography>
          <Grid item>

           <div
                       style={{
                         paddingTop: "30px",
                       }}
                     >
                       <a
                         style={{
                           textDecoration: "",
                         }}
                         target="_blank"
                         href="https://mp.weixin.qq.com/s?__biz=MzIwODMwMTEyMw==&mid=2247483919&idx=1&sn=fbba93560614f4f91f16be9d8fa07c1b&chksm=97047f23a073f63512975225b618ef02f6e48d97067f45a6009a06ddf8732f38c124a0b40a7a&token=178338073&lang=zh_CN#rd"
                       >
                         端到端的合同自动化解决方案 – 以零售商铺租赁为例
                       </a>
                     </div>

            <div
                                   style={{
                                     paddingTop: "30px",
                                   }}
                                 >
                                   <a
                                     style={{
                                       textDecoration: "",
                                     }}
                                     target="_blank"
                                     href="https://mp.weixin.qq.com/s?__biz=MzIwODMwMTEyMw==&mid=2247483732&idx=1&sn=921c497fe695c511f9870d4d8e335d27&chksm=97047c78a073f56e42183646e145b3419eb1393088918b2ab6ee42b04f2b99640e66643f8619&token=178338073&lang=zh_CN#rd"
                                   >
                                     自动化的律师：1分钟=1小时；1月=1年
                                   </a>
                                 </div>

            <div
                                              style={{
                                                paddingTop: "30px",
                                              }}
                                            >
                                              <a
                                                style={{
                                                  textDecoration: "",
                                                }}
                                                target="_blank"
                                                href="https://mp.weixin.qq.com/s?__biz=MzIwODMwMTEyMw==&mid=2247483906&idx=1&sn=122f464864dadcb820d713988fcc6f63&chksm=97047f2ea073f638cbf76d2aebf482bb67d57edd4802148ebbccdf3a255d3a0e74017a54db32&token=178338073&lang=zh_CN#rd"
                                              >
                                                DoxAuto上线全球首个自助式文档自动化平台
                                              </a>
                                            </div>

           <div
                                                         style={{
                                                           paddingTop: "30px",
                                                         }}
                                                       >
                                                         <a
                                                           style={{
                                                             textDecoration: "",
                                                           }}
                                                           target="_blank"
                                                           href="https://mp.weixin.qq.com/s?__biz=MzIwODMwMTEyMw==&mid=2247483906&idx=2&sn=4ba7f65fff4f75bf36d6aebf1b196aa2&chksm=97047f2ea073f638ce3e9bc23101ae589aeaf70d59d1cfe2d2f615a00d7d4280b928b6858900&token=178338073&lang=zh_CN#rd"
                                                         >
                                                           DoxAuto Launches the World’s First Self-Service Document Automation Platform
                                                         </a>
                                                       </div>
          </Grid>

         <Grid item>

                   <div
                               style={{
                                 paddingTop: "30px",
                               }}
                             >
                               <a href="../resources/autosigpack.xlsm">自动签名件生成器（试用版）</a>
                             </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
