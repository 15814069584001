import React, { useState } from "react";
import update from "immutability-helper";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Menu from "@material-ui/core/Menu";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ShortTextIcon from "@material-ui/icons/ShortText";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TableChartIcon from "@material-ui/icons/TableChart";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import ListItemText from "@material-ui/core/ListItemText";

import TypeTableSetting from "./TypeTableSetting";
import TypeBoolSetting from "./TypeBoolSetting";
import TypeTextSetting from "./TypeTextSetting";
import TypeDateSetting from "./TypeDateSetting";
import TypeRadioSetting from "./TypeRadioSetting";
import commonStyles from "common/commonStyles";
import {
  BOOL,
  DATE,
  RADIO,
  TABLE,
  TEXT,
  CHECK_BOX,
} from "common/commonConstants/QuestionCardType";
import translator, {
  FORMAT_BOOL,
  FORMAT_DATE_CH,
  FORMAT_DATE_COMMON,
  FORMAT_DATE_COMMON2,
  FORMAT_DATE_EN,
  FORMAT_NUMBER,
  FORMAT_NUMBER_CH_LOWERCASE,
  FORMAT_NUMBER_CH_UPPERCASE,
  FORMAT_NUMBER_CURRENCY_RMB,
  FORMAT_NUMBER_CURRENCY_RMB_W,
  FORMAT_NUMBER_CURRENCY_US,
  FORMAT_NUMBER_EN,
  FORMAT_NUMBER_FLOAT_FIXED_2,
  FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP,
  FORMAT_NUMBER_INT,
  FORMAT_NUMBER_INT_WITH_SEP,
  FORMAT_NUMBER_PERCENTAGE,
  FORMAT_TEXT,
  FORMAT_UNSUPPORTED,
} from "common/commonConstants/QuestionTypeFormat";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import TextFieldWithCodeMirror from "components/TextFieldWithCodeMirror";
import TypeCheckBoxSetting from "./TypeCheckBoxSetting";

const useStyles = makeStyles((theme) => ({
  templateHeader: {
    marginTop: theme.spacing(5),
    position: "relative",
  },
  questionActiveBar: {
    width: "6px",
    height: "100%",
    position: "absolute",
    backgroundColor: "#4285f4",
  },
  questionContent: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    overflow: "hidden",
  },
  smallGutterTop: {
    marginTop: theme.spacing(2),
  },

  questionTypeSelect: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  menuItemText: {
    paddingRight: theme.spacing(6),
  },

  ...commonStyles(theme),
}));

export const QuestionType = {
  Number: "数字",
  Text: "文本",
  Bool: "布尔",
  Date: "日期",
};

const inputTypes = (questionType) => {
  if (!questionType) {
    return [TEXT, RADIO, BOOL, DATE, CHECK_BOX];
  }
  switch (questionType) {
    case QuestionType.Number:
      return [TEXT, RADIO];
    case QuestionType.Text:
      return [TEXT, RADIO, CHECK_BOX];
    case QuestionType.Bool:
      return [BOOL];
    case QuestionType.Date:
      return [DATE];
  }
};

const supportFormatByQuestionType = {
  [QuestionType.Text]: [translator[FORMAT_TEXT]],
  [QuestionType.Number]: [
    translator[FORMAT_NUMBER],
    translator[FORMAT_NUMBER_INT],
    translator[FORMAT_NUMBER_INT_WITH_SEP],
    translator[FORMAT_NUMBER_FLOAT_FIXED_2],
    translator[FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP],
    translator[FORMAT_NUMBER_PERCENTAGE],
    translator[FORMAT_NUMBER_EN],
    translator[FORMAT_NUMBER_CH_LOWERCASE],
    translator[FORMAT_NUMBER_CH_UPPERCASE],
    translator[FORMAT_NUMBER_CURRENCY_US],
    translator[FORMAT_NUMBER_CURRENCY_RMB],
    translator[FORMAT_NUMBER_CURRENCY_RMB_W],
  ],
  [QuestionType.Date]: [
    translator[FORMAT_DATE_COMMON],
    translator[FORMAT_DATE_COMMON2],
    translator[FORMAT_DATE_CH],
    translator[FORMAT_DATE_EN],
  ],
  [QuestionType.Bool]: [translator[FORMAT_BOOL]],
};

const supportFormat = {
  [CHECK_BOX]: [translator[FORMAT_TEXT]],
  [RADIO]: [
    translator[FORMAT_TEXT],
    translator[FORMAT_NUMBER],
    translator[FORMAT_NUMBER_INT],
    translator[FORMAT_NUMBER_INT_WITH_SEP],
    translator[FORMAT_NUMBER_FLOAT_FIXED_2],
    translator[FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP],
    translator[FORMAT_NUMBER_PERCENTAGE],
    translator[FORMAT_NUMBER_EN],
    translator[FORMAT_NUMBER_CH_LOWERCASE],
    translator[FORMAT_NUMBER_CH_UPPERCASE],
    translator[FORMAT_NUMBER_CURRENCY_US],
    translator[FORMAT_NUMBER_CURRENCY_RMB],
    translator[FORMAT_NUMBER_CURRENCY_RMB_W],
  ],
  [TEXT]: [
    translator[FORMAT_TEXT],
    translator[FORMAT_NUMBER],
    translator[FORMAT_NUMBER_INT],
    translator[FORMAT_NUMBER_INT_WITH_SEP],
    translator[FORMAT_NUMBER_FLOAT_FIXED_2],
    translator[FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP],
    translator[FORMAT_NUMBER_PERCENTAGE],
    translator[FORMAT_NUMBER_EN],
    translator[FORMAT_NUMBER_CH_LOWERCASE],
    translator[FORMAT_NUMBER_CH_UPPERCASE],
    translator[FORMAT_NUMBER_CURRENCY_US],
    translator[FORMAT_NUMBER_CURRENCY_RMB],
    translator[FORMAT_NUMBER_CURRENCY_RMB_W],
  ],
  [DATE]: [
    translator[FORMAT_DATE_COMMON],
    translator[FORMAT_DATE_COMMON2],
    translator[FORMAT_DATE_CH],
    translator[FORMAT_DATE_EN],
  ],
  [BOOL]: [translator[FORMAT_BOOL]],
  [TABLE]: [translator[FORMAT_UNSUPPORTED]],
};

const menuItem = function (value) {
  return (
    <MenuItem value={value} key={value}>
      <ListItemIcon>
        {(() => {
          switch (value) {
            case TEXT:
              return <ShortTextIcon />;
            case RADIO:
              return <RadioButtonCheckedIcon />;
            case DATE:
              return <CalendarTodayIcon />;
            case BOOL:
              return <TableChartIcon />;
            case CHECK_BOX:
              return <CheckBoxOutlineBlankIcon />;
            case TABLE:
              return <TableChartIcon />;
          }
        })()}
      </ListItemIcon>
      <ListItemText
        primary={(() => {
          switch (value) {
            case TEXT:
              return "输入框";
            case RADIO:
              return "选择";
            case CHECK_BOX:
              return "多选";
            case DATE:
              return "日期输入框";
            case BOOL:
              return "布尔选项";
            case TABLE:
              return "表格";
            default:
              return value;
          }
        })()}
      />
    </MenuItem>
  );
};

const toggleProperty = (obj, propName) => {
  let newObj;
  if (propName in obj) {
    newObj = update(obj, {
      $unset: [propName],
    });
  } else {
    // TODO: 更好的方式
    newObj = update(obj, {
      [propName]: {
        $set:
          propName === "dependencyExpression" ||
          propName === "validationExpression"
            ? {}
            : "",
      },
    });
  }
  return newObj;
};

const isMenuItemChecked = (field, propName) => propName in field;

const genTypeUpdateSpec = (value, questionType, spec) => {
  spec.type = { $set: value };
  switch (value) {
    case TEXT:
      if (questionType === QuestionType.Number) {
        spec.format = { $set: FORMAT_NUMBER };
      } else {
        spec.format = { $set: FORMAT_TEXT };
      }
      spec.$unset = ["defaultValue", "options", "columns"];
      spec.editable = {
        $set: true,
      };
      break;
    case DATE:
      spec.format = { $set: FORMAT_DATE_COMMON };
      spec.$unset = ["defaultValue", "options", "columns"];
      break;
    case RADIO:
    case CHECK_BOX:
      if (questionType === QuestionType.Number) {
        spec.format = { $set: FORMAT_NUMBER };
      } else {
        spec.format = { $set: FORMAT_TEXT };
      }
      spec.$unset = ["defaultValue", "columns"];
      spec.options = {
        $set: [],
      };
      break;
    case BOOL:
      spec.format = { $set: FORMAT_BOOL };
      spec.$unset = ["defaultValue", "columns"];
      spec.options = {
        $set: [
          { label: "TRUE", value: "true" },
          { label: "FALSE", value: "false" },
        ],
      };
      break;
    case TABLE:
      spec.format = { $set: FORMAT_UNSUPPORTED };
      spec.$unset = ["options", "defaultValue", "defaultValueExpression"];
      spec.columns = {
        $set: [],
      };
      break;
    default:
  }
  return spec;
};

const QuestionCardFunc = ({
  active,
  question,
  modules,
  onChange,
  onPositionChange,
  editor,
  addQuestion,
  showDeleteButton,
}) => {
  const classes = useStyles();
  const [moreEl, setMoreEl] = useState(null);
  const CodeEditor = editor || TextFieldWithCodeMirror;
  const openMoreMenu = (event) => {
    setMoreEl(event.currentTarget);
  };

  const closeMoreMenu = (event) => {
    setMoreEl(null);
  };

  const clickMenuItem = (event) => {
    const propName = event.currentTarget.getAttribute("name");
    const newField = toggleProperty(question, propName);
    onChange(newField, question);
    setMoreEl(null);
  };

  const onQuestionTypeChange = (event) => {
    const value = event.target.value;
    const types = inputTypes(value);
    let spec = { questionType: { $set: value } };
    spec.type = { $set: types[0] };
    spec = genTypeUpdateSpec(types[0], value, spec);
    const newField = update(question, spec);
    onChange(newField, question);
  };

  /**
   * 类型转换时，要做一些字段的初始化。
   * @param event
   */
  const onTypeChange = (event) => {
    const value = event.target.value;
    const spec = genTypeUpdateSpec(value, question.questionType, {});
    const newField = update(question, spec);
    onChange(newField, question);
  };

  const onValueChange = (event) => {
    const propertyName = event.target.name;
    const value = event.target.value;
    let newQuestion = update(question, {
      [propertyName]: { $set: value },
    });

    onChange(newQuestion, question);
  };

  const valueChange = (func) => (event) => {
    const propertyName = event.target.name;
    const newField = update(question, {
      [propertyName]: { $set: func(event) },
    });
    onChange(newField, question);
  };

  const isEditable = () => active && question.enabled;
  const isNotEditable = () => !isEditable();

  return (
    <Card className={classes.templateHeader}>
      <div style={{ display: "flex" }}>
        <div
          className={classes.questionActiveBar}
          style={{ opacity: active ? 1 : 0 }}
        />
        <div className={classes.questionContent}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container spacing={3} item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <TextField
                      fullWidth
                      autoFocus
                      disabled={isNotEditable()}
                      name="question"
                      value={question.question}
                      margin="normal"
                      placeholder="问题内容"
                      InputProps={{
                        className: classes.test,
                        autoComplete: "off",
                      }}
                      onChange={onValueChange}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    placeholder="问题变量名"
                    multiline
                    fullWidth
                    disabled={isNotEditable()}
                    margin="normal"
                    name="var"
                    value={question.var}
                    onChange={onValueChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="combo-box-demo"
                    freeSolo
                    disabled={!active}
                    value={question.moduleLevel1 || ""}
                    options={Object.keys(modules)}
                    getOptionLabel={(category) => category}
                    onChange={(event, value) => {
                      onValueChange({
                        target: {
                          name: "moduleLevel1",
                          value,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="问题分类"
                        variant="outlined"
                        name="moduleLevel1"
                        onChange={onValueChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} className={active ? "" : classes.hidden}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="question-type-label">答案类型</InputLabel>
                    <Select
                      labelId="question-type-label"
                      multiple={false}
                      renderValue={menuItem}
                      name="questionType"
                      label="答案类型"
                      value={question.questionType}
                      disabled={isNotEditable()}
                      onChange={onQuestionTypeChange}
                      inputProps={{
                        className: classes.questionTypeSelect,
                      }}
                    >
                      {[
                        QuestionType.Text,
                        QuestionType.Bool,
                        QuestionType.Number,
                        QuestionType.Date,
                      ].map((v) => menuItem(v))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} className={active ? "" : classes.hidden}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      输入方式
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      multiple={false}
                      renderValue={menuItem}
                      name="type"
                      value={question.type}
                      disabled={isNotEditable()}
                      onChange={onTypeChange}
                      label="输入方式"
                      inputProps={{
                        className: classes.questionTypeSelect,
                      }}
                    >
                      {inputTypes(question.questionType).map((v) =>
                        menuItem(v)
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} className={active ? "" : classes.hidden}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      输出格式
                    </InputLabel>
                    <Select
                      name="format"
                      onChange={onValueChange}
                      value={question.format}
                      label="输出格式"
                    >
                      {(
                        supportFormatByQuestionType[question.questionType] ||
                        supportFormat[question.type]
                      ).map((tran) => {
                        return (
                          <MenuItem key={tran.value} value={tran.value}>
                            {tran.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {(() => {
                let settings;
                switch (question.type) {
                  case TABLE:
                    settings = (
                      <TypeTableSetting
                        active={isEditable()}
                        columns={question.columns}
                        onChange={onValueChange}
                      />
                    );
                    break;
                  case RADIO:
                    settings = (
                      <TypeRadioSetting
                        active={isEditable()}
                        question={question}
                        defaultValueExpression={question.defaultValueExpression}
                        defaultValue={question.defaultValue}
                        options={question.options}
                        onChange={onValueChange}
                      />
                    );
                    break;
                  case CHECK_BOX:
                    settings = (
                      <TypeCheckBoxSetting
                        active={isEditable()}
                        question={question}
                        defaultValueExpression={question.defaultValueExpression}
                        defaultValue={question.defaultValue || {}}
                        options={question.options}
                        onChange={onValueChange}
                      />
                    );
                    break;
                  case BOOL:
                    settings = (
                      <TypeBoolSetting
                        active={isEditable()}
                        question={question}
                        onChange={onChange}
                      />
                    );
                    break;
                  case TEXT:
                    settings = (
                      <TypeTextSetting
                        active={isEditable()}
                        field={question}
                        onChange={onChange}
                      />
                    );
                    break;
                  case DATE:
                    settings = (
                      <TypeDateSetting
                        active={isEditable()}
                        question={question}
                        defaultValueExpression={question.defaultValueExpression}
                        format={question.format}
                        onChange={onValueChange}
                      />
                    );
                    break;
                  default:
                    settings = null;
                }

                return (
                  <React.Fragment>
                    <Grid
                      container
                      spacing={3}
                      item
                      xs={12}
                      className={active ? "" : classes.hidden}
                    >
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          <TextField
                            placeholder="提示信息"
                            fullWidth
                            disabled={isNotEditable()}
                            value={question.tips}
                            name="tips"
                            InputProps={{
                              autoComplete: "off",
                            }}
                            onChange={onValueChange}
                          />
                        </Typography>
                      </Grid>
                    </Grid>

                    {settings}
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={3}
                      key="__dependencyExpression"
                    >
                      <Grid item xs={12}>
                        <CodeEditor
                          current={question}
                          name="dependencyExpression"
                          onChange={onValueChange}
                          disabled={isNotEditable()}
                          value={question.dependencyExpression}
                          placeholder="依赖公式"
                          label="依赖公式"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={3}
                      key="__validationExpression"
                      className={
                        isMenuItemChecked(question, "validationExpression") &&
                        active
                          ? ""
                          : classes.hidden
                      }
                    >
                      <Grid item xs={12}>
                        <CodeEditor
                          current={question}
                          name="validationExpression"
                          value={question.validationExpression}
                          onChange={onValueChange}
                          disabled={isNotEditable()}
                          placeholder="验证公式"
                          label="验证公式"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={3}
                      key="__description"
                      className={
                        isMenuItemChecked(question, "description") && active
                          ? ""
                          : classes.hidden
                      }
                    >
                      <Grid item xs={12}>
                        <TextField
                          name="description"
                          value={question.description}
                          onChange={onValueChange}
                          fullWidth
                          disabled={isNotEditable()}
                          placeholder="备注"
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })()}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions
            style={{
              display: "flex",
            }}
          >
            <Typography color="textPrimary" align="right">
              {question.editable ? "可编辑" : "不可编辑"}
              <Switch
                color="primary"
                name="editable"
                disabled={!active}
                checked={question.editable}
                onChange={valueChange((event) => event.target.checked)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Typography>
            <Typography color="textPrimary" align="center">
              {question.enabled ? "显示" : "隐藏"}
              <Switch
                disabled={!active}
                color="primary"
                name="enabled"
                checked={question.enabled}
                onChange={valueChange((event) => event.target.checked)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Typography>
            <div style={{ flex: 1 }} />
            {active ? (
              <React.Fragment>
                <IconButton aria-label="add to favorites" onClick={addQuestion}>
                  <AddIcon />
                </IconButton>
                <IconButton
                  aria-label="add to favorites"
                  onClick={(event) => {
                    onPositionChange("UP", question, event);
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  aria-label="add to favorites"
                  onClick={(event) => {
                    onPositionChange("DOWN", question, event);
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  disabled={!showDeleteButton}
                  aria-label="add to favorites"
                  onClick={(event) => {
                    onPositionChange("DELETE", question, event);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  aria-controls="more-menu"
                  aria-haspopup="true"
                  onClick={openMoreMenu}
                >
                  <MoreVertIcon />
                </IconButton>
              </React.Fragment>
            ) : null}
            <Menu
              id="more-menu"
              anchorEl={moreEl}
              keepMounted
              open={Boolean(moreEl)}
              onClose={closeMoreMenu}
            >
              <MenuItem name="tips" disabled>
                <ListItemText className={classes.menuItemText}>
                  显示内容
                </ListItemText>
              </MenuItem>
              <MenuItem name="description" onClick={clickMenuItem}>
                <ListItemIcon>
                  {isMenuItemChecked(question, "description") ? (
                    <CheckIcon />
                  ) : null}
                </ListItemIcon>
                <ListItemText className={classes.menuItemText}>
                  问题备注
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem name="validationExpression" onClick={clickMenuItem}>
                <ListItemIcon>
                  {isMenuItemChecked(question, "validationExpression") ? (
                    <CheckIcon />
                  ) : null}
                </ListItemIcon>
                <ListItemText className={classes.menuItemText}>
                  输入校验
                </ListItemText>
              </MenuItem>
              <Divider />
            </Menu>
          </CardActions>
        </div>
      </div>
    </Card>
  );
};

export default class Index extends React.PureComponent {
  render() {
    return <QuestionCardFunc {...this.props} />;
  }
}
