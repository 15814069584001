import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import BlocklyEditor, { Block } from "components/Blockly";
import Blockly from "blockly";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (theme) => ({
  sourceInput: {
    marginBottom: theme.spacing(1),
  },
});

class TextFieldWithBlockly extends Component {
  constructor(props) {
    super(props);
    this.simpleWorkspace = React.createRef();
    this.state = {
      isEditorOpen: false,
    };
  }

  onEditorOpen = () => {
    const workspace = this.simpleWorkspace.current.workspace;
    const dom = this.props.value && this.props.value.blocks;
    if (dom) {
      const blocks = Blockly.Xml.textToDom(dom);
      workspace.clear();
      Blockly.Xml.domToWorkspace(workspace, blocks);
    }
  };

  onEditorClose = () => {
    const workspace = this.simpleWorkspace.current.workspace;
    // xmlToCode
    const xmlDom = Blockly.Xml.workspaceToDom(workspace);
    const blocks = Blockly.Xml.domToPrettyText(xmlDom);
    const code = Blockly.JavaScript.workspaceToCode(workspace);
    this.props.onChange({
      target: {
        value: { code, blocks },
        name: this.props.name,
        getAttribute: (name) => this.props[name],
      },
    });
  };

  onInputChange = (event) => {
    this.props.onChange({
      target: {
        value: { code: event.target.value },
        name: this.props.name,
        getAttribute: (name) => this.props[name],
      },
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.isEditorOpen && this.state.isEditorOpen) {
      this.onEditorOpen();
    }
  }

  toggleEditor = (event) => {
    if (this.state.isEditorOpen) {
      this.onEditorClose();
    }
    this.setState({
      isEditorOpen: !this.state.isEditorOpen,
    });
  };

  handleMouseDown = (event) => {
    event.preventDefault();
  };

  render() {
    const { classes, value, label, placeholder, disabled } = this.props;
    return (
      <React.Fragment>
        <FormControl fullWidth className={classes.sourceInput}>
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <Input
            id="outlined-adornment-password"
            type="text"
            value={(value && value.code) || ""}
            placeholder={placeholder}
            disabled={disabled}
            onChange={this.onInputChange}
            // TODO: 注释1, 图标
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.toggleEditor}
                  onMouseDown={this.handleMouseDown}
                  edge="end"
                >
                  {this.state.isEditorOpen ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          <FormHelperText>{""}</FormHelperText>
        </FormControl>
        {/*TODO： 注释2，编辑器*/}
        {this.state.isEditorOpen ? (
          <BlocklyEditor
            ref={this.simpleWorkspace}
            readOnly={false}
            trashcan={true}
            media={"/media/"}
            move={{
              scrollbars: true,
              drag: true,
              wheel: true,
            }}
          >
            <Block type="controls_if" />
            <Block type="controls_ifelse" />
            <Block type="logic_compare" />
            <Block type="logic_operation" />
            <Block type="logic_negate" />
            <Block type="logic_boolean" />
            <Block type="math_number" />
            <Block type="text" />
            <Block type="math_arithmetic" />
            <Block type="text_join" />

            <Block type="get_question_var" />
            <Block type="get_mid_var" />
            <Block type="get_input_var" />
            <Block type="set_error_val" />
            <Block type="set_result_val" />
          </BlocklyEditor>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TextFieldWithBlockly);
