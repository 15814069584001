import React, { useEffect, useState } from "react";

import HighCharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighCharts3DFactory from "highcharts/highcharts-3d";

import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "common/commonStyles";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import classnames from "classnames";
import { useHistory } from "react-router";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import Mock from "./Mock";
import { getContract } from "service/contract";

HighCharts3DFactory(HighCharts);

const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
    "&::after": {
      content: "''",
      right: 20,
      bottom: 20,
      backgroundColor: "white",
      height: 20,
      width: 100,
      position: "absolute",
    },
  },
  templateHeader: {
    marginTop: theme.spacing(5),
    position: "relative",
  },
  questionBar: {
    height: "10px",
    backgroundColor: "rgb(103, 58, 183)",
  },
  questionContent: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  title: {
    color: theme.fontColor.grey1,
  },
  time: {
    color: theme.fontColor.grey3,
    textAlign: "right",
  },
  summary: {
    color: theme.fontColor.grey2,
  },
  greyBackground: {
    backgroundColor: "grey",
    color: "white",
    padding: "2px 6px",
  },
  ...commonStyles(theme),
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const { contractId } = useParams();
  const [isContractLoading, setContractLoading] = useState(false);
  const [template, setTemplate] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    (async () => {
      try {
        setContractLoading(true);
        const { data: theContract } = await getContract(contractId);
        const data = {};
        for (let name in theContract.questions) {
          data[name] = theContract.questions[name].formatted;
        }
        for (let name in theContract.variables) {
          data[name] = theContract.variables[name].formatted;
        }
        setData(data);
        setTemplate(Mock[theContract.templateId]);
      } catch (e) {
      } finally {
        setContractLoading(false);
      }
    })();
  }, []);

  if (!template) {
    return null;
  }

  return (
    <Container maxWidth="md" className={classes.main}>
      <Grid className={classes.question}>
        <Card className={classes.templateHeader}>
          <div className={classes.questionBar}></div>
          <CardContent className={classes.questionContent}>
            <Typography variant="h4" className={classes.title}>
              {template.title && template.title + "- 数据分析"}
            </Typography>
            <Typography variant="subtitle1" className={classes.time}>
              {new Date().toLocaleString()}
            </Typography>
            <Grid
              container
              spacing={5}
              className={classnames(classes.smallGutterTop, classes.summary)}
            >
              <Grid item xs={6}>
                统计时段：
                <span className={classes.greyBackground}>
                  {template.duration}
                </span>
              </Grid>
              <Grid item xs={6}>
                历史项目数量：
                <span className={classes.greyBackground}>{template.total}</span>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid container spacing={5} className={classes.smallGutterTop}>
        {template.charts(data).map((chart) => {
          return (
            <Grid item xs={12} md={chart.width} key={chart.key}>
              <Card>
                <CardContent className={classes.content}>
                  <HighchartsReact
                    highcarts={HighCharts}
                    options={chart.data}
                  />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        justify="center"
        spacing={5}
        className={classnames(
          classes.mediumGutterTop,
          classes.mediumGutterBottom
        )}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.close();
            }}
          >
            关闭
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
