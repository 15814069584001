import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import { TEXT } from "common/commonConstants/QuestionCardType";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import UploadButton from "./UploadButton";
import IconButton from "@material-ui/core/IconButton";
import TemplateFormStyles from "./TemplateFormStyles";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  createTemplate,
  getTemplate,
  getTemplateCategories,
  updateTemplate,
  uploadDoc,
} from "service/template";
import QuestionSection from "components/QuestionTemplateSection";
import { FORMAT_TEXT } from "common/commonConstants/QuestionTypeFormat";
import VariableSection from "components/VariableTemplateSection";
import Switch from "@material-ui/core/Switch";
import { QuestionType } from "components/QuestionTemplateSection/QuestionTemplateCard";
import { downloadURI } from "common/helper";
import { error } from "layout/layoutSlice";
import { selectIsAdmin, selectUser } from "../Login/userSlice";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = TemplateFormStyles;

const TITLE_KEY = "title";

class TemplateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTemplateLoading: false,
      isSubmitting: false,
      activityCardKey: TITLE_KEY,
      templateCategories: [],
      modules: {},
      template: {
        category: props.isAdmin ? "" : "用户工具",
        title: "",
        description: "",
        publicTemplate: false,
        questions: [],
        variables: [
          {
            key: `variable_1`,
            variableName: `variable1`,
            expression: {},
            format: FORMAT_TEXT,
          },
        ],
      },
      file: undefined,
    };

    this.keyIndex = 0;
    this.valIndex = 0;

    this.activeRef = React.createRef();
    this.toolbarRef = React.createRef();
    this.topOfToolbar = 0;
  }

  genVariableName = () => {
    return `var${this.valIndex++}`;
  };

  genQuestionCardKey = () => {
    return `question_key_${this.keyIndex++}`;
  };

  downloadTemplate = () => {
    const templateId = this.state.template.id;
    const templateTitle = this.state.template.title;
    if (!templateId) {
      return;
    }
    downloadURI(
      `/download-template/${templateId}`,
      `${templateTitle}_${templateId}`
    );
  };

  isActive = (key) => {
    return this.state.activityCardKey === key;
  };

  setActivityCard = (activityCardKey) => {
    this.setState({
      activityCardKey,
    });
  };

  setTemplate = (template) => {
    // TODO: remove, workaround
    window.__questions = template.questions || [];
    window.__midVars = template.variables || [];

    this.setState({
      template,
    });
  };

  onSwitchValueChange = (event) => {
    const propertyName = event.target.name;
    const value = event.target.checked;
    const newTemplate = update(this.state.template, {
      [propertyName]: { $set: value },
    });
    this.setTemplate(newTemplate);
  };

  onInputValueChange = (event) => {
    const propertyName = event.target.name;
    const value = event.target.value;
    const newTemplate = update(this.state.template, {
      [propertyName]: { $set: value },
    });
    this.setTemplate(newTemplate);
  };

  onChangeVarsOf = (event) => {
    const index = event.target.getAttribute("x-index");
    const propertyName = event.target.name;
    // func = func || ((e) => e.target.value);
    const value = event.target.value;

    const newTemplate = update(this.state.template, {
      variables: { [index]: { [propertyName]: { $set: value } } },
    });
    this.setTemplate(newTemplate);
  };

  onQuestionsChange = (newQuestions, originQuestions) => {
    const newTemplate = update(this.state.template, {
      questions: { $set: newQuestions },
    });
    this.setTemplate(newTemplate);
  };

  addQuestion = () => {
    let position;
    if (this.isActive(TITLE_KEY)) {
      position = 0;
    } else {
      position =
        (this.state.template.questions || []).findIndex(
          (f) => f.key === this.state.activityCardKey
        ) + 1;
    }
    const key = this.genQuestionCardKey();
    const newTemplate = update(this.state.template, {
      questions: {
        $splice: [
          [
            position,
            0,
            {
              key,
              var: this.genVariableName(),
              questionType: QuestionType.Text,
              type: TEXT,
              format: FORMAT_TEXT,
              enabled: true,
              editable: true,
              options: [],
              columns: [],
              dependency: [],
            },
          ],
        ],
      },
    });
    this.setState({
      activityCardKey: key,
    });
    this.setTemplate(newTemplate);
  };

  updateModules = (questions) => {
    this.setState({
      modules: this.getModules(questions),
    });
  };

  getModules = (questions) => {
    const modules = (questions || []).reduce((pre, question) => {
      if (!question.moduleLevel1) {
        return pre;
      }
      if (question.moduleLevel1 in pre === false) {
        pre[question.moduleLevel1] = {};
      }
      if (!question.moduleLevel2) {
        return pre;
      }
      const level2 = pre[question.moduleLevel1];
      if (question.moduleLevel2 in level2 === false) {
        level2[question.moduleLevel2] = true;
      }
      return pre;
    }, {});
    return modules;
  };

  // loading
  componentDidMount() {
    const { templateId } = this.props.match.params;
    // 初始化模板分类
    (async () => {
      const { data: categories } = await getTemplateCategories();
      this.setState({
        templateCategories: categories,
      });
    })();

    // 初始化模板
    (async () => {
      this.setState({ isTemplateLoading: true });
      try {
        if (!templateId) {
          this.addQuestion();
          return;
        }
        const { data: responseBody } = await getTemplate(templateId);
        (responseBody.questions || []).forEach((question, index) => {
          question.key = `__QUESTION_${index}`;
        });
        // 假设变量名是a12这种格式，找到后面数字最大的+1作为index。
        this.valIndex =
          (responseBody.questions || [])
            .filter(({ var: v }) => !!v) // 找到var存在的
            .map(({ var: v }) => parseInt(v.substr(3)))
            .filter((num) => !isNaN(num))
            .reduce((pre, cur) => (pre > cur ? pre : cur), 0) + 1;
        //
        const modules = this.getModules(responseBody.questions);
        this.setState({
          modules: modules,
        });
        this.setTemplate(responseBody);
      } catch (e) {
        // TODO: error
      } finally {
        this.setState({ isTemplateLoading: false });
      }
    })();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // 更新toolbar的位置
    // if (this.activeRef.current) {
    //   this.topOfToolbar = this.activeRef.current.offsetTop;
    //   this.toolbarRef.current.style.top = this.topOfToolbar + "px";
    // }
  }

  render() {
    const { classes, history, isAdmin, user } = this.props;
    const { template } = this.state;

    return (
      <div className={classes.fullBackground}>
        <Container maxWidth="md" className={classes.main}>
          {/*<QuestionToolBar ref={this.toolbarRef}>*/}
          {/*  <IconButton size="medium" onClick={this.addQuestion}>*/}
          {/*    <AddCircleOutlineIcon />*/}
          {/*  </IconButton>*/}
          {/*</QuestionToolBar>*/}

          <Grid className={classes.question} key="__title">
            <Card
              className={classes.templateHeader}
              onClick={() => this.setActivityCard(TITLE_KEY)}
              ref={this.isActive(TITLE_KEY) ? this.activeRef : null}
            >
              <div className={classes.questionBar} />
              <div style={{ display: "flex" }}>
                <div
                  className={classes.questionActiveBar}
                  style={{ opacity: this.isActive(TITLE_KEY) ? 1 : 0 }}
                />
                <div style={{ flexGrow: 1 }}>
                  <CardContent className={classes.questionContent}>
                    <Typography variant="h4">
                      <TextField
                        fullWidth
                        margin="normal"
                        placeholder="工具标题"
                        name="title"
                        value={template.title}
                        InputProps={{
                          className: classes.test,
                        }}
                        onChange={this.onInputValueChange}
                      />
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="工具描述"
                      size="small"
                      value={template.description}
                      name="description"
                      InputProps={{
                        className: classes.test,
                      }}
                      onChange={this.onInputValueChange}
                    />
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      paddingRight: 24,
                      paddingLeft: 24,
                      paddingBottom: 16,
                    }}
                  >
                    <Autocomplete
                      id="combo-box-demo"
                      freeSolo
                      disabled={!isAdmin}
                      value={template.category || ""}
                      options={this.state.templateCategories}
                      getOptionLabel={(category) => category}
                      style={{ width: 300, marginRight: 40 }}
                      onChange={(event, value) => {
                        this.onInputValueChange({
                          target: {
                            name: "category",
                            value,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="分类"
                          name="category"
                          variant="outlined"
                          onChange={this.onInputValueChange}
                        />
                      )}
                    />
                    <TextField
                      label="开发者"
                      placeholder="开发者"
                      name="author"
                      InputProps={{
                        className: classes.test,
                      }}
                      disabled
                      value={user.nickname}
                      onChange={this.onInputValueChange}
                      style={{ marginRight: 40 }}
                      variant="outlined"
                    />
                    {isAdmin ? (
                      <Typography color="textPrimary" align="center">
                        {template.publicTemplate ? "公开" : "私有"}
                        <Switch
                          color="primary"
                          name="publicTemplate"
                          checked={template.publicTemplate}
                          onChange={this.onSwitchValueChange}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Typography>
                    ) : null}
                    <div style={{ flex: 1 }} />
                    <UploadButton
                      onChange={(file) => this.setState({ file })}
                    />
                    {this.state.template.id ? (
                      <IconButton
                        aria-label="add to favorites"
                        size="medium"
                        onClick={this.downloadTemplate}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </IconButton>
                    ) : null}
                  </CardActions>
                </div>
              </div>
            </Card>
          </Grid>
          <QuestionSection
            activityCardKey={this.state.activityCardKey}
            questions={template.questions}
            modules={this.state.modules}
            activeRef={this.activeRef}
            onFocus={this.setActivityCard}
            onModuleChange={this.updateModules}
            onQuestionsChange={this.onQuestionsChange}
            addQuestion={this.addQuestion}
          />

          <VariableSection
            activityCardKey={this.state.activityCardKey}
            name="variables"
            variables={template.variables}
            activeRef={this.activeRef}
            onFocus={this.setActivityCard}
            onChange={this.onInputValueChange}
          />

          <Grid className={classes.submit} key="__submit">
            <Button
              disabled={this.state.isSubmitting}
              variant="contained"
              color="primary"
              onClick={() => {
                // validate fields;
                if (!user.username) {
                  this.props.dispatch(error("请登录之后再进行修改"));
                  return;
                }
                // submit;
                (async () => {
                  this.setState({ isSubmitting: true });
                  try {
                    let savedTemplate;
                    if (template.id) {
                      const response = await updateTemplate(template);
                      savedTemplate = response.data;
                    } else {
                      const response = await createTemplate(template);
                      savedTemplate = response.data;
                    }
                    // upload file
                    if (this.state.file) {
                      await uploadDoc(savedTemplate.id, this.state.file);
                    }
                    history.push("/manageTemplates");
                  } catch (e) {
                    console.error(e);
                    // TODO http 和自定义的不统一，后续同意
                    const message = e.message || (e.data && e.data.message);
                    this.props.dispatch(error(message));
                    this.setState({ isSubmitting: false });
                  }
                })();
              }}
            >
              提交
            </Button>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default connect((state) => ({
  isAdmin: selectIsAdmin(state),
  user: selectUser(state),
}))(withStyles(styles)(withRouter(TemplateForm)));
