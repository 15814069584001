import identity from "lodash/identity";
import numeral from "numeral";
import { format, parse } from "date-fns";

export const FORMAT_TEXT = "FORMAT_TEXT";

export const FORMAT_NUMBER = "FORMAT_NUMBER";

export const FORMAT_BOOL = "FORMAT_BOOL";

export const FORMAT_NULL = "FORMAT_NULL";

export const FORMAT_NUMBER_INT = "FORMAT_INT";
export const FORMAT_NUMBER_INT_WITH_SEP = "FORMAT_INT_WITH_SEP";

export const FORMAT_NUMBER_FLOAT_FIXED_2 = "FORMAT_FLOAT";
export const FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP = "FLOAT_WITH_SEP";

export const FORMAT_NUMBER_PERCENTAGE = "FORMAT_PERCENTAGE";

export const FORMAT_NUMBER_EN = "FORMAT_NUMBER_EN";

export const FORMAT_NUMBER_CH_LOWERCASE = "FORMAT_NUMBER_CH_LOWERCASE";

export const FORMAT_NUMBER_CH_UPPERCASE = "FORMAT_NUMBER_CH_UPPERCASE";

export const FORMAT_NUMBER_CURRENCY_US = "FORMAT_US_DOLLAR";
export const FORMAT_NUMBER_CURRENCY_RMB = "FORMAT_RMB";
export const FORMAT_NUMBER_CURRENCY_RMB_W = "FORMAT_RMB_W";

export const FORMAT_DATE_EN = "MMMM d, yyyy";
export const FORMAT_DATE_CH = "yyyy年M月d日";
export const FORMAT_DATE_COMMON = "yyyy-MM-dd";
export const FORMAT_DATE_COMMON2 = "MM-dd-yyyy";

export const FORMAT_UNSUPPORTED = "FORMAT_UNSUPPORTED";

const DATE_INPUT_FORMAT = "MM/dd/yyyy";

export function toString(val) {
  if (isEmpty(val)) {
    return "";
  }
  return val.toString();
}

export function boolTrueLabel(option) {
  if (!option) {
    return "Yes";
  }
  const lab = option.label;
  if (!lab || lab === "TRUE") {
    return "Yes";
  }
  return lab;
}

export function boolFalseLabel(option) {
  if (!option) {
    return "No";
  }
  const lab = option.label;
  if (!lab || lab === "FALSE") {
    return "No";
  }
  return lab;
}

function isEmpty(input) {
  return input === null || input === undefined || input === "";
}

function isValidNumber(inputText) {
  return !isEmpty(inputText) && !isNaN(inputText - 0);
}

function stringToBool(inputText) {
  if (inputText === "true") {
    return true;
  } else if (inputText === "false") {
    return false;
  } else {
    return undefined;
  }
}

function boolToString(boolVal) {
  if (isEmpty(boolVal)) {
    return "";
  } else {
    return boolVal ? "true" : "false";
  }
}

function dateInputToJs(formatStr) {
  return (input) => {
    if (typeof input === "string" && input.length > 0) {
      return parse(input, formatStr, new Date());
    } else {
      return input;
    }
  };
}

function dateInputFormat(formatStr) {
  return (date) => {
    if (isEmpty(formatStr)) {
      return "";
    }
    if (typeof date === "string") {
      date = parse(date, DATE_INPUT_FORMAT, new Date());
    }
    if (date instanceof Date) {
      return format(date, formatStr);
    } else {
      return date.toString();
    }
  };
}

function dateInputToJson(formatStr) {
  return (date) => {
    if (isEmpty(date)) {
      return "";
    } else if (typeof date === "string") {
      return date;
    } else {
      return format(date, formatStr);
    }
  };
}

function dateJsonToInput(formatStr) {
  return (formattedText) =>
    isEmpty(formatStr) ? "" : parse(formattedText, formatStr, new Date());
}

const eb = {
  twenty: [2, 0],
  thirty: [3, 0],
  forty: [4, 0],
  fifty: [5, 0],
  sixty: [6, 0],
  seventy: [7, 0],
  eighty: [8, 0],
  ninety: [9, 0],
};

const ec = {
  one: [0, 1],
  two: [0, 2],
  three: [0, 3],
  four: [0, 4],
  five: [0, 5],
  six: [0, 6],
  seven: [0, 7],
  eight: [0, 8],
  nine: [0, 9],
  ten: [1, 0],
  eleven: [1, 1],
  twelve: [1, 2],
  thirteen: [1, 3],
  fourteen: [1, 4],
  fifteen: [1, 5],
  sixteen: [1, 6],
  seventeen: [1, 7],
  eighteen: [1, 8],
  nineteen: [1, 9],
  twenty: [2, 0],
  thirty: [3, 0],
  forty: [4, 0],
  fifty: [5, 0],
  sixty: [6, 0],
  seventy: [7, 0],
  eighty: [8, 0],
  ninety: [9, 0],
};

function hundredsToDigit(hundreds) {
  let [a, b, c] = [0, 0, 0];
  let tmp;
  const nums = hundreds
    .split(/\s+/)
    .filter((token) => token !== "and" && token !== "");
  let idx = nums.findIndex((token) => token === "hundred");
  if (idx > -1) {
    [tmp, a] = ec[nums[idx - 1]];
  }
  const left = nums.length - idx - 1;
  if (left === 1) {
    [b, c] = ec[nums[nums.length - 1]];
  } else if (left == 2) {
    [b, tmp] = eb[nums[nums.length - 2]];
    [tmp, c] = ec[nums[nums.length - 1]];
  }
  return [a, b, c];
}

export function enWordToDigit(en) {
  en = en.toLowerCase();

  const result = [];
  let pre = 0,
    idx;
  const all = ["trillion", "billion", "million", "thousand"];
  all.forEach((u) => {
    idx = en.indexOf(u, pre);
    let sub;
    if (idx === -1) {
      sub = "";
    } else {
      sub = en.substring(pre, idx);
      pre = idx + u.length;
    }

    result.push(hundredsToDigit(sub));
  });
  const left = en.substring(pre);
  result.push(hundredsToDigit(left));
  return result.flat().join("") - 0;
}

const a = [
  "",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];

const b = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

function makeEnGroup(groupStr) {
  const [huns, tens, ones] = groupStr.split("");
  let result = "";
  const num = tens + ones - 0;
  if (huns - 0 > 0) {
    result += a[huns] + " Hundred";
    if (num > 0) {
      result += " and ";
    }
  }
  if (num < 20) {
    result += a[num];
  } else {
    result += b[tens];
    if (ones - 0 > 0) {
      result += " " + a[ones];
    }
  }
  return result;
}

export function digitToEnWord(digit) {
  if (isNaN(digit)) {
    return "";
  }
  if (digit <= 0) {
    return "Zero";
  }
  digit = Math.trunc(digit);
  digit = ("000000000000000" + digit).substr(-15);
  return [
    [digit.substr(0, 3), " Trillion"],
    [digit.substr(3, 3), " Billion"],
    [digit.substr(6, 3), " Million"],
    [digit.substr(9, 3), " Thousand"],
    [digit.substr(12, 3), ""],
  ].reduce((pre, [groupStr, postfix]) => {
    const num = groupStr - 0;
    if (num === 0) {
      return pre;
    }
    if (num < 100 && pre.length > 0) {
      pre += " and";
    }
    if (pre.length > 0) {
      pre += " ";
    }
    pre += makeEnGroup(groupStr) + postfix;
    return pre;
  }, "");
}

const cha = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
const chA = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖", "拾"];

const chb = [
  "",
  "十",
  "百",
  "千",
  "万",
  "十万",
  "百万",
  "千万",
  "亿",
  "十亿",
  "百亿",
  "千亿",
];
const chB = [
  "",
  "拾",
  "佰",
  "仟",
  "万",
  "拾万",
  "百万",
  "仟万",
  "亿",
  "拾亿",
  "佰亿",
  "仟亿",
];

const chUpperConstantUnits = ["万", "仟", "佰", "拾", ""];
const chLowerConstantUnits = ["万", "千", "百", "十", ""];
const makeChGroup = (constantUnits, constantOnes) => (str) => {
  const [thousands, hundreds, tens, ones] = str.split("");
  return [
    [thousands, constantUnits[1], constantUnits[0]],
    [hundreds, constantUnits[2], constantUnits[1]],
    [tens, constantUnits[3], constantUnits[2]],
    [ones, constantUnits[4], constantUnits[3]],
  ].reduce((pre, [num, postfix, prefix]) => {
    num = num - 0;
    if (num === 0) {
      return pre;
    }
    if (pre.length > 0 && !pre.endsWith(prefix)) {
      pre += "零";
    }
    pre += constantOnes[num] + postfix;
    return pre;
  }, "");
};

const digitToChinese = (constantUnits, constantOnes) => (digit) => {
  const group = makeChGroup(constantUnits, constantOnes);

  if (isNaN(digit)) {
    return "";
  }
  if (digit <= 0) {
    return "零";
  }
  digit = Math.trunc(digit);
  digit = ("000000000000" + digit).substr(-12);
  return [
    [digit.substr(0, 4), "亿", ""],
    [digit.substr(4, 4), "万", "亿"],
    [digit.substr(8, 4), "", "万"],
  ].reduce((pre, [groupStr, postfix, prefix]) => {
    const num = groupStr - 0;
    if (num === 0) {
      return pre;
    }
    // 这个时候才需要判断是否要增加零
    if (pre.length > 0 && !pre.endsWith("零")) {
      if (num < 1000 || !pre.endsWith(prefix)) {
        pre += "零";
      }
    }
    // 添加上字符串
    pre += group(groupStr) + postfix;
    return pre;
  }, "");
};

const chUpperThousandUnits = ["仟", "佰", "拾"];
const chLowerThousandUnits = ["千", "百", "十"];
const chThousandsToDigit = (constantUnits, constantOnes) => (thousands) => {
  const result = [0, 0, 0, 0];
  let pre = 0,
    pos;
  constantUnits.forEach((v, idx) => {
    pos = thousands.indexOf(v, pre);
    if (pos > -1) {
      const v = thousands[pos - 1];
      const value = constantOnes.findIndex((n) => v === n);
      result[idx] = value;
      pre = pos + 1;
    }
  });
  if (pre <= thousands.length - 1) {
    const v = thousands[thousands.length - 1];
    const value = constantOnes.findIndex((n) => v === n);
    result[result.length - 1] = value;
  }
  return result.join("");
};

const chWordToDigit = (constantUnits, constantOnes) => (ch) => {
  const trans = chThousandsToDigit(constantUnits, constantOnes);

  if (ch === "零") {
    return 0;
  }
  const result = [];
  let pre = 0,
    idx;
  const all = ["亿", "万"];
  all.forEach((u) => {
    idx = ch.indexOf(u, pre);
    let sub;
    if (idx === -1) {
      sub = "";
    } else {
      sub = ch.substring(pre, idx);
      pre = idx + u.length;
    }

    result.push(trans(sub));
  });
  const left = ch.substring(pre);
  result.push(trans(left));
  return result.join("") - 0;
};

export const chWordUpperToDigit = chWordToDigit(chUpperThousandUnits, chA);
export const chWordLowerToDigit = chWordToDigit(chLowerThousandUnits, cha);
export const digitToChWordUpper = digitToChinese(chUpperConstantUnits, chA);
export const digitToChWordLower = digitToChinese(chLowerConstantUnits, cha);

/**
 *
 * 获取到contract之后，先通过jsonToInput对所有字段进行转换。
 *
 * 进行公式计算时，先通过inputToJs对字段进行转换后，当做计算的值。
 *
 * 计算完成后，需要通过jsToInput将值转换后输入当做目标值。
 *
 * 请求API之前，对所有的值，通过inputToJson进行格式化。
 *
 * 对应方法需要能够处理blank的情况
 *
 */
const translator = {
  /**
   * 这个部分现在是给Table使用了，所以需要将对象格式化一下成为JSON字符串。
   */
  [FORMAT_UNSUPPORTED]: {
    label: "不支持",
    value: FORMAT_UNSUPPORTED,
    inputToJs: identity,
    jsToInput: identity,

    inputToJson: identity,
    jsonToInput: identity,
    formatInput: (inputObj) =>
      isEmpty(inputObj) ? "" : JSON.stringify(inputObj), // TODO: 这个怎么办？
  },

  /**
   * 这里就不做转化，最后这个应该被删除掉
   * @deprecated
   */
  [FORMAT_NULL]: {
    label: "无",
    value: FORMAT_NULL,
    // input, js, json都不做处理
    inputToJs: identity,
    jsToInput: identity,

    inputToJson: identity,
    jsonToInput: identity,

    formatInput: toString,
  },
  /**
   * 没有啥特殊的注意
   */
  [FORMAT_TEXT]: {
    label: "文本",
    value: FORMAT_TEXT,
    // input, js, json都是String
    inputToJs: identity,
    jsToInput: identity,

    inputToJson: (inputText) =>
      isEmpty(inputText) ? "" : inputText.toString(),
    jsonToInput: identity,
    formatInput: (inputText) =>
      isEmpty(inputText) ? "" : inputText.toString(),
  },
  /**
   * Boolean因为组件原因，输入的都是字符串，所以到js和json的时候要做一下String到boolean的转换。
   */
  [FORMAT_BOOL]: {
    label: "布尔",
    value: FORMAT_BOOL,
    // input是String， js和json都是bool
    inputToJs: stringToBool,
    jsToInput: boolToString,

    inputToJson: stringToBool,
    jsonToInput: boolToString,
    formatInput: toString,
  },
  /**
   * 没有特殊的处理
   */
  [FORMAT_NUMBER]: {
    label: "数字",
    value: FORMAT_NUMBER,
    // 不能为空，需要是数字
    validate: isValidNumber,
    // input里可以为number和字符串，js类型确保是number，json中确保是number
    inputToJs: (inputText) => inputText - 0, // TODO: 这里怎么做，设置为0，还是设置为undefined
    jsToInput: identity,

    inputToJson: (inputText) => inputText - 0,
    jsonToInput: identity,
    formatInput: toString,
  },

  /**
   * 没有特殊的处理
   */
  [FORMAT_NUMBER_INT]: {
    label: "数字: ####",
    value: FORMAT_NUMBER_INT,
    // 不能为空，需要是数字，这里不需要是int，到时候做一个格式化
    validate: isValidNumber,
    // input里可以为number和字符串，js类型确保是number，json中确保是number
    inputToJs: (inputText) => Math.round(inputText),
    jsToInput: identity,

    inputToJson: identity,
    jsonToInput: identity,
    formatInput: (inputText) =>
      isEmpty(inputText) ? "" : Math.round(inputText).toString(),
  },

  /**
   * 这里输入的话，需要支持 数字;字符串：#,### ####
   * js到input的话，可以格式化为 #,###
   */
  [FORMAT_NUMBER_INT_WITH_SEP]: {
    label: "数字: #,###",
    value: FORMAT_NUMBER_INT_WITH_SEP,
    // 不能为空，需要是数字，这里不需要是int，到时候做一个格式化
    validate: isValidNumber,
    // input里可以为number,字符串(####)或者字符串(#,###)，js类型确保是number，json中确保是#,###
    inputToJs: (inputText) => Math.round(numeral(inputText).value()),
    jsToInput: (number) => (isNaN(number) ? "" : numeral(number).format("0,0")),

    inputToJson: (inputText) => Math.round(numeral(inputText).value()),
    jsonToInput: (formattedText) => numeral(formattedText).value(),

    formatInput: (inputText) =>
      isEmpty(inputText) ? "" : numeral(inputText).format("0,0"),
  },

  /**
   * 没啥特别的
   */
  [FORMAT_NUMBER_FLOAT_FIXED_2]: {
    label: "数字: #.##",
    value: FORMAT_NUMBER_FLOAT_FIXED_2,
    // 不能为空，需要是数字，到时候做一个格式化
    validate: isValidNumber,
    // input里为字符串，js类型确保是number，json中是字符串，确保是#.##
    inputToJs: (inputText) => numeral(inputText).value(),
    jsToInput: (number) => number.toFixed(2),

    inputToJson: (inputText) => (inputText - 0).toFixed(2), // 格式化为#.##
    jsonToInput: identity,
    formatInput: (inputText) =>
      isEmpty(inputText) ? "" : (inputText - 0).toFixed(2), // 格式化为#.##
  },

  /**
   * 这里输入的话，需要支持 数字;字符串：#,###.## ####.##
   * js到input的话，可以格式化为 #,###.##
   */
  [FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP]: {
    label: "数字: #,###.##",
    value: FORMAT_NUMBER_FLOAT_FIXED_2_WITH_SEP,
    // 不能为空，需要是数字，到时候做一个格式化
    validate: isValidNumber,
    // input里为字符串(#,###.###的格式)，js类型确保是number，json中是字符串，确保是#,###.##
    inputToJs: (inputText) => numeral(inputText).value(),
    jsToInput: (number) =>
      isNaN(number) ? "" : numeral(number).format("0,0.00"),

    inputToJson: (inputText) => numeral(inputText).format("0.00"), // 格式化为#,###.##
    jsonToInput: (formattedText) => numeral(formattedText).value(),

    formatInput: (inputText) =>
      isEmpty(inputText) ? "" : numeral(inputText).format("0,0.00"), // 格式化为#,###.##
  },
  /**
   * 这里输入的话，需要支持 数字;字符串：#.##% #.##
   * js到input的话，可以格式化为 #.##%
   */
  [FORMAT_NUMBER_PERCENTAGE]: {
    label: "数字: ##.##%",
    value: FORMAT_NUMBER_PERCENTAGE,
    // 不能为空，需要是数字，到时候做一个格式化
    validate: isValidNumber,
    // input里为字符串或者数字,或者（##.##%），js类型确保是number，json中是字符串，确保是##.##%
    inputToJs: (inputText) => numeral(inputText).value(),
    jsToInput: (number) =>
      isNaN(number) ? "" : numeral(number).format("0.00%"),

    inputToJson: (inputText) => numeral(inputText).format("0.00%"), // 格式化为#,###.##
    jsonToInput: (formattedText) => numeral(formattedText).value(),
    formatInput: (inputText) =>
      isEmpty(inputText) ? "" : numeral(inputText).format("0.00%"),
  },
  /**
   * input为数字或者英文字符串
   */
  [FORMAT_NUMBER_EN]: {
    label: "数字: One",
    value: FORMAT_NUMBER_EN,
    // input里为字符串或者数字，js类型确保是number，json中是字符串，确保是##.##%
    inputToJs: (inputText) =>
      isNaN(inputText) ? enWordToDigit(inputText) : numeral(inputText).value(),
    jsToInput: digitToEnWord,

    inputToJson: toString,
    jsonToInput: identity,
    formatInput: (input) => (isNaN(input) ? input : digitToEnWord(input)),
  },
  /**
   * input为数字或者中文小写字符串
   */
  [FORMAT_NUMBER_CH_LOWERCASE]: {
    label: "数字: 一",
    value: FORMAT_NUMBER_CH_LOWERCASE,
    // input里为字符串或者数字，js类型确保是number，json中是字符串，确保是##.##%
    inputToJs: (inputText) =>
      isNaN(inputText)
        ? chWordLowerToDigit(inputText)
        : numeral(inputText).value(),
    jsToInput: digitToChWordLower,

    inputToJson: toString, // 格式化为#,###.##
    jsonToInput: identity,
    formatInput: (input) => (isNaN(input) ? input : digitToChWordLower(input)),
  },

  /**
   * input为数字或者中文大写字符串
   */
  [FORMAT_NUMBER_CH_UPPERCASE]: {
    label: "数字: 壹",
    value: FORMAT_NUMBER_CH_UPPERCASE,
    // input里为字符串或者数字，js类型确保是number，json中是字符串，确保是##.##%
    inputToJs: (inputText) =>
      isNaN(inputText)
        ? chWordUpperToDigit(inputText)
        : numeral(inputText).value(),
    jsToInput: digitToChWordUpper,

    inputToJson: toString, // 格式化为#,###.##
    jsonToInput: identity,
    formatInput: (input) => (isNaN(input) ? input : digitToChWordUpper(input)),
  },
  /**
   * input 数字或者 US$ ###
   */
  [FORMAT_NUMBER_CURRENCY_US]: {
    label: "数字: US$ ###",
    value: FORMAT_NUMBER_CURRENCY_US,
    inputToJs: (inputText) => {
      if (isEmpty(inputText)) {
        return 0;
      }
      if (inputText.startsWith("US$")) {
        return numeral(inputText.substr(3)).value;
      }
      return numeral(inputText).value();
    },
    jsToInput: (js) => `US$ ${js}`,

    inputToJson: toString, // 格式化为#,###.##
    jsonToInput: identity,
    formatInput: (inputText) => {
      if (isEmpty(inputText)) {
        return "";
      }
      if (isNaN(inputText)) {
        return inputText;
      }
      return `US$ ${inputText}`;
    },
  },

  /**
   * input 数字或者 人民币#,###元
   */
  [FORMAT_NUMBER_CURRENCY_RMB]: {
    label: "数字: 人民币#,###元",
    value: FORMAT_NUMBER_CURRENCY_RMB,
    // input里为字符串或者数字，js类型确保是number，json中是字符串，确保是##.##%
    inputToJs: (inputText) => {
      if (isEmpty(inputText)) {
        return 0;
      }
      if (inputText.startsWith("人民币")) {
        return numeral(inputText.substring(3, inputText.length - 1)).value;
      }
      return numeral(inputText).value();
    },
    jsToInput: (inputText) => `人民币${numeral(inputText).format("0,0")}元`,

    inputToJson: toString, // 格式化为#,###.##
    jsonToInput: identity,
    formatInput: (inputText) => {
      if (isEmpty(inputText)) {
        return "";
      }
      if (isNaN(inputText)) {
        return inputText;
      }
      return `人民币${numeral(inputText).format("0,0")}元`;
    },
  },
  /**
   * input为数字，或者####万元
   */
  [FORMAT_NUMBER_CURRENCY_RMB_W]: {
    label: "数字: ####万元",
    value: FORMAT_NUMBER_CURRENCY_RMB_W,
    // input里为字符串或者数字，js类型确保是number，json中是字符串，确保是##.##%
    inputToJs: (inputText) => {
      if (isEmpty(inputText)) {
        return 0;
      }
      if (inputText.endsWith("万元")) {
        return numeral(inputText.substring(0, inputText.length - 2)).value();
      }
      return numeral(inputText).value();
    },
    jsToInput: (js) => `${js}万元`,

    inputToJson: toString, // 格式化为#,###.##
    jsonToInput: identity,
    formatInput: (inputText) => {
      if (isEmpty(inputText)) {
        return "";
      }
      if (inputText.endsWith("万元")) {
        return inputText;
      }
      return `${inputText}万元`;
    },
  },
};

// 日期的四个格式，仅仅是格式字符串不一样，其他都一样
[
  [FORMAT_DATE_EN, "日期: MMMM d, yyyy"],
  [FORMAT_DATE_CH, "日期: yyyy年M月d日"],
  [FORMAT_DATE_COMMON, "日期: yyyy-MM-dd"],
  [FORMAT_DATE_COMMON2, "日期: MM-dd-yyyy"],
].forEach(([value, label]) => {
  translator[value] = {
    label,
    value,
    // input里为Date或者String（尽量转化为Date），js类型确保是Date，json中确保是String
    inputToJs: dateInputToJs(DATE_INPUT_FORMAT),
    jsToInput: identity,

    inputToJson: dateInputToJson(DATE_INPUT_FORMAT),
    jsonToInput: dateJsonToInput(DATE_INPUT_FORMAT),
    formatInput: dateInputFormat(value),
  };
});

export default translator;
