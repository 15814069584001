import { combineReducers, configureStore } from "@reduxjs/toolkit";
import layoutReducer from "layout/layoutSlice";
import UserReducer, { clearUser } from "pages/Login/userSlice";
import axios from "axios";
import * as CryptoJS from "crypto-js";

const initSession = JSON.parse(window.sessionStorage.getItem("session"));
if (initSession) {
  const privateKey = window.sessionStorage.getItem("privateKey");
  axios.defaults.headers.common["Authorization"] = initSession.token;
  axios.defaults.headers.common["x-private-key"] = privateKey;
}

const store = configureStore({
  reducer: combineReducers({
    globalErrors: layoutReducer,
    session: UserReducer,
  }),
  preloadedState: initSession
    ? {
        session: initSession,
      }
    : undefined,
});

export default store;

const encryptedBase64Key = "bXVzdGJlMTZieXRlc2tleQ==";
const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // encrypt
    if (config.url === "/doc-template-upload") {
      return config;
    }

    if (config.data) {
      const encryptedData = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf16BE.parse(JSON.stringify(config.data)),
        parsedBase64Key,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      // base64
      const newData = encryptedData.toString();
      config.data = newData;
    }

    config.headers["Content-Type"] = "application/json";
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // base64
    if (response.data) {
      const decryptedData = CryptoJS.AES.decrypt(
        response.data,
        parsedBase64Key,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      // decrypt

      response.data = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf16BE));
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 403 || error.response.status === 401) {
      store.dispatch(clearUser());
    }

    if (error.response.data) {
      const decryptedData = CryptoJS.AES.decrypt(
        error.response.data,
        parsedBase64Key,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      error.response.data = JSON.parse(
        decryptedData.toString(CryptoJS.enc.Utf16BE)
      );
    }
    return Promise.reject(error.response);
  }
);
