import { createSlice } from "@reduxjs/toolkit";
import { loginUser, logoutUser } from "service/session";
import { error } from "layout/layoutSlice";

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const userSlice = createSlice({
  name: "session",
  initialState: {
    isAuthenticated: false,
    user: {},
    token: undefined,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user; // 设置一个user的基本信息
      state.isAuthenticated = true;
      state.token = action.payload.token;
      // TODO: move to other place.
      window.sessionStorage.setItem(
        "session",
        JSON.stringify({
          isAuthenticated: true,
          user: action.payload.user,
          token: action.payload.token,
        })
      );
    },
    clearUser: (state) => {
      state.user = {};
      state.isAuthenticated = false;
      state.token = undefined;
      // TODO: move to other place.
      window.sessionStorage.setItem(
        "session",
        JSON.stringify({
          isAuthenticated: false,
          user: {},
          token: undefined,
        })
      );
    },
  },
});
export const { setUser, clearUser } = userSlice.actions;

export const login = ({ username, password }) => async (dispatch) => {
  try {
    const { data: loginResp } = await loginUser({ username, password });
    dispatch(setUser(loginResp));
  } catch (ex) {
    dispatch(error("用户密码错误"));
  }
};

export const logout = () => async (dispatch) => {
  try {
    try {
      await logoutUser();
    } catch (e) {
      console.log(e);
    }
    dispatch(clearUser());
  } catch (error) {
    console.error(error);
  }
};

export const selectIsAuthenticated = (state) => state.session.isAuthenticated;
export const selectUser = (state) => state.session.user;
export const selectIsAdmin = (state) => {
  const authorities = state.session.user.authorities;
  return (
    (authorities || []).findIndex((a) => a.authority === "ROLE_ADMIN") != -1
  );
};

// TODO: 更好的表示受限的用户
export const selectIsCreator = (state) => {
  const authorities = state.session.user.authorities;
  return (
    (authorities || []).findIndex((a) => a.authority === "ROLE_CREATOR") != -1
  );
};

export const selectIsUnlimitedCreator = (state) => {
  const authorities = state.session.user.authorities;
  return (
    (authorities || []).findIndex(
      (a) => a.authority === "ROLE_UNLIMITED_CREATOR"
    ) != -1
  );
};

export default userSlice.reducer;
